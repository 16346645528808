import React from 'react'
import {
  Col,
  Row,
  Alert,
  Button,
  ButtonGroup,
  Badge,
  Label,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Input
} from 'reactstrap'
import TextField from '@material-ui/core/TextField'

import { withFirebase } from '../../components/Fb'
import { withCookies } from 'react-cookie'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import ReservationModal from '../../components/Inputs/ReservationModal'
// import ResourceModal from '../../components/Inputs/ResourceModal'
// import moment from 'moment-timezone'
// import moment from 'moment'
import moment from 'moment-timezone'
import 'moment/locale/fi'

// import Switch from 'react-bootstrap-switch'
// import { duration } from '@material-ui/core'
import { TinyColor } from '@ctrl/tinycolor'

// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import LanguageIcon from '@material-ui/icons/Language'
import DeleteIcon from '@material-ui/icons/Delete'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { v4 as uuidv4 } from 'uuid'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
// import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
// import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import algoliasearch from 'algoliasearch'
import { getReservation } from '../../components/variables'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchInput from '../../components/Inputs/SearchInput'
import FilterDialog from '../../components/Inputs/FilterDialog'
import PersonDetails from '../../components/Details/PersonDetails2'

import { isMobile } from 'react-device-detect'
import CustomerSideDrawer from '../../components/Drawer/CustomerSideDrawer'
import BookingsSideDrawer from '../../components/Drawer/BookingsSideDrawer'
import LatestSideDrawer from '../../components/Drawer/LatestSideDrawer'
// import DateSelector from '../../components/Inputs/DateSelector'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const algoliaclient = algoliasearch('6WV1D95IXL', 'd7e7d2238757ecd7bfd520b2b3a5562f')
const algoliaindex = algoliaclient.initIndex('falcon_combined')

// import { MotionPhotosOffTwoTone } from '@mui/icons-material'

// moment.tz.setDefault('Etc/UTC');
moment.tz.setDefault('Etc/GMT-3')
const localizer = momentLocalizer(moment)
// const localizer = momentLocalizer(moment_timezone)
const TIMEFORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

// const RECURRENT_COLOR = '#3d1c94'
const SHARE_COLOR = '#afa277'
const UNKNOWNSHARE_COLOR = '#e40668'
const CANCELLED_COLOR = '#f05f40'
// const ONSALE_COLOR = '#f09440'
// const RESERVED_COLOR = '#e9f040'
// const HVS_COLOR = '#fdc400'
// const HEDMAN_COLOR = '#16ba64'

const NOLIGHTS_TITLES = ['suljettu', 'pimeys', 'ei valoja']

const datenavidation = { cursor: 'pointer', margin: 10 }

// const desingclass = { borderColor: 'black', borderWidth: 1, borderStyle: 'dashed' }
const desingclass = { }

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const isOwnEvent = (event, personid) => {
  if (!event) return false
  if (!event.data) return false
  if (event.data.persons && event.data.persons.payers && event.data.persons.payers.length > 0) {
    if (event.data.persons.payers[0].businessId) {
      // check is contact or user
      if (event.data.persons.payers[0].contacts && event.data.persons.payers[0].contacts.length > 0) {
        const _c = event.data.persons.payers[0].contacts.find((c) => c.id === personid)
        if (_c) {
          return true
        }
      }
      if (event.data.persons.payers[0].users && event.data.persons.payers[0].users.length > 0) {
        const _u = event.data.persons.payers[0].users.find((u) => u.id === personid)
        if (_u) {
          return true
        }
      }
    } else {
      if (event.data.persons.payers[0].id === personid) {
        return true
      }
    }
  }
  return false
}

const ResourceHeaderContainer =
  ({ lightsOnOff, lightStatus }) =>
  (props) => {
    // console.log('ResourceHeaderContainer')
    // console.log(props)
    return <ResourceHeader event={props} lightsOnOff={lightsOnOff} lightStatus={lightStatus} />
  }

function EmptyToolbar() {
  return <div className='rbc-toolbar'></div>
}

function ResourceHeader(props) {
  let event = props.event
  // console.log('ResourceHeader', props.lightStatus[event.resource.id])
  // onClick={(e) => props.lightsOnOff(event.resource.id)}
  return (
    <div> {props.lightStatus[event.resource.id] ?
      <span style={{ marginRight: '20px' }} >
        <FontAwesomeIcon icon={faLightbulb} color={props.lightStatus[event.resource.id].lightsstatus === 'on' ? 'gold' : 'gray'} />
      </span>
      : null}
      {event.label}
    </div>
  )
}

function Event({ event, onPopoverButtonClick, deleteCart, multireserve, multicancel, role, uid }) {
  let titleClass = ''
  let title = event.title
  if (!title || title === '') {
    titleClass = 'importtitle'
    if (event.data?.persons?.payers) {
      let _p = event.data?.persons?.payers[0]
      if (!_p) {
        // console.log('no payers', event)
        _p = { title: 'no payers' }
      }
      if (_p.title) {
        title = title + '(k:' + _p.title + ')'
      }
    }
  }
  if (event.data?.persons?.owner && event.data.persons.owner[0]) {
    titleClass = 'importtitle'
    let _o = event.data?.persons?.owner[0]
    if (!_o.id) {
      if (_o.title) {
        title = title + '(o:' + _o.title + ')'
      } else if (_o.importtitle) {
        title = title + ' o:' + _o.importtitle
      }
    }
  }
  if (event.data?.title) {
    title = event.data.title
  }

  let _backgroundStyle = {
    borderTopStyle: 'solid',
    borderWidth: 1,
    borderColor: 'lavender',
    width: '100%',
    backgroundColor: 'tomato',
    marginBottom: 2,
  }
  let _s = { height: 4, width: '0%', backgroundColor: 'mediumseagreen' }
  // if (event.data && event.data.isrecurring) {
  //   _backgroundStyle.display = 'none'
  // }

  if (event.paymentstatus && event.paymentstatus.cost > 0) {
    _s.width = (event.paymentstatus.paid / event.paymentstatus.cost) * 100 + '%'
  } else if (event.paymentstatus && event.paymentstatus.cost === 0 && event.paymentstatus.paid === 0) {
    _s.width = '100%'
  }
  if (event.data?.notitle) {
    _s.width = '0%'
    _backgroundStyle.width = '0%'
  }

  if (event.title === 'Ei hinnoittelua') {
    _s.width = '0%'
    _backgroundStyle.width = '0%'
  }



  let eventTitleData =
    event?.data?.via === 'www' ? (
      <LanguageIcon fontSize={'inherit'} />
    ) : event?.data?.via === 'admin' ? (
      <AccountCircleIcon fontSize={'inherit'} />
    ) : null
  if (event?.id?.startsWith('placeholder-') && multireserve) {
    title = `Korissa`
    eventTitleData = <DeleteIcon fontSize={'inherit'} onClick={() => deleteCart(event.id)} />
  }

  const notesData = event?.data?.notes ? <StarBorderIcon fontSize={'inherit'} style={{
    color: 'rgb(69 253 161)', //'rgb(191 36 36)',//rgb(69 253 161)
    // textShadow: '0 0 3px #fff, 0 0 3px #fff, 0 0 3px #e60073, 0 0 3px #e60073, 0 0 50px #e60073, 0 0 3px #e60073, 0 0 3px #e60073',
    filter: 'drop-shadow(0 0 1px white)'
  }} /> : null
    

  if (role === 'COOPERATIVE' && !isOwnEvent(event, uid)) {
    return (
      <span>
        <div style={_backgroundStyle}>
          {/* <div style={_s}></div> */}
        </div>
        <div className={titleClass}>
          <small></small>
        </div>
      </span>
    )
  }

  return (
    <span>
      <div style={_backgroundStyle}>
        <div style={_s}></div>
      </div>
      <div className={titleClass}>
      {notesData}{eventTitleData} 
        {title} <small>{event?.paymentstatus && role !== 'PARTNER' ? event.paymentstatus.cost/100 + '€' : ''}</small>
      </div>
    </span>
  )
}

const sortResources = (a, b) => {
  if (!a.order) a.order = 1000
  if (!b.order) b.order = 1000
  if (a.order < b.order) {
    return -1
  }
  if (a.order > b.order) {
    return 1
  }
  return 0
}

const convertCartToReservations = (cartSlot, payer, title, tags) => {
  const filledShell = getReservation()
  if (title) filledShell.title = title
  if (tags) filledShell.tags = tags
  filledShell.state = 'reserved'
  filledShell.resourcegroups[0].resources = [cartSlot.resourcedata]
  filledShell.resourcegroups[0].cost = cartSlot.cost
  if (!isNaN(cartSlot.overridecost)) filledShell.resourcegroups[0].overridecost = cartSlot.overridecost
  filledShell.resourcegroups[0].start = moment.utc(cartSlot.start).format(TIMEFORMAT)
  filledShell.resourcegroups[0].end = moment.utc(cartSlot.end).format(TIMEFORMAT)
  filledShell.persons.payers.push(payer)
  delete filledShell.id
  return filledShell
}

class CourtsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.datenavigatorRef = React.createRef()
    this.filterRef = React.createRef()
    const { cookies } = props
    this.state = {
      loading: true,
      resources: null,
      reservationAlert: null,
      lights: {},
      reservationModal: false,
      resourceModal: false,
      showcancelled: false,
      showonsale: false,
      selectedDate: moment.utc().format('YYYY-MM-DD'),
      loadingModalOpen: false,
      events: [],
      calendarHeight: '75vh',
      cartEvents: [],
      cartCancelEvents: [],
      multireserve: false,
      multicancel: false,
      multireserveOpen: false,
      multicancelOpen: false,
      multireservationpayer: null,
      multititle: '',
      checkingPrice: false,
      multitags: [],
      multicancelconfirmation: false,
      multicancelmessagebody: null,
      multicancelmessagesubject: null,
      dayMin: new Date(1972, 0, 1, 12, 0, 0),
      dayMax: new Date(1972, 0, 1, 12, 59, 59),
      eventsearch: null,
      filterDialogOpen: false,
      ownFilter: cookies.get('ownFilter') ? cookies.get('ownFilter') : null,
      personSelected: false,
      customerSideDrawerOpen: false,
      bookingsSideDrawerOpen: false,
      latestSideDrawerOpen: false,
      categories: [],
      categoryFilter: cookies.get('categoryFilter') ? cookies.get('categoryFilter') : 'all',
      venues: [],
    }
  }

  updateFilters = () => {
    if (this.state.resources) {
      let _fResources = []
      if (this.state.ownFilter) {
        _fResources = this.props?.customerdata?.resources.filter((e) => {
          const f = this.state.ownFilter.resources.find((o) => o.id === e.id)
          if (f) {
            return true
          }
          return false
        })
      } else {
        _fResources = this.props?.customerdata?.resources.filter((e) => {
          if (this.state.categoryFilter && this.state.categoryFilter !== 'all') {
            return this.state.categoryFilter === e.category
          }
          return true
        })
        // _fResources = this.state.rawResources.filter((e) => {
        //   if (this.state.categoryFilter && this.state.categoryFilter !== 'all') {
        //     return this.state.categoryFilter === e.category
        //   }
        //   return true
        // })
      }
      _fResources.sort(sortResources)
      // console.log(_fResources)
      this.setState({
        resources: _fResources,
      })
      this.getMOTD()
    }
  }

  filterData = () => {
    // console.time('filter')
    // console.log('filter', new Date().getTime(), new Date())
    let reservationsLoaded = false

    let events = []
    let backgroundevents = []

    if (this.state.shards) reservationsLoaded = true
    if (this.state.shards) {
      const _rawreservations = []
      // eslint-disable-next-line array-callback-return
      Object.keys(this.state.shards).map((shardkey) => {
        _rawreservations.push(...this.state.shards[shardkey])
      })
      const rawreservations = _rawreservations.map((event) => {
        if (moment.utc(event.start).date() !== moment.utc(event.end).date()) {
          if (
            moment.utc(event.end).hour() === 0 &&
            moment.utc(event.end).minute() === 0 &&
            moment.utc(event.end).second() === 0
          ) {
            event.end = moment.utc(event.end).subtract(1, 'millisecond').format(TIMEFORMAT)
          }
        }
        return event
      })
      if (!this.state.showcancelled) {
        events = rawreservations.filter((e) => e.data.state !== 'cancelled')
      } else {
        events = rawreservations
      }
      if (!this.state.showonsale) {
        events = events.filter((e) => e.data.state !== 'onsale')
      }
      if (!this.state.showmoved) {
        events = events.filter((e) => e.data.state !== 'moved')
      }
    }

    let _fResources = []
    if (reservationsLoaded && this.props?.customerdata?.resources /*this.state.rawResources*/) {
      _fResources = this.props.customerdata.resources.filter((e) => {
        if (this.state.categoryFilter && this.state.categoryFilter !== 'all') {
          return this.state.categoryFilter === e.category
        }
        return true
      })
      _fResources.sort(sortResources)
    }
    if (this.state.freeEvents) {
      events.push(...this.state.freeEvents)
    }

    if (this.state.openingtimes) {
      console.log('openingtimes formatting')
      const _dDay = moment.utc(this.state.selectedDate, 'YYYY-MM-DD').day()
      let tDay = _dDay === 0 ? 6 : _dDay - 1
      let dd = this.state.openingtimes.find((d) => d.day === tDay)

      if (reservationsLoaded && dd) {

        let zeroStart = false
        let zeroEnd = false
        let startOffset = 60
        if (parseInt(dd.open[1]) !== 0) {
          startOffset = 30
        }
        if (parseInt(dd.open[0]) < 3) {
          startOffset = 0
          dd.open[0] = '00'
          dd.open[1] = '00'
          zeroStart = true
        }
        let endOffset = 60
        if (parseInt(dd.close[0]) === 23 && parseInt(dd.close[1]) > 0) {
          endOffset = 0
          zeroEnd = true
        }
        let allday = zeroEnd && zeroStart

        let _min = moment
          .utc('1972-01-01 ' + dd.open[0] + ':' + dd.open[1], 'YYYY-MM-DD HH:mm')
          .subtract(180 + startOffset, 'minutes')
          // .subtract(moment().tz('Europe/Helsinki').utcOffset() + startOffset, 'minutes')
          .toDate()
        let _max = moment
          .utc('1972-01-01 23:59:59', 'YYYY-MM-DD HH:mm:SS')
          .subtract(180 + endOffset, 'minutes')
          // .subtract(moment().tz('Europe/Helsinki').utcOffset() + endOffset, 'minutes')
          .toDate()
        // console.log('dd.events', dd.events)
        if (this.props.customerdata && this.props.customerdata.resources) {
          const openingEvents = this.props.customerdata.resources //this.state.rawResources
            .map((res) =>
              JSON.parse(JSON.stringify(dd.events))
                .filter(
                  (x) => moment.utc(x.end, 'THH:mm:SS.000Z').diff(moment.utc(x.start, 'THH:mm:SS.000Z'), 'minutes') >= 29
                )
                .map((ev) => {
                  ev.resourceId = res.id
                  ev.start = this.state.selectedDate + ev.start
                  ev.end = this.state.selectedDate + ev.end
                  return ev
                })
            )
            .flat(2)
          // events.push(...openingEvents)
          // console.log('allday', allday, 'openingEvents', openingEvents)
          if (!allday) {
            backgroundevents.push(...openingEvents)
          }
        }

        // console.log('minmax', _min, _max)
        this.setState({ dayMin: _min, dayMax: _max })
        // this.setState({ dayMin: _min })
        // console.log(min, _min)
        // console.timeEnd('openings')
      }
    }
    
    if (this.state.bgevents) {
      const _filtered = this.state.bgevents.filter((x) => moment.utc(x.end).diff(moment.utc(x.start), 'minutes')  > 1)
      // console.log('bgevents', this.state.bgevents, _filtered)
      backgroundevents = [..._filtered]
    }

    // console.log('events', events)
    this.setState({
      loaded: reservationsLoaded, // && resourcesLoaded,
      events,
      backgroundevents,
      resources: _fResources,
    })
    // console.log('beforemotd', new Date().getTime())
    this.getMOTD()
    // console.log('end', new Date().getTime())

    // }
  }

  clearAlgolia = (x) => {
    x.id = x.objectID
    delete x._highlightResult
    delete x.lastmodified
    delete x.reservationAlert
    delete x.objectID
    return x
  }

  searchCombinedPayers = (input) => {
    console.log('searchCombinedPayers', input)
    algoliaindex.search(input, { filters: '_tags:' + this.props.firebase.customerid }).then(({ hits }) => {
      const persons = hits
        .filter((x) => x.email && x.email.indexOf('_reg') < 0 && x.email.indexOf('_del') < 0)
        .map((x) => this.clearAlgolia(x))
      const organisations = this.props.firebase.searchCombined(input).map((p) => p.item)
      const searchresult = persons.concat(organisations)
      this.setState({ searchpayers: searchresult })
    })
  }

  isValidBusiness = (option) => {
    if (option.businessId) {
      if (!option.contacts) {
        return true
      }
    }
    return false
  }

  handlePayer = (event, value) => {
    // console.log('handlePayer', value)
    this.setState({ multireservationpayer: value, checkingPrice: true }, this.updateMultiPrices)
  }

  handleTags = (event, value) => {
    if (this.state.multitags && value) {
      this.setState({ multitags: value })
    }
  }

  handleOptionLabel = (opt) => {
    if (opt.businessId) {
      return opt.name
    } else {
      return opt.firstname && opt.lastname ? opt.firstname + ' ' + opt.lastname : ''
    }
  }

  deleteCart = (id) => {
    if (this.state.cartEvents) {
      let _t = [...this.state.cartEvents].filter((x) => x.id !== id)
      let _e = [...this.state.events].filter((x) => x.id !== id)
      // console.log('updating cart', _t, id)
      this.setState({ events: _e, cartEvents: _t })
    }
  }

  setResource = (event) => {
    this.setState({ input: JSON.parse(event.target.value) })
  }

  addNew = () => {
    this.setState({
      modalNotice: !this.state.modalNotice,
    })
  }

  toggleLights = (id) => {
    let _l = this.state.lights
    _l[id] = !_l[id]
    this.setState({
      lights: _l,
      lightsDialog: (
        <ReactBSAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title={_l[id] ? 'Sytytetty' : 'Sammutettu'}
          onConfirm={() => this.hideLightsAlert()}
          onCancel={() => this.hideLightsAlert()}
          confirmBtnBsStyle='info'
        >
          Done.
        </ReactBSAlert>
      ),
    })
  }

  hideLightsAlert = () => {
    this.setState({
      lightsDialog: null,
    })
  }

  lightsOnOff = (id) => {
    // console.log('lightsOnOff ' + id)
    this.setState({
      lightsDialog: (
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title={
            this.state.lights[id] && this.state.lights[id].lightsstatus === 'on'
              ? 'Sammutetaanko valot?'
              : 'Sytytetäänkö valot?'
          }
          onConfirm={() => this.toggleLights(id)}
          onCancel={() => this.hideLightsAlert()}
          confirmBtnBsStyle='info'
          cancelBtnBsStyle='danger'
          confirmBtnText='Yes'
          cancelBtnText='Peruuta'
          showCancel
        ></ReactBSAlert>
      ),
    })
  }

  getMOTD = () => {
    if (this.state.infotexts) {
      const keys = Object.keys(this.state.infotexts)
      const selectedKey = keys.find((k) => {
        const motd = this.state.infotexts[k]
        if (motd.state && motd.state === 'deleted') return false
        const start = moment(motd.start, 'YYYY-MM-DD')
        const end = moment(motd.end, 'YYYY-MM-DD')
        const isForAdmin = motd.visible ? motd.visible === 'all' || motd.visible === 'admin' : false
        const catFilter = this.state.categoryFilter ? this.state.categoryFilter : 'all'
        const correctCategory = motd.categories
          ? catFilter === 'all'
            ? true
            : motd.categories.includes(catFilter)
          : true
        // console.log('isForAdmin', isForAdmin, 'correctCategory', correctCategory, 'this.state.categoryFilter', this.state.categoryFilter, 'motd.categories', motd.categories)
        return (
          moment(this.state.selectedDate, 'YYYY-MM-DD').isBetween(start, end, undefined, '[]') &&
          isForAdmin &&
          correctCategory
        )
      })
      if (selectedKey) {
        const motd = this.state.infotexts[selectedKey].text
        this.setState({ motd })
      } else {
        this.setState({ motd: null })
      }
    }
  }

  updateListeners = () => {
    this.listenBookings()
    this.getOpeningTimes()
  }

  moveDate = (days) => {
    let date
    if (days > 0) {
      date = moment.utc(this.state.selectedDate).add(days, 'days')
    } else if (days < 0) {
      date = moment.utc(this.state.selectedDate).subtract(-days, 'days')
    } else {
      date = moment.utc()
    }
    if (date.isValid()) {
      this.setState({ selectedDate: date.format('YYYY-MM-DD') }, this.updateListeners)
    }
  }

  handleDateChange = (event) => {
    const date = moment(event.target.value)
    if (date.isValid()) {
      this.setState({ selectedDate: event.target.value }, this.updateListeners)
    } else {
      // console.log('not valid date')
    }
    //(e) => this.setState({ selectedDate: e.target.value }, this.listenBookings)
  }

  getOpeningTimes = () => {
    console.log('getOpeningTimes', this.state.selectedDate)
    this.props.firebase.getDayOpeningTimes(this.state.selectedDate).then((doc) => {
      if (doc.exists) {
        let b = doc.data()
        let bgevents = b.dayopens.map((x) => {
          x.allDay = false
          x.data = { notitle: x.title ? false : true }
          return x
        })
        this.setState({ bgevents }, this.filterData)
      } else {
        console.log('getOpeningTimes no data for', this.state.selectedDate)
      }
    })
    console.log('getOpeningTimes openingtimes', this.state.openingtimes)
    if (!this.state.openingtimes) {
      this.props.firebase
        .getOpeningTimes()
        .get()
        .then((doc) => {
          if (doc.exists) {
            let days = doc.data().defaults.map((day) => {
              let morning = JSON.parse(
                JSON.stringify({
                  allDay: false,
                  resourceId: '',
                  title: 'Dark',
                  start: 'T01:00:00.000Z',
                  data: { notitle: true },
                })
              )
              let evening = JSON.parse(
                JSON.stringify({
                  allDay: false,
                  resourceId: '',
                  title: 'Dark',
                  end: 'T23:59:59.000Z',
                  data: { notitle: true },
                })
              )
              morning.end = 'T' + day.start.replace('.', ':') + ':00.000Z'
              evening.start = 'T' + day.end.replace('.', ':') + ':00.000Z'
              return { day: day.day, events: [morning, evening], open: day.start.split(':'), close: day.end.split(':') }
            })
            console.log('Set Openings', days)
            this.setState(
              {
                openingtimes: days,
              },
              this.filterData
            )
          }
        })
    }
  }

  listenBookings = () => {
    // console.log('Listen bookings')
    const date = moment(this.state.selectedDate)
    if (date.isValid()) {
      if (date.year() < 2030 && date.year() > 2020) {
        // console.log('start', new Date().getTime())
        // console.time('loader')
        // console.time('unsubscribeBookings')
        if (this.bookingsRef) {
          this.setState({ shards: null })
          this.unsubscribeBookings()
          // console.timeEnd('unsubscribeBookings')
        }
        // sharded
        this.bookingsRef = this.props.firebase.listenBookingIndex(
          moment.utc(this.state.selectedDate, 'YYYY-MM-DD').format('YYYYMMDD')
        )
        this.unsubscribeBookings = this.bookingsRef.onSnapshot((snap) => {
          console.log('listenBookings')
          const combinedBookings = this.state.rawReservations || []
          const shards = this.state.shards || {}
          snap.forEach((doc) => {
            shards[doc.id] = Object.keys(doc.data()).map((key) => {
              return { ...doc.data()[key], id: key }
            })
            combinedBookings.push(
              ...Object.keys(doc.data()).map((key) => {
                return { ...doc.data()[key], id: key }
              })
            )
          })
          this.setState({ loadingModalOpen: false, rawReservations: combinedBookings, shards }, this.filterData)
        })
        // ---sharded

        // indexes
        // this.bookingsRef = this.props.firebase.listenBookingStore(
        //   moment.utc(this.state.selectedDate, 'YYYY-MM-DD').format('YYYYMMDD')
        // )
        // this.unsubscribeBookings = this.bookingsRef.onSnapshot((doc) => {
        //   // console.log('listening bookings')
        //   this.setState({ loadingModalOpen: false })
        //   // if (doc && !doc.empty) { // rtdb
        //   if (doc && doc.exists) { // firestore
        //     const _s1 = new Date().getTime()
        //     const data = doc.data()
        //     const keys = Object.keys(data)
        //     const bookings = keys.map((key) => { return { ...data[key], id: key } })
        //     // const bookings = Object.keys(doc.data()).map((key) => { return { ...doc.data()[key], id: key } })
        //     this.setState({ rawReservations: bookings }, this.filterData)
        //   } else {
        //     // console.log('raw empty')
        //     this.setState({ rawReservations: [] }, this.filterData)
        //   }
        // })
        // ---indexes
        this.getMOTD()
        // console.timeEnd('loader')
      }
    }
  }

  listenFreeTimes = () => {
    this.freetimesRef = this.props.firebase.listenFreetimes()
    this.unsubscribeFreetimes = this.freetimesRef.on('value', (snapshot) => {
      console.log('listenFreeTimes')
      if (snapshot && snapshot.exists()) {
        const _freetimes = JSON.parse(JSON.stringify(snapshot.val()))
        const _keys = Object.keys(_freetimes)
        // const groupedByDay = {}
        const freeEvents = []
        const _justNow = parseInt(moment.utc().add(10, 'minutes').format('X'))
        // eslint-disable-next-line
        _keys.map((curr, index) => {
          // console.log(curr)
          // const _d = moment.utc(curr, 'X').format('YYYYMMDD')
          // console.log('curr', moment.utc(curr, 'X').format('YYYYMMDD HH:mm'), '_justNow', moment.utc(_justNow, 'X').format('YYYYMMDD HH:mm'))
          if (_justNow < parseInt(curr)) {
            const _resourceids = Object.keys(_freetimes[curr]).filter((k) => k !== 'start') //resourceids
            // console.log(JSON.stringify(_ks))
            // const _durs = {}
            // eslint-disable-next-line
            _resourceids.map((k) => {
              // console.log(JSON.stringify(_freetimes[curr][k]))
              // eslint-disable-next-line
              /*const _times = */ _freetimes[curr][k].times
                .filter((x) => x.duration < 200)
                // eslint-disable-next-line array-callback-return
                .map((t) => {
                  freeEvents.push({
                    allDay: false,
                    start: moment.utc(curr, 'X').format('YYYY-MM-DDTHH:mm:ss.000Z'),
                    end: moment.utc(curr, 'X').add(t.duration, 'minutes').format('YYYY-MM-DDTHH:mm:ss.000Z'),
                    id: t.key,
                    data: { state: 'open' },
                    paymentstatus: { cost: 0, paid: 0 },
                    resourceId: k,
                    title: t.price + ' €',
                  })
                  // _durs[t.duration] = 1
                  // return t
                })
              // _freetimes[curr][k].times = _times
              // return _freetimes[curr][k]
            })
            // let courts = _ks.map((k) => {

            //   // console.log(JSON.stringify(_freetimes[curr][k]))
            //   if (cats.map((cat) => cat.category).includes(_freetimes[curr][k].category)) {
            //     let _times = _freetimes[curr][k].times.filter((x) => x.duration < 200).map((t) => {
            //       _durs[t.duration] = 1
            //       return t
            //     })
            //     _freetimes[curr][k].times = _times
            //     return _freetimes[curr][k]
            //   } else {
            //     return null
            //   }
            // }).filter((x) => x)
            // _resourceids.map((k) => {
            //   delete _freetimes[curr][k]
            // })

            // if (!groupedByDay[_d]) groupedByDay[_d] = []
            // _freetimes[curr].courts = courts
            // _freetimes[curr].durations = Object.keys(_durs)
            // _freetimes[curr].pricerange = [0, 1]
            // _freetimes[curr].id = _d
            // _freetimes[curr].key = _d + '.' + index
            // if (_freetimes[curr].courts && _freetimes[curr].courts.length > 0) {
            //   groupedByDay[_d].push(_freetimes[curr])
            // }
          }
        })
        this.setState({ freeEvents }, this.filterData)
        // this.setState({ freetimes: groupedByDay, freetimesRaw: snapshot.val(), freeEvents })
      }
    })
  }

  listenLights = () => {
    this.lightsRef = this.props.firebase.listenLights()
    this.unsubscribeLights = this.lightsRef.on('value', (snapshot) => {
      console.log('listenLights')
      if (snapshot && snapshot.exists()) {
        const lights = JSON.parse(JSON.stringify(snapshot.val()))
        // console.log('lights', lights)
        this.setState({ lights })
      }
    })
  }

  listenInfoTexts = () => {
    this.infotextRef = this.props.firebase.listenInfoTexts()
    this.unsubscribeInfoTexts = this.infotextRef.on('value', (snapshot) => {
      console.log('listenInfoTexts')
      if (snapshot && snapshot.exists()) {
        const _infotexts = JSON.parse(JSON.stringify(snapshot.val()))
        this.setState({ infotexts: _infotexts })
      }
    })
  }

  toggleModalNotice = () => {
    this.setState({
      modalNotice: !this.state.modalNotice,
    })
  }

  async componentDidMount() {
    try {
      console.log('CourtsContainer componentDidMount')
      // this.unsubscribeResources = this.props.firebase.listenResourses().onSnapshot((snapshot) => {
      //   console.log('listenResourses')
      //   let _r = snapshot.docs
      //     .map((sh) => {
      //       // console.log(sh.data())
      //       return { ...sh.data(), id: sh.id }
      //     })
      //     .filter((r) => {
      //       if (r.state) {
      //         return r.state === 'active'
      //       } else {
      //         return true
      //       }
      //     })
      //   this.setState({ rawResources: _r }, this.filterData)
      // })
      this.listenBookings()
      this.listenLights()
      this.listenInfoTexts()
      this.getOpeningTimes()
      this.props.firebase.getResourceCategories().get().then((doc) => {
        console.log('getResourceCategories doc', doc.data())
        this.setState({ categories: doc.data().categories })
      })
      const _cats = await this.props.firebase.getColorCategories().get()
      if (_cats.exists) {
        console.log('getColorCategories', _cats.data())
        const categories = _cats.data().categories.filter((c) => c.enabled).map((c) => {
          if (c.visibility === undefined) c.visibility = true;
          return c
        })
        const colors = { categories }
        const recurrentColor = categories.find((c) => c.type === 'reservationtype' && c.category === 'vakio')
        const onsaleColor = categories.find((c) => c.type === 'reservationtype' && c.category === 'onsale')
        const onetimeColor = categories.find((c) => c.type === 'reservationtype' && c.category === 'yksittäinen')

        this.setState({
          colorcategories: colors,
          recurrentColor: recurrentColor ? recurrentColor.color : null,
          onsaleColor: onsaleColor ? onsaleColor.color : null,
          onetimeColor: onetimeColor ? onetimeColor.color : null,
        })
      }
      if (this.filterRef && this.filterRef.current && !isMobile) {
        let calendarSpace = Math.round(
          ((window.innerHeight - this.filterRef.current.clientHeight - 62 - 30) / window.innerHeight) * 100
        )
        calendarSpace = calendarSpace > 80 ? 80 : calendarSpace
        this.setState({ calendarHeight: calendarSpace + 'vh' })
      }
      this.updateFilters()
    } catch (error) {
      console.log('CourtsContainer componentDidMount error', error)
    }
  }

  componentWillUnmount() {
    // this.unsubscribeResources()
    if (this.bookingsRef) {
      this.unsubscribeBookings()
      // this.bookingsRef.off('value', this.unsubscribeBookings)
    }
    if (this.freetimesRef) {
      this.freetimesRef.off('value', this.unsubscribeFreetimes)
    }
    if (this.infotextRef) {
      this.infotextRef.off('value', this.unsubscribeInfoTexts)
    }
  }

  toggleReservationModal = () => {
    if (this.state.reservationModal) {
      let _e = this.state.events.filter((e) => !e?.id?.startsWith('placeholder-'))
      // let _e = this.state.events.filter((e) => e.id !== 'placeholder')
      this.setState({
        reservationModal: !this.state.reservationModal,
        events: _e,
      })
    } else {
      this.setState({ reservationModal: !this.state.reservationModal })
    }
  }

  toggleReservationAlert = () => {
    this.setState({ reservationAlert: !this.state.reservationAlert })
  }

  toggleCustomerSideDrawer = () => {
    this.setState({ customerSideDrawerOpen: !this.state.customerSideDrawerOpen })
  }

  toggleBookingsSideDrawer = () => {
    this.setState({ bookingsSideDrawerOpen: !this.state.bookingsSideDrawerOpen })
  }

  toggleLatestSideDrawer = () => {
    this.setState({ latestSideDrawerOpen: !this.state.latestSideDrawerOpen })
  }

  clearState = () => {
    this.setState({
      selectedResource: null,
    })
  }

  setReservationProperty = (property, value) => {
    // console.log(property + ' => ' + value)
    let w = this.state.selectedResource ? this.state.selectedResource : {}
    w[property] = value
    this.setState({ selectedResource: w })
  }

  reservationAlert = () => {
    return (
      <Alert color='danger' isOpen={this.state.reservationAlert} toggle={this.toggleReservationAlert} fade={true}>
        Vanhaa tapahtumaa ei voi muuttaa.
      </Alert>
    )
  }

  onSelectPerson = (person) => {
    this.setState({ selectedPerson: person, personSelected: true })
  }

  reservationModal = () => {
    return (
      <ReservationModal
        isOpen={this.state.reservationModal}
        toggle={this.toggleReservationModal}
        resource={this.state.selectedResource}
        onSelectPerson={this.onSelectPerson}
        tags={
          this.state.colorcategories
            ? this.state.colorcategories.categories.filter((c) => c.type === 'tag' && c.enabled)
            : []
        }
        {...this.props}
      />
    )
  }

  toggleLoadingModal = () => {
    this.setState({ loadingModalOpen: !this.state.loadingModalOpen })
  }

  openReservationModalFromDrawer = async (id, occurency) => {
    const reid = id.split('---').pop()
    const reservation = await this.props.firebase.getReservation(reid)
    const start = moment.utc(reservation.data().resourcegroups[0].start)
    const end = moment.utc(reservation.data().resourcegroups[0].end)
    const selectedReservation = {
      id: reservation.id,
      start: moment.utc(occurency.date).hour(start.hour()).minutes(start.minutes()).format(),
      end: moment.utc(occurency.date).hour(end.hour()).minutes(end.minutes()).format(),
    }
    selectedReservation.data = { ...reservation.data(), id: reservation.id }
    // console.log('selectedReservation', selectedReservation)
    this.setState({ selectedResource: selectedReservation }, this.toggleReservationModal)
    // this.setState({ reservationModalOpen: true, selectedReservationResource: resource })
    return true
  }

  handleSelectEvent = async (event) => {
    if (event.data && event.data.id && event.data.id.startsWith('ph-')) {
      return
    }

    if (this.state.multicancel) {
      // console.log('selected event', event)
      event.resourcedata = this.state.resources.find((x) => x.id === event.resourceId)
      let cartCancelEvents = []
      if (event.cancelselect) {
        cartCancelEvents = [...this.state.cartCancelEvents].filter((d) => {
          let found = false
          if (d.data.isrecurring) {
            found =
              event.id === d.id &&
              moment.utc(event.start).format('YYYY-MM-DD') === moment.utc(d.start).format('YYYY-MM-DD')
          } else {
            found = event.id === d.id
          }
          return !found
        })
      } else {
        cartCancelEvents = [...this.state.cartCancelEvents]
        cartCancelEvents.push(event)
      }
      const _c = [...this.state.events]
      const _sel = _c.map((d) => {
        if (cartCancelEvents.find((c) => c.id === d.id)) {
          d.cancelselect = true
          return d
        } else {
          delete d.cancelselect
        }
        return d
      })
      this.setState({ cartCancelEvents, events: _sel })
    } else {
      this.toggleLoadingModal()
      const reservation = await this.props.firebase.getReservation(event.id)
      let canOpen = true
      if (this.props.profile.role === 'PARTNER' || this.props.profile.role === 'COOPERATIVE') {
        const ok = event?.data?.persons?.payers.find((p) => {
          const isContact = p.contacts ? p.contacts.find((x) => x.id === this.props.user.uid) : null
          const isUser = p.users ? p.users.find((x) => x.id === this.props.user.uid) : null
          const isOwn = p.id === this.props.user.uid
          if (isContact || isUser || isOwn) {
            return true
          }
          return false
        })
        if (!ok) canOpen = false
      }
      if (reservation.exists && canOpen) {
        const selectedResource = JSON.parse(JSON.stringify(event))
        selectedResource.data = { ...reservation.data(), id: reservation.id }
        this.toggleLoadingModal()
        this.setState({ selectedResource }, this.toggleReservationModal)
      } else if (!canOpen) {
        alert('Ei oikeuksia avata')
        this.toggleLoadingModal()
      } else {
        console.log('cant find reservation', event)
        const isOkEvenNotFound = event?.id?.startsWith('placeholder-') || event?.id?.startsWith('ph-') || event?.title?.startsWith('Ei hinnoittelua') || event?.data?.notitle === true
        if (!isOkEvenNotFound) {
          alert('cant find reservation')
        }
        this.toggleLoadingModal()
      }
    }
  }

  openMenu = (event) => {}

  handleSelectSlot = (slotInfo) => {
    // console.log('handleSelectSlot')
    // console.log(slotInfo)
    let s = moment(slotInfo.start)
    let e = moment(slotInfo.end)
    if (e.diff(s, 'minutes') < 60) {
      e = moment(s).add(60, 'minutes')
    }
    this.setState({ selectedResource: null })
    let _ePlaceholder = {
      id: 'placeholder-' + uuidv4(),
      // id: 'placeholder',
      start: s.add(180, 'minutes').toDate(),
      end: e.add(180, 'minutes').toDate(),
      resourceId: slotInfo.resourceId,
    }
    let cartEvents = [...this.state.cartEvents]
    if (this.state.multireserve) {
      _ePlaceholder.resourcedata = this.state.resources.find((x) => x.id === slotInfo.resourceId)
      cartEvents.push(_ePlaceholder)
    }

    let newEvents = [...this.state.events]
    newEvents.push(_ePlaceholder)
    this.setState({ events: newEvents, cartEvents })
    if (!this.state.multireserve) {
      this.setReservationProperty('start', s.format(TIMEFORMAT))
      this.setReservationProperty('end', e.format(TIMEFORMAT))
      this.setReservationProperty('resourceid', slotInfo.resourceId)
      this.toggleReservationModal()
    }
    // this.setState({ events: newEvents })
    // this.setReservationProperty('start', s.format(TIMEFORMAT))
    // this.setReservationProperty('end', e.format(TIMEFORMAT))
    // this.setReservationProperty('resourceid', slotInfo.resourceId)
    // this.toggleReservationModal()
  }

  updateFilter = (filter, status) => {
    let fss = {}
    if (filter === 'category') {
      const { cookies } = this.props
      fss = { categoryFilter: status, ownFilter: null }
      cookies.set('categoryFilter', status, { path: '/' })
      cookies.set('ownFilter', null, { path: '/' })
      this.setState(fss, this.updateFilters)
    }
  }

  getCategories = () => {
    if (this.props.customerdata && this.props.customerdata.resources) {
      let _cs = this.props.customerdata.resources.map((e) => e.category)
      return [...new Set(_cs)]
    } else {
      return []
    }
    // if (this.state.rawResources) {
    //   let _cs = this.state.rawResources.map((e) => e.category)
    //   return [...new Set(_cs)]
    // } else {
    //   return []
    // }
  }

  getResourceCategoryFilters = () => {
    if (this.state.categories && this.state.categories.length > 0) {
      const sortedCategories = this.state.categories.sort((a, b) => a.order - b.order).filter((c) => c.enabled)
      // console.log('sortedCategories', sortedCategories, this.state.categoryFilter, this.state.ownFilter)
      const _c = sortedCategories.map((e) => {
        const _cat = e.category.toLowerCase()
        return (
          <Button
            size={'xs'}
            key={_cat}
            className='btn-round'
            color='info'
            outline
            type='button'
            onClick={() => this.updateFilter('category', _cat)}
            active={this.state.categoryFilter === _cat && !this.state.ownFilter}
          >
            {e.category}
          </Button>
        )
      })
      _c.push(
        <Button
          size={'xs'}
          className='btn-round'
          key='typeall'
          color='info'
          outline
          type='button'
          onClick={() => this.updateFilter('category', 'all')}
          active={(!this.state.categoryFilter || this.state.categoryFilter === 'all') && !this.state.ownFilter}
        >
          Kaikki
        </Button>
      )
      return _c
    } else {
      return [
        <Button
          size={'xs'}
          key='typeall'
          className='btn-round'
          color='info'
          outline
          type='button'
          onClick={() => this.updateFilter('category', 'all')}
          active={!this.state.categoryFilter || this.state.categoryFilter === 'all'}
        >
          Kaikki
        </Button>,
      ]
    }
  }

  // getCategoryFilters = () => {
  //   if (this.state.rawResources) {
  //     const _c = this.getCategories().map((e) => {
  //       return (
  //         <Button
  //           size={'xs'}
  //           key={e}
  //           className='btn-round'
  //           color='info'
  //           outline
  //           type='button'
  //           onClick={() => this.updateFilter('category', e)}
  //           active={this.state.categoryFilter === e && !this.state.ownFilter}
  //         >
  //           {e}
  //         </Button>
  //       )
  //     })
  //     _c.push(
  //       <Button
  //         size={'xs'}
  //         className='btn-round'
  //         key='typeall'
  //         color='info'
  //         outline
  //         type='button'
  //         onClick={() => this.updateFilter('category', 'all')}
  //         active={(!this.state.categoryFilter || this.state.categoryFilter === 'all') && !this.state.ownFilter}
  //       >
  //         Kaikki
  //       </Button>
  //     )
  //     return _c
  //   } else {
  //     return [
  //       <Button
  //         size={'xs'}
  //         key='typeall'
  //         className='btn-round'
  //         color='info'
  //         outline
  //         type='button'
  //         onClick={() => this.updateFilter('category', 'all')}
  //         active={!this.state.categoryFilter || this.state.categoryFilter === 'all'}
  //       >
  //         Kaikki
  //       </Button>,
  //     ]
  //   }
  // }

  popoverButtonClickHandler = (event) => {
    // console.log(event)
  }

  showMoved = (event) => {
    if (event.shiftKey) {
      this.setState({ showmoved: !this.state.showmoved }, this.filterData)
    }
  }

  setShowCancelled = (event) => {
    // console.log(event.target.checked)
    this.setState({ showcancelled: event.target.checked }, this.filterData)
    // this.setState({ showcancelled: event.state.value }, this.filterData)
  }

  setShowOnsale = (event) => {
    this.setState({ showonsale: event.target.checked }, this.filterData)
    // this.setState({ showonsale: event.state.value }, this.filterData)
  }

  endAccessor = (event) => {
    // return moment(event.end).toDate()
    return moment(event.end).subtract(180, 'minutes').toDate()
  }

  startAccessor = (event) => {
    return moment(event.start).subtract(180, 'minutes').toDate()
  }

  dateChanged = (event) => {
    this.setState({ selectedDate: moment.utc(event).format('YYYY-MM-DD') }, this.updateListeners)
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    const isOwn = isOwnEvent(event, this.props.user.uid)
    let s = {
      color: 'white',
    }

    if (event?.id?.startsWith('placeholder-')) {
      s.backgroundColor = `mediumseagreen`
    }

    if (event.data) {
      if (event.data.isrecurring || event.data.parentid) {
        if (this.state.recurrentColor) {
          s.backgroundColor = `rgba(${this.state.recurrentColor.r}, ${this.state.recurrentColor.g}, ${this.state.recurrentColor.b}, ${this.state.recurrentColor.a})`
        }
      } else {
        if (this.state.onetimeColor && !event.data.parentid) {
          s.backgroundColor = `rgba(${this.state.onetimeColor.r}, ${this.state.onetimeColor.g}, ${this.state.onetimeColor.b}, ${this.state.onetimeColor.a})`
        }
      }
      if (event.data.state === 'onsale' && this.state.onsaleColor) {
        s.backgroundColor = `rgba(${this.state.onsaleColor.r}, ${this.state.onsaleColor.g}, ${this.state.onsaleColor.b}, ${this.state.onsaleColor.a})`
      }

      if (event.data.parentid) {
        s.fontStyle = 'italic'
      }

      // if (event.data.state === 'reserved') {
      //   s.backgroundColor = RESERVED_COLOR // greenyellow
      // }

      if (event.data.persons?.owner && event.data.persons.owner[0]) {
        // console.log(event.data.persons.owner)
        let _p = event.data.persons.owner[0]
        if (_p.businessId || _p.email || _p.name) {
          s.backgroundColor = SHARE_COLOR
        } else if (_p.title) {
          s.backgroundColor = UNKNOWNSHARE_COLOR
        }
      }

      if (event.data.persons?.payers && this.state.colorcategories) {
        let _p = event.data.persons.payers[0]
        if (!_p) {
          _p = { email: 'noemail' }
        }
        if (_p.email) {
          let userColor = this.state.colorcategories.categories.find(
            (c) => c.type === 'user' && _p.email.indexOf(c.category) > -1
          )
          if (userColor) {
            s.backgroundColor = `rgba(${userColor.color.r}, ${userColor.color.g}, ${userColor.color.b}, ${userColor.color.a})`
          }
        }

        if (event.data.tags) {
          let tagColor = this.state.colorcategories.categories.find(
            (c) => c.type === 'tag' && event.data.tags.includes(c.category)
          )
          if (tagColor) {
            s.backgroundColor = `rgba(${tagColor.color.r}, ${tagColor.color.g}, ${tagColor.color.b}, ${tagColor.color.a})`
          }
        }

        // && _p.email.indexOf('hvstennis.fi') > -1) {
        //   s.backgroundColor = HVS_COLOR
        // }
        // if (_p.email && _p.email.indexOf('tennishedman.fi') > -1) {
        //   s.backgroundColor = HEDMAN_COLOR
        // }
      }

      if (this.props.profile.role === 'COOPERATIVE' && !isOwn) {
        delete s.backgroundColor
      }

      if (event.data.state === 'cancelled') {
        s.backgroundColor = CANCELLED_COLOR // tomato
      } else if (event.data.state === 'onsale') {
        s.fontStyle = 'italic'
        s.borderStyle = 'dashed'
        s.backgroundClip = 'padding-box'
        if (this.state.onsaleColor) {
          s.backgroundColor = `rgba(${this.state.onsaleColor.r}, ${this.state.onsaleColor.g}, ${this.state.onsaleColor.b}, ${this.state.onsaleColor.a})`
          // if (event.data.isrecurring) {
          //   s.backgroundColor = `rgb(235,235,235)`
          // }
        } else {
          if (event.data.isrecurring) {
            s.backgroundColor = `rgb(220,220,220)`
          } else {
            s.backgroundColor = `rgb(244,243,239)`
          }
        }
      }

      if (event.data.state === 'open') {
        s.backgroundColor = `rgb(0,0,0)`
      }

      if (event.data && event.data.id && event.data.id.startsWith('ph-') && event.data.state === 'reserved') {
        s.backgroundColor = `lightgray`
      }

      const color1 = new TinyColor(s.backgroundColor)
      if (color1.isLight()) s.color = 'black'

      if (event.paymentstatus) {
        if (event.paymentstatus.cost === event.paymentstatus.paid && event.paymentstatus.cost > 0) {
          // s['text-decoration'] = 'underline'
        }
      }

      if (event.data.notitle) {
        s.color = `rgba(128, 128, 128, 0.0)`
        s.backgroundColor = `rgba(128, 128, 128, 0.8)`
      }

      if (event.title === 'Ei hinnoittelua') {
        // s.color = `rgba(235, 235, 235, 0.0)`
        s.backgroundColor = `rgba(235, 235, 235, 0.8)`
        s.color = 'rgba(76,175,80, 0.7)'
      }
    }

    if (event?.cancelselect) {
      // s.backgroundColor = `black`
      s.borderColor = `red`
      s.borderWidth = `3px`
    }

    if (this.state.eventsearch && this.state.eventsearch.length > 2) {
      let found =
        (event.title && event.title.toLowerCase().indexOf(this.state.eventsearch.toLowerCase()) > -1) ||
        (event.data.title && event.data.title.toLowerCase().indexOf(this.state.eventsearch.toLowerCase()) > -1)
      if (!found) {
        s.opacity = '20%'
      }
    }

    if (event.data && event.data.notes && event.data.notes.length > 0) {
      s.borderStyle = 'outset'
      s.borderWidth = '2px'
    }

    if (event.data && event.data.flexendtime) {
      s.borderRightStyle = 'outset'
      s.borderRightWidth = '6px'
    }

    if (event.data && ((event.data.title && NOLIGHTS_TITLES.includes(event.data.title.toLowerCase())) || event.data.nolights)) {
      const bg = s.backgroundColor
      // s.background = 'repeating-linear-gradient(-45deg, ' + bg + ', ' + bg + ' 2px, #797676 2px, #797676 10px)'
      // s.background = 'repeating-linear-gradient(-45deg, ' + bg + ', #797676 2px, #797676 2px, ' + bg + ' 10px)'
      // s.background = 'repeating-linear-gradient(-45deg, #797676 0px, #797676 2px, ' + bg + ' 2px, ' + bg + ' 10px)'
      s.background = 'linear-gradient(45deg, ' + bg + ', ' + bg + ', #222222)'
      delete s.backgroundColor
    }

    return {
      style: s,
    }
  }

  toggleMultireserve = () => {
    if (this.state.cartEvents.length === 0 && this.state.cartCancelEvents.length === 0) {
      this.setState({ multireserve: !this.state.multireserve, multicancel: false })
    }
  }

  toggleMulticancel = () => {
    if (this.state.cartCancelEvents.length === 0 && this.state.cartEvents.length === 0) {
      this.setState({ multicancel: !this.state.multicancel, multireserve: false })
    }
  }

  toggleMultireserveModal = () => {
    if (this.state.cartEvents.length > 0) {
      if (!this.state.multireserveOpen && this.state.multireservationpayer) {
        this.setState(
          { multireserveOpen: !this.state.multireserveOpen, multicancelOpen: false, checkingPrice: true },
          this.updateMultiPrices
        )
      } else {
        this.setState({ multireserveOpen: !this.state.multireserveOpen, multicancelOpen: false })
      }
    } else {
      this.setState({ multireserveOpen: false, multicancelOpen: false })
    }
  }

  toggleMulticancelModal = () => {
    // console.log('Open Cancel Modal')
    if (this.state.cartCancelEvents.length > 0) {
      this.setState({ multicancelOpen: !this.state.multicancelOpen, multireserveOpen: false })
    }
  }

  cancelMultiReservation = async () => {
    const occurenciesToCancel = this.state.cartCancelEvents.map((e) => {
      let _e = {}
      _e.id = e.id
      _e.occurency = moment.utc(e.start).format('YYYY-MM-DD')
      return _e
    })
    // console.log(occurenciesToCancel, this.state.multicancelmessagesubject, this.state.multicancelmessagebody)
    /* const response = */ this.props.firebase.cancelMultiReservation(
      occurenciesToCancel,
      this.state.multicancelmessagesubject,
      this.state.multicancelmessagebody
    )
    this.setState({
      multicancelmessagesubject: null,
      multicancelmessagebody: null,
      cartCancelEvents: [],
      multicancelOpen: false,
    })
  }

  saveMultiReservation = async () => {
    this.setState({ multireserveSaving: true })
    const _e = this.state.cartEvents.map((e) => {
      return convertCartToReservations(e, this.state.multireservationpayer, this.state.multititle, this.state.multitags)
    })
    // console.log('saving', _e)
    for (const r of _e) {
      /*const response = */ await this.props.firebase.createReservation(r)
      // console.log('added reservation', r, response)
    }
    this.setState({ multititle: null, multireservationpayer: null, cartEvents: [], multireserveSaving: false }, this.toggleMultireserveModal)
  }

  handleOverrideCostChange = (event, cartid) => {
    if (isNaN(parseFloat(event.target.value))) {
      const items = this.state.cartEvents.map((e) => {
        if (e.id === cartid) delete e.overridecost
        return e
      })
      this.setState({ cartEvents: items })
    } else {
      const items = this.state.cartEvents.map((e) => {
        if (e.id === cartid) e.overridecost = parseFloat(event.target.value)
        return e
      })
      this.setState({ cartEvents: items })
    }
  }

  loadingModal = () => {
    return (
      <Modal size='sm' isOpen={this.state.loadingModalOpen} toggle={this.toggleLoadingModal} backdrop={'static'}>
        <ModalBody>
          <Spinner />
        </ModalBody>
      </Modal>
    )
  }

  displayFree = (event) => {
    event.stopPropagation()
    if (event.ctrlKey && event.shiftKey) {
      this.listenFreeTimes()
    }
  }

  handleTitleChange = (e) => {
    this.setState({ multititle: e.target.value })
  }

  updateMultiPrices = () => {
    // console.log(this.state.cartEvents)
    Promise.all(
      this.state.cartEvents.map(async (_slot) => {
        // console.log(_slot)
        const slot = {
          start: moment.utc(_slot.start).format('YYYY-MM-DD HH:mm'),
          end: moment.utc(_slot.end).format('YYYY-MM-DD HH:mm'),
          resourceId: _slot.resourceId,
        }
        const response = await this.props.firebase.getReservationPrices(slot, null)
        if (response.data && response.data.prices) {
          const general = response.data.prices.find((x) => x.pricing === 'general')
          if (general) _slot.cost = general.price
        }
        return _slot
      })
    ).then((items) => {
      // console.log('items', items)
      this.setState({ checkingPrice: false, cartEvents: items })
    })
  }

  handleMultiCancelMessageBodyChange = (e) => {
    this.setState({ multicancelmessagebody: e.target.value })
  }

  handleMultiCancelMessageSubjectChange = (e) => {
    this.setState({ multicancelmessagesubject: e.target.value })
  }

  handleMultiCancelConfirmChange = (e) => {
    this.setState({ multicancelconfirmation: e.target.checked })
  }

  subtotal = () => {
    return this.state.cartEvents
      .map(({ cost, overridecost }) => {
        if (!isNaN(parseFloat(overridecost))) {
          return overridecost
        } else if (cost) {
          return cost
        }
        return 0
      })
      .reduce((sum, i) => sum + i, 0)
  }

  multiReservationModal = () => {
    return (
      <Modal size='xl' isOpen={this.state.multireserveOpen} toggle={this.toggleMultireserveModal} backdrop={'static'}>
        <ModalHeader toggle={this.toggleMultireserveModal}>Varaa</ModalHeader>
        <ModalBody>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label='sticky a dense table ' size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Kategoria</TableCell>
                  <TableCell>Nimi</TableCell>
                  <TableCell align='right'>Aika</TableCell>
                  <TableCell align='right'>Kesto</TableCell>
                  <TableCell align='right'>Hinta</TableCell>
                  <TableCell align='right'>Käsinkorjattu hinta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.cartEvents.map((row) => {
                  return (
                    <TableRow style={{ maxHeight: 30 }}>
                      <TableCell>{row.resourcedata.category}</TableCell>
                      <TableCell>{row.resourcedata.displayName}</TableCell>
                      <TableCell align='right'>
                        {moment.utc(row.start).format('dddd D.M. HH:mm')} - {moment.utc(row.end).format('HH:mm')}
                      </TableCell>
                      <TableCell align='right'>
                        {moment.utc(row.end).diff(moment.utc(row.start), 'minutes')} min
                      </TableCell>
                      <TableCell align='right'>
                        {this.state.checkingPrice ? (
                          <Spinner size='sm' />
                        ) : row.cost === 'undefined' ? (
                          ''
                        ) : (
                          row.cost + ' €'
                        )}
                      </TableCell>
                      <TableCell align='right'>
                        <TextField
                          id='overridecost'
                          type='number'
                          value={row.overridecost === 'undefined' ? '' : row.overridecost}
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                          }}
                          style={{ maxWidth: '8ch' }}
                          onChange={(e) => this.handleOverrideCostChange(e, row.id)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  <TableCell rowSpan={2} colSpan={4} />
                  <TableCell align='right'>
                    <b>Yhteensä</b>
                  </TableCell>
                  <TableCell align='right'>
                    <b>{this.subtotal()} €</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Row>
            <Col md='4'>
              <Autocomplete
                options={this.state.searchpayers ? this.state.searchpayers : []}
                getOptionLabel={this.handleOptionLabel} // {option => option.name}
                onChange={this.handlePayer}
                getOptionDisabled={this.isValidBusiness}
                getOptionSelected={(option, val) => {
                  return option.id === val.id
                }}
                value={this.state?.multireservationpayer}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue.length > 2) {
                    this.searchCombinedPayers(newInputValue)
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant='standard'
                      label='Lisää maksaja'
                      placeholder='Maksajat'
                      margin='normal'
                      fullWidth
                    />
                  )
                }}
                renderOption={(option) => {
                  let title = ''
                  if (option.businessId) {
                    title = option.name
                  } else {
                    title = option.firstname && option.lastname ? option.firstname + ' ' + option.lastname : ''
                  }
                  return (
                    <Grid container alignItems='center'>
                      <Grid item xs>
                        <span>{title}</span>
                        <Typography variant='body2' color='textSecondary'>
                          {option.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                }}
              />
            </Col>
            <Col md='4'>
              <TextField
                id='title'
                label='Otsikko'
                style={{ marginBottom: 40, marginTop: 20 }}
                fullWidth
                value={this.state.multititle || ''}
                onChange={this.handleTitleChange}
              />
            </Col>
            <Col md='4'>
              <Autocomplete
                multiple
                options={
                  this.state.colorcategories
                    ? this.state.colorcategories.categories
                        .filter((c) => c.type === 'tag' && c.enabled)
                        .map((t) => t.category)
                    : []
                }
                // getOptionLabel={this.handleTagOptionLabel} // {option => option.name}
                onChange={this.handleTags}
                // getOptionSelected={(option, val) => { return option.id === val.id }}
                value={this.state?.multitags ? this.state?.multitags : []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='standard'
                    label='Lisää tagejä'
                    placeholder='Tagit ...'
                    margin='normal'
                    fullWidth
                  />
                )}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className='mr-auto'>
            <Button
              color='success'
              onClick={() => this.saveMultiReservation()}
              disabled={!this.state.multireservationpayer || this.state.checkingPrice || this.state.multireserveSaving}
            >
              {this.state.multireserveSaving ? <Spinner /> : 'Tallenna'}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }

  multiCancelModal = () => {
    return (
      <Modal size='xl' isOpen={this.state.multicancelOpen} toggle={this.toggleMulticancelModal} backdrop={'static'}>
        <ModalHeader toggle={this.toggleMulticancelModal}>Peru</ModalHeader>
        <ModalBody>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label='sticky a dense table ' size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Nimi</TableCell>
                  <TableCell>Kenttä</TableCell>
                  <TableCell align='right'>Aika</TableCell>
                  <TableCell align='right'>Kesto</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.cartCancelEvents.map((row) => {
                  return (
                    <TableRow style={{ maxHeight: 30 }}>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.resourcedata.displayName}</TableCell>
                      <TableCell align='right'>
                        {moment.utc(row.start).format('dddd D.M. HH:mm')} - {moment.utc(row.end).format('HH:mm')}
                      </TableCell>
                      <TableCell align='right'>
                        {moment.utc(row.end).diff(moment.utc(row.start), 'minutes')} min
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Row>
            <Col style={{ paddingTop: 30 }}>
              <Typography mt={2} variant='subtitle2' component='span'>
                Peruutusviestin saate
              </Typography>
              <Form>
                <FormGroup>
                  <Label for='exampleEmail'>Otsikko</Label>
                  <Input
                    id='multiCancelMessageSubject'
                    value={this.state.multicancelmessagesubject ? this.state.multicancelmessagesubject : ''}
                    name='multiCancelMessageSubject'
                    onChange={this.handleMultiCancelMessageSubjectChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='messageBody'>Viesti</Label>
                  <Input
                    rows='5'
                    value={this.state.multicancelmessagebody ? this.state.multicancelmessagebody : ''}
                    id='messageBody'
                    name='messageBody'
                    type='textarea'
                    style={{ maxHeight: 400 }}
                    onChange={this.handleMultiCancelMessageBodyChange}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                {/* <FormGroup check> */}
                <Label check>
                  <Input type='checkbox' onChange={this.handleMultiCancelConfirmChange} /> Vahvista peruminen
                </Label>
                {/* </FormGroup> */}
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className='mr-auto'>
            <Button
              color='success'
              onClick={() => this.cancelMultiReservation()}
              disabled={!this.state.multicancelconfirmation}
            >
              Peru
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }

  handleSearchChange = (string) => {
    this.setState({ eventsearch: string })
  }

  filterDialogClose = (selectedFilter) => {
    const { cookies } = this.props
    cookies.set('ownFilter', selectedFilter, { path: '/' })
    this.setState({ filterDialogOpen: false, ownFilter: selectedFilter }, this.updateFilters)
  }

  filterDialogOpen = () => {
    this.setState({ filterDialogOpen: true })
  }

  closePersonDetails = () => {
    this.setState({ selectedPerson: null, personSelected: false })
  }

  render() {
    // const today = new Date()

    // if (this.state.loaded) {
    const filterLength = this.getCategories().length
    return (
      <>
        <div
          className='content'
          style={this.state.multireserve || this.state.multicancel ? { backgroundColor: 'dimgray' } : {}}
        >
          {this.reservationAlert()}
          <div ref={this.filterRef}>
            {filterLength > 5 ? (
              <Row>
                <Col>
                  <ButtonGroup className='flex-wrap' size={'xs'}>
                    {this.getResourceCategoryFilters()}
                    <Button
                      size={'xs'}
                      className='btn-round'
                      key='ownfilter'
                      color='info'
                      outline
                      type='button'
                      onClick={() => this.filterDialogOpen()}
                      active={this.state.ownFilter}
                    >
                      {this.state.ownFilter ? this.state.ownFilter.name : 'Omat'}
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            ) : null}
            <Row style={desingclass}>
              <Col md='6' className='d-flex justify-content-center' style={desingclass}>
                {filterLength < 6 ? (
                  <ButtonGroup className='flex-wrap' size={'xs'}>
                    {this.getResourceCategoryFilters()}
                    <Button
                      size={'xs'}
                      className='btn-round'
                      key='ownfilter'
                      color='info'
                      outline
                      type='button'
                      onClick={() => this.filterDialogOpen()}
                      active={this.state.ownFilter}
                    >
                      {this.state.ownFilter ? this.state.ownFilter.name : 'Omat'}
                    </Button>
                  </ButtonGroup>
                ) : null}
              </Col>
              <Col md='2' className='d-flex justify-content-center mb-2' style={desingclass}>
                <SearchInput onSearch={this.handleSearchChange} />
              </Col>
              <Col md='4' style={desingclass}>
                {this.state.colorcategories
                  ? this.state.colorcategories.categories
                      .filter((c) => c.enabled && c.visibility)
                      .map((cat, i) => {
                        return (
                          <Badge
                            key={cat.label + '-' + i}
                            size='sm'
                            style={{
                              backgroundColor: `rgba(${cat.color.r}, ${cat.color.g}, ${cat.color.b}, ${cat.color.a})`,
                            }}
                          >
                            {cat.label}
                          </Badge>
                        )
                      })
                  : null}
              </Col>
              <FilterDialog
                resources={this.state.rawResources}
                open={this.state.filterDialogOpen}
                onClose={this.filterDialogClose}
              />
            </Row>
            <Row style={desingclass}>
              <Col md='4' className='align-middle' style={desingclass}>
                <div onClick={(e) => this.displayFree(e)}>
                  <strong style={{ textTransform: 'capitalize' }}>
                    {moment.utc(this.state.selectedDate).format('dddd')}
                  </strong>
                </div>

                <TextField
                  id='endtime'
                  // label='Päivä'
                  type='date'
                  onChange={this.handleDateChange}
                  // defaultValue={moment.utc().format('YYYY-MM-DD')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.selectedDate}
                />
                <>
                  <FirstPageIcon onClick={() => this.moveDate(-7)} style={datenavidation} />
                  <KeyboardArrowLeftIcon onClick={() => this.moveDate(-1)} style={datenavidation} />
                  <Button size='sm' outline style={{ margin: 10 }} onClick={() => this.moveDate(0)}>
                    Tänään
                  </Button>
                  <KeyboardArrowRightIcon onClick={() => this.moveDate(1)} style={datenavidation} />
                  <LastPageIcon onClick={() => this.moveDate(7)} style={datenavidation} />
                </>
              </Col>
              <Col md='4' style={desingclass}>
                {this.state.motd ? <Alert color='info'>{this.state.motd}</Alert> : null}
              </Col>
              <Col md='4' style={desingclass}>
                <FormGroup check>
                  <Row style={desingclass}>
                    <Col style={desingclass}>
                      <Label check className='checklabel'>
                        <Input
                          type='checkbox'
                          className='checks'
                          style={{ opacity: 1, position: 'absolute', visibility: 'visible' }}
                          defaultValue={this.state.showcancelled}
                          onChange={this.setShowCancelled}
                        />{' '}
                        perutut
                      </Label>
                      <br />
                      <Label check className='checklabel'>
                        <Input
                          type='checkbox'
                          className='checks'
                          style={{ opacity: 1, position: 'absolute', visibility: 'visible' }}
                          defaultValue={this.state.showonsale}
                          onChange={this.setShowOnsale}
                        />{' '}
                        myynnissä
                      </Label>
                    </Col>
                    <Col style={desingclass}>
                      {this.state.cartEvents && this.state.cartEvents.length > 0 && this.state.multireserve ? (
                        <Button size='xs' color='success' onClick={() => this.toggleMultireserveModal()}>
                          Korissa ({this.state.cartEvents.length})
                        </Button>
                      ) : (
                        <Button
                          style={{ margin: 2 }}
                          size='xs'
                          onClick={() => this.toggleMultireserve()}
                          color={this.state.multireserve ? 'success' : 'secondary'}
                        >
                          Monivaraus
                        </Button>
                      )}
                      {this.state.cartCancelEvents &&
                      this.state.cartCancelEvents.length > 0 &&
                      this.state.multicancel ? (
                        <Button size='xs' color='danger' onClick={() => this.toggleMulticancelModal()}>
                          Perutaan ({this.state.cartCancelEvents.length})
                        </Button>
                      ) : (
                        <Button
                          size='xs'
                          style={{ margin: 2 }}
                          onClick={() => this.toggleMulticancel()}
                          color={this.state.multicancel ? 'danger' : 'secondary'}
                        >
                          Moniperuutus
                        </Button>
                      )}
                    </Col>
                    <Col style={desingclass}>
                      {/* <Button style={{ margin: 2 }} size='xs' onClick={() => this.toggleCustomerSideDrawer()}>
                        Asiakasnäkymä
                      </Button>
                      <br /> */}
                      <Button style={{ margin: 2 }} size='xs' onClick={() => this.toggleBookingsSideDrawer()}>
                        Varauksetlista
                      </Button>
                      <br />
                      <Button style={{ margin: 2 }} size='xs' onClick={this.toggleLatestSideDrawer}>
                        Uusimmat
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <Row>
            <Col md='12'>
              <Calendar
                ref={this.myRef}
                backgroundEvents={this.state.backgroundevents || []}
                style={{ height: this.state.calendarHeight }}
                selectable
                localizer={localizer}
                events={this.state.events ? this.state.events : []}
                startAccessor={this.startAccessor}
                endAccessor={this.endAccessor}
                onNavigate={this.dateChanged}
                views={['day']}
                step={30}
                min={this.state.dayMin}
                max={this.state.dayMax}
                defaultView={Views.DAY}
                scrollToTime={new Date(1970, 1, 1, 23)}
                date={moment.utc(this.state.selectedDate, 'YYYY-MM-DD').toDate()} // defaultDate={today}
                // onDoubleClickEvent={(event) => this.openMenu(event)}
                onSelectEvent={(event) => this.handleSelectEvent(event)}
                onSelectSlot={this.handleSelectSlot}
                onView={this.updateView}
                components={{
                  resourceHeader: ResourceHeaderContainer({
                    lightsOnOff: this.lightsOnOff,
                    lightStatus: this.state.selectedDate === moment.utc().format('YYYY-MM-DD') ? this.state.lights : {},
                  }),
                  // event: Event,
                  event: (props) =>
                    Event({
                      ...props,
                      deleteCart: this.deleteCart,
                      multireserve: this.state.multireserve,
                      multicancel: this.state.multicancel,
                      role: this.props.profile.role,
                      uid: this.props.user.uid,
                    }),
                  toolbar: EmptyToolbar,
                }}
                eventPropGetter={this.eventStyleGetter}
                resources={this.state.resources}
                resourceIdAccessor='id'
                resourceTitleAccessor='displayName'
                // formats={{ eventTimeRangeFormat: () => null }}
              />
            </Col>
          </Row>
          {this.reservationModal()}
          {this.multiReservationModal()}
          {this.multiCancelModal()}
          {this.state.lightsDialog}
          {this.loadingModal()}

          <Dialog
            fullWidth={true}
            // fullScreen
            maxWidth={'lg'}
            open={this.state.personSelected}
            onClose={this.closePersonDetails}
            aria-labelledby='max-width-dialog-title'
            style={{ zIndex: '1050' }}
            TransitionComponent={Transition}
          >
            {/* <DialogTitle id='max-width-dialog-title'>{this.state.selectedPerson ? this.state.selectedPerson.firstname + ' ' + this.state.selectedPerson.lastname + ' ' + this.state.selectedPerson.email : ''}</DialogTitle> */}
            <DialogContent>
              <PersonDetails person={this.state.selectedPerson} {...this.props} />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closePersonDetails} color='primary'>
                Sulje
              </Button>
            </DialogActions>
          </Dialog>
          <CustomerSideDrawer open={this.state.customerSideDrawerOpen} onClose={this.toggleCustomerSideDrawer} />
          <BookingsSideDrawer
            open={this.state.bookingsSideDrawerOpen}
            onClose={this.toggleBookingsSideDrawer}
            onReservationOpen={this.openReservationModalFromDrawer}
            bookings={this.state.events}
            resources={this.state.resources}
          />
          <LatestSideDrawer
            open={this.state.latestSideDrawerOpen}
            onClose={this.toggleLatestSideDrawer}
            onReservationOpen={this.openReservationModalFromDrawer}
            resources={this.state.resources}
          />
        </div>
      </>
    )
    // } else {
    //   return (
    //     <>
    //       <Row>
    //         <Col>Loading ...</Col>
    //       </Row>
    //     </>
    //   )
    // }
  }
}

export default withCookies(withFirebase(CourtsContainer))
