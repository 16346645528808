import React, { Component } from 'react'
// import { Col, Row, Spinner, Modal, ModalBody, Button, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from 'reactstrap'
import { withFirebase } from '../Fb'
// import ReactTable from 'react-table'
import moment from 'moment'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import ReceiptIcon from '@material-ui/icons/Receipt';
import PhoneIcon from '@material-ui/icons/Phone'
// import AnnouncementIcon from '@material-ui/icons/Announcement'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import MuiButton from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { ListItem, Typography, ListItemText, ListItemSecondaryAction, Divider } from '@material-ui/core'
// import TabPanel from '@material-ui/lab/TabPanel'
import WarningIcon from '@material-ui/icons/Warning'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import { CopyToClipboard } from 'react-copy-to-clipboard'
// import ReactTable from '../Lists/ReactTableWrapper'
import { DataGrid } from '@mui/x-data-grid'
import EditButton from '../Buttons/EditButton'
import PersonBenefitCards from './PersonBenefitCards'
import ShopProductDialog from '../Dialogs/ShopProductDialog'
import PersonProductOrders from '../Lists/PersonProductOrders'

const ACL_FIELDS = ['allowpostpayment', 'blocked', 'blockedMessage', 'quotaoverride']
const CDATA_FIELDS = ['firstname', 'lastname', 'streetaddress', 'zip', 'city', 'country']
const paymenttypes = [
  {
    value: 'transfer',
    label: 'Siirto',
  },
  {
    value: 'compensation',
    label: 'Hyvitys',
  },
  {
    value: 'deposit',
    label: 'Talletus',
  },
]
const PRODUCT_PILOT_CUSTOMERS = [
  'ramu-Tq22jQlBPgbsiJ26bNxg',
  'testilanmailapelikeskus-sgaVLykN',
  'tali-2GjtFLost8pDf0dWwmNc',
  'kerttula-asfji2300203',
  'nokiatennis-1tmwvtS3',
  'evs-4FD8m7rCa7wOuCADvzZZ',
  'valkeakoskentennistuki-22MbYNtH'
]

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  )
}

/** reservations formatting starts */

const getUnixTime = (reservation, border) => {
  const timeOfDayHelper = moment.utc(reservation.resourcegroups[0][border])
  const ordering = moment.utc(reservation.occurency.date)
  ordering.hour(timeOfDayHelper.hour()).minute(timeOfDayHelper.minute())
  if (moment().utcOffset() > 0) {
    ordering.subtract(moment().utcOffset(), 'minute')
  } else {
    ordering.add(moment().utcOffset(), 'minute')
  }
  return ordering.unix()
}

const isInvoiced = (reservation) => {
  const { occurency, payments } = reservation
  if (!payments) return false
  if (occurency) {
    return payments.filter((x) => x.occurency === occurency.date).filter((x) => x.type === 'invoice').length > 0
  } else {
    return payments.filter((x) => x.type === 'invoice').length > 0
  }
}

const missingPayment = (reservation, costAsCents) => {
  const { payments, occurency } = reservation

  if (!payments)
    return { missing: costAsCents > 0, amount: parseFloat((costAsCents / 100).toFixed(2)), haspayments: false }
  if (occurency) {
    // console.log('occurency', occurency)
    // console.log('payments', payments)
    const paidAsCents = payments
      .filter((x) => x.type !== 'refund')
      .filter((x) => {
        if (x.occurency) {
          return x.occurency === occurency.date
        } else {
          return true
        }
      })
      .reduce((a, x) => a + x.amount, 0)
    // console.log('paidAsCents', paidAsCents, 'costAsCents', costAsCents)
    const amount = parseFloat(((costAsCents - paidAsCents) / 100).toFixed(2))
    // console.log('amount', amount)
    return { missing: paidAsCents < costAsCents, amount, haspayments: true }
  } else {
    // console.log('payments', payments)
    const paidAsCents = payments.filter((x) => x.type !== 'refund').reduce((a, x) => a + x.amount, 0)
    // console.log('paidAsCents', paidAsCents, 'costAsCents', costAsCents)
    const amount = parseFloat(((costAsCents - paidAsCents) / 100).toFixed(2))
    // console.log('amount', amount)
    return { missing: paidAsCents < costAsCents, amount, haspayments: true }
  }
}

const refundedPayment = (reservation) => {
  const { payments, occurency } = reservation
  if (!payments) return { refunded: false, amount: 0 }
  let timestamp = ''
  if (occurency) {
    const _amount = payments
      .filter((x) => x.type === 'refund')
      .filter((x) => x.occurency === occurency.date)
      .reduce((a, x) => {
        timestamp = x.timestamp
        return a + x.amount
      }, 0)
    const amount = Math.abs(parseFloat((_amount / 100).toFixed(2)))
    return { refunded: amount > 0, amount, timestamp }
  } else {
    const _amount = payments
      .filter((x) => x.type === 'refund')
      .reduce((a, x) => {
        timestamp = x.timestamp
        return a + x.amount
      }, 0)
    const amount = Math.abs(parseFloat((_amount / 100).toFixed(2)))
    return { refunded: amount > 0, amount, timestamp }
  }
}

const isRecurring = (reservation) => {
  return reservation.isrecurring || reservation.parentid
}

const isCancelled = (reservation) => {
  if (reservation?.occurency?.state) {
    return reservation?.occurency?.state === 'cancelled'
  }
  return reservation.state === 'cancelled'
}

const isOnSale = (reservation) => {
  if (reservation?.occurency?.state) {
    return reservation?.occurency?.state === 'onsale'
  }
  return reservation.state === 'onsale'
}

const isPast = (reservation) => {
  const debugTime = new URLSearchParams(window.location.search).get('time')
  if (debugTime) return reservation.ordering < moment(debugTime).unix()
  return reservation.ordering < moment().unix()
}

const isUpcoming = (reservation) => {
  // const debugTime = new URLSearchParams(window.location.search).get('time')
  // if (debugTime) {
  //   if (reservation.occurency.date.startsWith('2022-10-04')) {
  //     // console.log(
  //     //   'isUpcoming',
  //     //   reservation.ordering,
  //     //   moment(debugTime).unix(),
  //     //   reservation.ordering > moment(debugTime).unix()
  //     // )
  //   }
  //   return reservation.ordering > moment(debugTime).unix()
  // }
  return reservation.ordering > moment().unix()
}

const isStarted = (reservation) => {
  const rUnix = getUnixTime(reservation, 'start')
  return rUnix > moment().unix()
}

const isStored = (reservation) => {
  return reservation.isstored || reservation?.occurency?.isstored
}

const isMoved = (reservation) => {
  if (reservation.parentid) {
    return true
  }
  return false
}

const getCost = (reservation) => {
  const { resourcegroups } = reservation
  if (!resourcegroups) console.log(reservation)
  let cost = 0
  if (resourcegroups[0]) {
    if (resourcegroups[0].hasOwnProperty('cost')) {
      cost = parseFloat(resourcegroups[0].cost)
    }
    if (resourcegroups[0].hasOwnProperty('overridecost')) {
      if (typeof resourcegroups[0].overridecost === 'string') {
        if (!isNaN(parseFloat(resourcegroups[0].overridecost))) {
          cost = parseFloat(resourcegroups[0].overridecost)
        }
      } else {
        cost = parseFloat(resourcegroups[0].overridecost)
      }
    }
  }
  return cost
}

/** reservations formatting ends **/

function EditIconButton(props) {
  return (
    <IconButton aria-label='Muokkaa' onClick={props.onClick} size='small'>
      <EditIcon fontSize='small' />
    </IconButton>
  )
}


class PersonDetails2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      reservationModalOpen: false,
      loadingModalOpen: false,
      reservations: [],
      formattedReservations: [],
      saldoloading: true,
      storageloading: true,
      membershipsloading: true,
      membershippermanent: false,
      aclsloading: true,
      acls: {},
      showAddMembershipRow: false,
      venues: [],
      activeTab: '1',
      removePersonConfirmation: false,
      saldodescription: null,
      saldotype: null,
      saldowallet: null,
      saldovenue: null,
      saldoamount: 0,
      savingsaldo: false,
      storagedescription: null,
      storagetype: null,
      storagewallet: null,
      storagevenue: null,
      storageamount: 0,
      savingstorage: false,
      tabValue: 0,
      messages: [],
      messagesloading: true,
      messageopen: [],
      messaging: { isOpen: false },
      copied: false,
      passwordResetLink: '',
      cardsloading: true,
      cards: [],
      personcDataEdit: false,
      personcDataSaved: true,
      personCdata: {},
      addToInvoiceDialog: false,
      productpilots: false
    }
  }

  componentDidMount() {
    // console.log('mount')
    // this.getReservations(this.props.person.id)
    // this.getSaldo(this.props.person.id)
    this.updateData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.person && prevProps.person) {
      if (prevProps.person.id !== this.props.person.id) {
        this.setState({ saldos: [] })
        if (this.props.person.id) {
          this.updateData()
        }
        // Promise.all([this.props.firebase.getPersonReservations(this.props.person.id).get(), this.props.firebase.getPersonSaldo(this.props.person.id), this.props.firebase.getPersonMemberships(this.props.person.id)]).then((values) => {
        //   let r = values[0]
        //   let s = values[1]
        //   let m = values[2]
        //   const raw = []
        //   if (!r.empty) {
        //     r.forEach((doc) => {
        //       // console.log(doc.id, '=>', doc.data())
        //       raw.push({ ...doc.data(), id: doc.id })
        //     })
        //   }
        //   const reservations = this.formatToTable(raw)
        //   this.setState({ loading: false, reservations, raw, saldos: s, saldoloading: false, memberships: m, membershipsloading: false })
        // })
        // this.getSaldo(this.props.person.id)
        // this.getReservations(this.props.person.id)
      }
    }
  }

  updateProductOrders = () => {
    this.setState({ invoicingloading: true })
    this.props.firebase.getPersonProductOrdersPd(this.props.person.id).then((values) => {
      this.setState({ invoicing: values.data, invoicingloading: false })
    })
  }


  updateData = () => {
    const productpilots = PRODUCT_PILOT_CUSTOMERS.includes(this.props.firebase.customerid)

    this.setState({
      loading: true,
      saldoloading: true,
      storageloading: true,
      membershipsloading: true,
      cardsloading: true,
      aclsloading: true,
      messagesloading: true,
      invoicingloading: true,
      pinloading: true,
      membershipend: moment.utc().endOf('year').format('YYYY-MM-DD'),
      membershipstart: moment.utc().format('YYYY-MM-DD'),
      productpilots
    })

    if (this.state.venues.length === 0) {
      console.time('venues')
      this.props.firebase
        .getVenues()
        .then((v) => {
          console.timeLog('venues', 'venues')
          // get pin
          let venues = []
          if (!v.empty) {
            v.docs.forEach((doc) => {
              if (doc.data().state !== 'deleted') {
                venues.push(doc.data().name.toLowerCase())
              }
            })
          }
          this.setState({ venues })
          console.timeEnd('venues')
        })
    }

    console.time('updateData')
    this.props.firebase.getPersonDetails(this.props.person.id).then((person) => {
      console.timeLog('updateData', 'person')
      const messages = person.data.messages || []
      console.log('messages', messages)
      messages.sort((a, b) => {
        if (a.timestamp._seconds > b.timestamp._seconds) {
          return -1
        }
        if (a.timestamp._seconds < b.timestamp._seconds) {
          return 1
        }
        return 0
      })
      const messageopen = []
      for (let i = 0; i < messages.length; i++) {
        messageopen.push(false)
      }

      this.setState({
        pinloading: false,
        pin: person.data.pin,
        personCdata: person.data.cdata || {},
        memberships: person.data.memberships,
        membershipsloading: false,
        membershipoptions: person.data.customermemberships,
        acls: person.data.acls,
        aclsloading: false,
        messages,
        messagesloading: false,
        messageopen,
        cards: person.data.personbenefitcards,
        benefitcards: person.data.customerbenefitcards,
        cardsloading: false,
        invoicingloading: false,
        invoicing: person.data.productorders,
        loading: false
      })
      console.timeEnd('updateData')
  })

    

    // this.props.firebase
    //   .getPersonCDataPd(this.props.person.id)
    //   // .getPersonCData(this.props.person.id)
    //   .then((cdata) => {
    //     console.timeLog('updateData','cdata')
    //     // if (cdata.exists) {
    //     //   const _p = {}
    //     //   Object.keys(cdata.data()).forEach((key) => {
    //     //     if (CDATA_FIELDS.includes(key)) {
    //     //       _p['c' + key] = cdata.data()[key]
    //     //     }
    //     //   })
    //       // console.log('_p', _p)
    //       this.setState({ personCdata: cdata.data })
    //     // }
    //     return this.props.firebase.getPersonPinPd(this.props.person.id)
    //     // return this.props.firebase.getPersonPin(this.props.person.id)
    //   })
    //   .then((pindata) => {
    //     console.timeLog('updateData','pin')
    //     // get memberships
    //     let pin = null
    //     if (!pindata?.data?.error) {
    //       pin = pindata.data
    //     }
    //     this.setState({ pin, pinloading: false })
    //     // return Promise.all([
    //     //   this.props.firebase.getPersonMemberships(this.props.person.id),
    //     //   this.props.firebase.getMemberships(),
    //     // ])
    //     return Promise.all([
    //       this.props.firebase.getPersonMembershipsPd(this.props.person.id),
    //       this.props.firebase.getCustomerMembershipsPd(),
    //     ])
    //   })
    //   .then(([m, allm]) => {
    //     console.timeLog('updateData','memberships')
    //     // get acls
    //     const membershipOptions = allm.data
    //     // const membershipOptions = []
    //     // if (!allm.empty) {
    //     //   // eslint-disable-next-line
    //     //   allm.docs.map((doc) => {
    //     //     if (doc.data().state !== 'deleted') {
    //     //       membershipOptions.push({ ...doc.data(), id: doc.id })
    //     //     }
    //     //   })
    //     // }
    //     this.setState({
    //       memberships: m.data,
    //       membershipsloading: false,
    //       membershipoptions: membershipOptions,
    //     })
    //     // return Promise.all([this.props.firebase.getAcls(this.props.person.id)])
    //     return Promise.all([this.props.firebase.getAclsPd(this.props.person.id)])
    //   })
    //   .then(([acls]) => {
    //     console.timeLog('updateData','acls')
    //     // get saldos
    //     let aclsdata = {}
    //     if (acls.data.acl) {
    //       aclsdata = acls.data.acl
    //     }
    //     this.setState({ acls: aclsdata, aclsloading: false })
    //   //   return Promise.all([
    //   //     this.props.firebase.getPersonSaldo(this.props.person.id),
    //   //     this.props.firebase.getPersonStorage(this.props.person.id),
    //   //   ])
    //   // })
    //   // .then(([s, st]) => {
    //   //   console.timeLog('updateData','saldos')
    //   //   // get messages
    //   //   this.setState({ saldos: s.data, storages: st.data, saldoloading: false, storageloading: false, loading: false })
    //     // return this.props.firebase.getPersonMessages(this.props.person.id)
    //     return this.props.firebase.getPersonMessagesPd(this.props.person.id)
    //   })
    //   .then((_messages) => {
    //     console.timeLog('updateData', 'messages')
    //     const messages = _messages.data
    //     messages.sort((a, b) => {
    //       if (a.timestamp.seconds > b.timestamp.seconds) {
    //         return -1
    //       }
    //       if (a.timestamp.seconds < b.timestamp.seconds) {
    //         return 1
    //       }
    //       return 0
    //     })
    //     const messageopen = []
    //     for (let i = 0; i < messages.length; i++) {
    //       messageopen.push(false)
    //     }
    //     this.setState({ messages, messagesloading: false, messageopen })
    //     // return Promise.all([
    //     //   this.props.firebase.getPersonBenefitCards(this.props.person.id),
    //     //   this.props.firebase.getBenefitCards(),
    //     // ])
    //     return Promise.all([
    //       this.props.firebase.getPersonBenefitCardsPd(this.props.person.id),
    //       this.props.firebase.getCustomerBenefitCardsPd(),
    //     ])
    //   })
    //   .then(([personcards, benefitcards]) => {
    //     console.timeLog('updateData','benefitcards')
    //     // let _d = benefitcards.docs.map((sh) => {
    //     //   return { ...sh.data(), id: sh.id }
    //     // })
    //     let _d = benefitcards.data
    //     console.log('personcards', personcards)
    //     this.setState({ cards: personcards.data, benefitcards: _d, cardsloading: false })
    //     console.timeEnd('updateData')
    //   })

    // Promise.all([
    //   // this.props.firebase.getPersonReservations(this.props.person.id).get(),
    //   this.props.firebase.getPersonSaldo(this.props.person.id),
    //   this.props.firebase.getVenues(),
    //   this.props.firebase.getPersonPin(this.props.person.id),
    //   this.props.firebase.getPersonStorage(this.props.person.id),
    // ]).then((values) => {
    //   // let r = values[0]
    //   let s = values[0]
    //   let m = values[1]
    //   let allm = values[2]
    //   let v = values[3]
    //   let pin = null
    //   if (!values[4]?.data?.error) {
    //     pin = values[4].data
    //   }
    //   let st = values[5]
    //   // const raw = []
    //   // if (!r.empty) {
    //   //   r.forEach((doc) => {
    //   //     // console.log(doc.id, '=>', doc.data())
    //   //     raw.push({ ...doc.data(), id: doc.id })
    //   //   })
    //   // }
    //   // const reservations = this.formatToTable(raw)
    //   const membershipOptions = []
    //   if (!allm.empty) {
    //     // eslint-disable-next-line
    //     allm.docs.map((doc) => {
    //       if (doc.data().state !== 'deleted') {
    //         membershipOptions.push({ ...doc.data(), id: doc.id })
    //       }
    //     })
    //   }
    //   let venues = []
    //   if (!v.empty) {
    //     v.docs.forEach((doc) => {
    //       if (doc.data().state !== 'deleted') {
    //         venues.push(doc.data().name.toLowerCase())
    //       }
    //     })
    //   }
    //   this.setState({
    //     loading: false,
    //     // reservations,
    //     // raw,
    //     saldos: s.data,
    //     saldoloading: false,
    //     storageloading: false,
    //     memberships: m.data,
    //     membershipsloading: false,
    //     membershipoptions: membershipOptions,
    //     venues,
    //     pin,
    //     storages: st.data,
    //   })
    // })

    Promise.all([
      this.props.firebase.getPersonSaldo(this.props.person.id),
      this.props.firebase.getPersonStorage(this.props.person.id),
    ])
      .then(([s, st]) => {
      this.setState({ saldos: s.data, storages: st.data, saldoloading: false, storageloading: false, loading: false })
    })


    console.time('reservations')

    this.props.firebase
      .getPersonReservations(this.props.person.id)
      .get()
      .then((r) => {
        console.timeLog('reservations','request')
        const raw = []
        if (!r.empty) {
          r.forEach((doc) => {
            raw.push({ ...doc.data(), id: doc.id })
          })
        }
        const reservations = this.formatToTable(raw)
        const _reservations = this.formatReservations(raw)
        this.setState({
          reservations,
          raw,
          formattedReservations: _reservations,
        })
        console.timeEnd('reservations')
      })
  }

  setAcl = (data) => {
    this.setState({ aclsloading: true })
    const update = { userid: this.props.person.id, acl: data }
    this.props.firebase.setAcl(update).then((response) => {
      // console.log('response', response)
      this.setState({ aclsloading: false, acls: { acl: response.data.acl } })
    })
  }

  getStatus = (reservation) => {
    const _cost = getCost(reservation)
    const _missingPayment = missingPayment(reservation, _cost * 100)
    // console.log('_missingPayment', _missingPayment, 'cost', _cost)
    const _refundedPayment = refundedPayment(reservation, _cost * 100)
    const _payedPayment = { payed: _cost - _missingPayment.amount > _cost, amount: _cost - _missingPayment.amount }
    const _isPast = isPast(reservation)
    const _isPaid = _missingPayment.missing === false
    const _isCancelled = isCancelled(reservation)
    const _isOnSale = isOnSale(reservation)
    const _isRecurring = isRecurring(reservation)
    const _isStored = isStored(reservation)
    const _isUpcoming = isUpcoming(reservation)
    const _isInvoiced = isInvoiced(reservation)
    const _isStarted = isStarted(reservation)
    const _isCancellable = _isStarted && !_isCancelled && !_isOnSale && !_isRecurring && !_isStored && !_isInvoiced
    const _isSellable = _isStarted && !_isCancelled && !_isOnSale && !_isStored
    const _isPayable = !_isPaid && !_isInvoiced && !(_isCancelled && !_isStored)
    const _isStorable = _isStarted && !_isCancelled && _isRecurring && !_isStored
    const _showAccessCode = _isUpcoming && !_isCancelled && !_isOnSale
    const _isSplittable = !_isCancelled && !_isOnSale && _isRecurring && !_isPaid
    const _isRefunded = _refundedPayment.refunded === true
    const _isReceiptEnabled = !_isInvoiced && _isPaid && !_isRefunded && _cost > 0
    const _isMoved = isMoved(reservation)

    return {
      cost: _cost,
      isCancellable: _isCancellable,
      isCancelled: _isCancelled,
      isInvoiced: _isInvoiced,
      isMoved: _isMoved,
      isOnSale: _isOnSale,
      isPaid: _isPaid,
      isPast: _isPast,
      isPayable: _isPayable,
      isReceiptEnabled: _isReceiptEnabled,
      isRecurring: _isRecurring,
      isRefunded: _isRefunded,
      isSellable: _isSellable,
      isSplittable: _isSplittable,
      isStarted: _isStarted,
      isStorable: _isStorable,
      isStored: _isStored,
      isUpcoming: _isUpcoming,
      missingPayment: _missingPayment,
      payedPayment: _payedPayment,
      refundedPayment: _refundedPayment,
      showAccessCode: _showAccessCode,
    }
  }

  formatReservations = (reservations) => {
    // let _m = Object.keys(firestore.data['ownReservations'])
    //   .map((key) => {
    //     return {
    //       ...firestore.data['ownReservations'][key],
    //       id: key,
    //       selectkey: key,
    //     }
    //   })
    //   .filter((r) => hasResource(r) && r.locationid === appconfig.accountkey && r.state !== 'user_cancelled')
    // .filter((r) => {
    //   if (r.isrecurring) {
    //     return r.state !== 'cancelled' // all cancelled !if past not shoud be invisible
    //   } else {
    //     return true
    //   }
    // })
    let _m = reservations
      .filter((r) => r.state !== 'user_cancelled')
      .map((r) => {
        if (r.isrecurring) {
          return r.recurrencyDates
            .map((rec) => {
              let _r = JSON.parse(JSON.stringify(r))
              let _sKey = _r.selectkey + '-'
              if (typeof rec === 'object') {
                _r.occurency = { date: rec.date, state: rec.state, isstored: rec.isstored }
                _r.state = rec.state
                _sKey += rec.date
                if (rec.state === 'moved') return null
              } else {
                _r.occurency = { date: rec }
                _sKey += rec
              }
              _r.selectkey = _sKey // _r.selectkey + '-' + rec
              const timeOfDayHelper = moment.utc(_r.resourcegroups[0].start)
              const ordering = moment.utc(_r.occurency.date)
              ordering.hour(timeOfDayHelper.hour()).minute(timeOfDayHelper.minute())
              if (moment().utcOffset() > 0) {
                ordering.subtract(moment().utcOffset(), 'minute')
              } else {
                ordering.add(moment().utcOffset(), 'minute')
              }
              _r.ordering = ordering.unix()
              _r.starttime = ordering
              // _r.occurency = { date: moment.utc(r.resourcegroups[0].start).format('YYYY-MM-DD') }
              _r.start = moment.utc(r.resourcegroups?.[0]?.start).format('HH:mm')
              _r.end = moment.utc(r.resourcegroups?.[0]?.end).format('HH:mm')
              _r.court = r.resourcegroups?.[0]?.resources?.[0]?.displayName
              _r.resourcetype = r.resourcegroups?.[0]?.resources?.[0]?.category
              _r.reservationtype = r.isrecurring ? 'Vakio' : r.parentid ? 'Siirretty vakio' : 'Irto'
              return _r
            })
            .filter((x) => x)
        } else {
          const ordering = moment.utc(r.resourcegroups[0].end)
          if (moment().utcOffset() > 0) {
            ordering.subtract(moment().utcOffset(), 'minute')
          } else {
            ordering.add(moment().utcOffset(), 'minute')
          }
          r.ordering = ordering.unix()
          r.starttime = ordering
          r.occurency = { date: moment.utc(r.resourcegroups[0].start).format('YYYY-MM-DD') }
          r.start = moment.utc(r.resourcegroups?.[0]?.start).format('HH:mm')
          r.end = moment.utc(r.resourcegroups?.[0]?.end).format('HH:mm')
          r.resourcetype = r.resourcegroups?.[0]?.resources?.[0]?.category
          r.reservationtype = r.isrecurring ? 'Vakio' : r.parentid ? 'Siirretty vakio' : 'Irto'
          r.court = r.resourcegroups?.[0]?.resources?.[0]?.displayName
          return r
        }
      })
      .flat(3)
      .map((r) => {
        r.vs = this.getStatus(r)
        r.id = r.occurency.date + '---' + r.id
        r.cost = r.vs.cost
        r.paid = r.vs.isPaid ? 'Kyllä' : 'Ei'
        r.invoiced = r.vs.isInvoiced ? 'Kyllä' : 'Ei'
        r.stateName =
          r.state === 'cancelled'
            ? 'Peruttu'
            : r.state === 'onsale'
            ? 'Myynnissä'
            : r.state === 'stored'
            ? 'Varastoitu'
            : 'Varattu'
        return r
      })

    _m.sort(function (a, b) {
      if (a.ordering < b.ordering) {
        return -1
      } else if (a.ordering > b.ordering) {
        return 1
      } else {
        return 0
      }
    })

    return _m
  }

  /** resevations format ends */

  formatToTable = (reservations) => {
    return reservations.map((r) => {
      const ret = {
        id: r.id,
        recurrencyDates: r.recurrencyDates || [],
        cost: r.cost,
        payments: r.payments || [],
        reservationtype: r.isrecurring ? 'Vakio' : 'Irto',
        state: r.state,
      }
      ret.day = moment.utc(r.resourcegroups?.[0]?.start).format('dd DD.MM.YY')
      ret.start = moment.utc(r.resourcegroups?.[0]?.start).format('HH:mm')
      ret.end = moment.utc(r.resourcegroups?.[0]?.end).format('HH:mm')
      ret.court = r.resourcegroups?.[0]?.resources?.[0]?.displayName
      ret.resourcetype = r.resourcegroups?.[0]?.resources?.[0]?.category
      ret.starttime = r.resourcegroups?.[0]?.start
      return ret
    })
  }

  getReservations = async (id) => {
    // console.log('get reservations', id)
    const raw = []
    const reservationsSnapshot = await this.props.firebase.getPersonReservations(this.props.person.id).get()
    if (!reservationsSnapshot.empty) {
      reservationsSnapshot.forEach((doc) => {
        // console.log(doc.id, '=>', doc.data())
        raw.push({ ...doc.data(), id: doc.id })
      })
    }
    const reservations = this.formatToTable(raw)
    this.setState({ loading: false, reservations, raw })
  }

  getSaldo = async (id) => {
    this.setState({ saldoloading: true })
    const saldos = await this.props.firebase.getPersonSaldo(this.props.person.id)
    this.setState({ saldos: saldos.data, saldoloading: false })
  }

  getStorage = async (id) => {
    this.setState({ storageloading: true })
    const storages = await this.props.firebase.getPersonStorage(this.props.person.id)
    this.setState({ storages: storages.data, storageloading: false })
  }

  getPersonCdata = async () => {
    this.setState({ personcDataloading: true })
    const cdata = await this.props.firebase.getPersonCData(this.props.person.id)
    if (cdata.exists) {
      const _p = {}
      Object.keys(cdata.data()).forEach((key) => {
        if (CDATA_FIELDS.includes(key)) {
          _p['c' + key] = cdata.data()[key]
        }
      })
      this.setState({ personCdata: _p })
    }
    this.setState({ personcDataloading: false })
  }

  getMemberships = async (id) => {
    this.setState({ membershipsloading: true })
    const m = await this.props.firebase.getPersonMemberships(this.props.person.id)
    this.setState({ memberships: m.data, membershipsloading: false })
  }

  getBenefitCards = async () => {
    this.setState({ cardsloading: true })
    const b = await this.props.firebase.getPersonBenefitCards(this.props.person.id)
    this.setState({ cards: b.data, cardsloading: false })
  }

  toggleReservationModal = () => {
    this.setState({ reservationModalOpen: !this.state.reservationModalOpen })
  }

  toggleLoadingModal = () => {
    this.setState({ loadingModalOpen: !this.state.loadingModalOpen })
  }

  handleMembershipSelection = (e) => {
    const membershipselection = e.target.value
    this.setState({ membershipselection })
  }

  handleMembershipStart = (e) => {
    const membershipstart = e.target.value
    this.setState({ membershipstart })
  }

  handleMembershipEnd = (e) => {
    const membershipend = e.target.value
    this.setState({ membershipend })
  }

  handleMembershipPermanent = (e) => {
    const membershippermanent = e.target.checked
    this.setState({ membershippermanent })
  }

  handleMembershipNote = (e) => {
    const membershipnote = e.target.value
    this.setState({ membershipnote })
  }

  handleSaldoAmount = (e) => {
    const saldoamount = e.target.value
    this.setState({ saldoamount })
  }

  handleSaldoVenue = (e) => {
    const saldovenue = e.target.value
    this.setState({ saldovenue })
  }

  handleSaldoType = (e) => {
    const saldotype = e.target.value
    this.setState({ saldotype })
  }

  handleSaldoWallet = (e) => {
    const saldowallet = e.target.value
    this.setState({ saldowallet })
  }

  handleSaldoDescription = (e) => {
    const saldodescription = e.target.value
    this.setState({ saldodescription })
  }

  handleStorageAmount = (e) => {
    const storageamount = e.target.value
    this.setState({ storageamount })
  }

  handleStorageVenue = (e) => {
    const storagevenue = e.target.value
    this.setState({ storagevenue })
  }

  handleStorageType = (e) => {
    const storagetype = e.target.value
    this.setState({ storagetype })
  }

  handleStorageWallet = (e) => {
    const storagewallet = e.target.value
    this.setState({ storagewallet })
  }

  handleStorageDescription = (e) => {
    const storagedescription = e.target.value
    this.setState({ storagedescription })
  }

  handleSelectEvent = async (reservationid, occurency) => {
    const reid = reservationid.split('---').pop()
    if (this.props.onReservationSelect) this.props.onReservationSelect(reid, occurency)
  }

  toggleRemoveDialog = () => {
    this.setState({
      removePersonConfirmation: !this.state.removePersonConfirmation,
    })
  }

  toggleAddToInvoiceDialog = () => {
    this.setState({
      addToInvoiceDialog: !this.state.addToInvoiceDialog,
    })
  }

  renderPersonMemberships = () => {
    const response = []
    if (this.state.membershipsloading) {
      response.push(
        <Row key={'loadingmemberships'} style={{ marginTop: 20 }}>
          <Col>Jäsenyydet</Col>
          <Col>
            <Spinner size='sm' /> Loading
          </Col>
        </Row>
      )
    } else if (this.state.memberships && this.state.memberships.length > 0) {
      response.push(<Row key={'loadingmemberships'} style={{ marginTop: 20 }}></Row>)
      for (let k = 0; k < this.state.memberships.length; k++) {
        const membership = this.state.memberships[k]
        const m = this.state.membershipoptions.find((x) => x.id === membership.membershipid)
        if (!m) continue
        response.push(
          <Row key={Math.random()}>
            <Col>{m.name}</Col>
            <Col>
              <Row style={{ marginBottom: 2 }}>
                <Col>{membership.permanent ? 'Pysyvä' : membership.start + ' - ' + membership.end}</Col>
                <Col>
                  {membership.permanent || moment.utc(membership.end).isAfter(moment.utc()) ? (
                    <>
                      {' '}
                      <Button
                        color='primary'
                        size={'sm'}
                        style={{ margin: 0 }}
                        onClick={() => this.onTerminateMembership(membership.id)}
                      >
                        Päätä jäsenyys
                      </Button>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Button color='danger' size={'sm'} style={{ margin: 0 }}>
                        Päättynyt
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )
      }
    } else {
      response.push(
        <Row key={'memberships'} style={{ marginTop: 20 }}>
          <Col>Jäsenyydet</Col>
          <Col>Ei jäsenyyksiä</Col>
        </Row>
      )
    }
    return response
  }

  renderSaldoData = () => {
    const response = []
    if (this.state.saldoloading) {
      response.push(
        <Row key={'loadingsaldo'} style={{ marginTop: 20 }}>
          <Col>Saldo</Col>
          <Col>
            <Spinner size='sm' /> Loading
          </Col>
        </Row>
      )
    } else if (this.state.saldos && this.state.saldos.length > 0) {
      response.push(<Row key={'loadingsaldo'} style={{ marginTop: 20 }}></Row>)
      for (let i = 0; i < this.state.saldos.length; i++) {
        const walletTypes = Object.keys(this.state.saldos[i]).filter((x) => x !== 'id')
        for (let k = 0; k < walletTypes.length; k++) {
          const sum = this.state.saldos[i][walletTypes[k]].reduce((acc, curr) => {
            acc = acc + curr.amount
            return acc
          }, 0)
          const walletTitle =
            walletTypes[k] === 'cash' ? 'Kassasaldo' : walletTypes[k] === 'sportmoney' ? 'Liikuntaraha' : walletTypes[k]
          const walletVenue =
            this.state.saldos[i].id !== 'default'
              ? this.state.saldos[i].id.charAt(0).toUpperCase() + this.state.saldos[i].id.slice(1) + ' - '
              : ''

          response.push(
            <Row
              key={walletVenue + '-' + walletTypes[k] + '-' + k}
              onClick={() => this.showSaldoTransaction(walletTypes[k], this.state.saldos[i].id)}
              style={{ cursor: 'pointer' }}
            >
              <Col xs={4} md={2}>
                {this.state.selectedsaldotype &&
                this.state.selectedsaldotype.venue === this.state.saldos[i].id &&
                this.state.selectedsaldotype.category === walletTypes[k] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}{' '}
                {walletVenue} {walletTitle}
              </Col>
              <Col>
                {(sum / 100).toFixed(2).replace('.', ',')} €
                {/* <Row style={{ marginBottom: 2 }}>
                  <Col>{sum / 100} € </Col>
                  <Col>
                    <Button
                      color='link'
                      size={'sm'}
                      style={{ margin: 0 }}
                      onClick={() => this.showSaldoTransaction(walletTypes[k], this.state.saldos[i].id)}
                    >
                      Näytä tapahtumat
                    </Button>
                  </Col>
                </Row> */}
              </Col>
            </Row>
          )
        }
      }
    } else {
      response.push(
        <Row key={'loadingsaldo'} style={{ marginTop: 20 }}>
          <Col>Saldo</Col>
          <Col>Ei saldoa</Col>
        </Row>
      )
    }

    return response
  }

  renderStorageData = () => {
    const response = []
    if (this.state.storageloading) {
      response.push(
        <Row key={'storageloading'} style={{ marginTop: 20 }}>
          <Col>Saldo</Col>
          <Col>
            <Spinner size='sm' /> Loading
          </Col>
        </Row>
      )
    } else if (this.state.storages && this.state.storages.length > 0) {
      response.push(<Row key={'storageloading'} style={{ marginTop: 20 }}></Row>)
      for (let i = 0; i < this.state.storages.length; i++) {
        const walletTypes = Object.keys(this.state.storages[i]).filter((x) => x !== 'id')
        for (let k = 0; k < walletTypes.length; k++) {
          const sum = this.state.storages[i][walletTypes[k]].reduce((acc, curr) => {
            acc = acc + curr.amount
            return acc
          }, 0)
          const walletTitle =
            walletTypes[k] === 'cash' ? 'Pankki' : walletTypes[k] === 'minutes' ? 'Varasto' : walletTypes[k]
          const walletVenue =
            this.state.storages[i].id !== 'default'
              ? this.state.storages[i].id.charAt(0).toUpperCase() + this.state.storages[i].id.slice(1) + ' - '
              : ''

          response.push(
            <Row
              key={walletVenue + '-' + walletTypes[k] + '-' + k}
              onClick={() => this.showStorageTransaction(walletTypes[k], this.state.storages[i].id)}
              style={{ cursor: 'pointer' }}
            >
              <Col xs={4} md={2}>
                {this.state.selectedstoragetype &&
                this.state.selectedstoragetype.venue === this.state.storages[i].id &&
                this.state.selectedstoragetype.category === walletTypes[k] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}{' '}
                {walletVenue} {walletTitle}
              </Col>
              <Col>
                {walletTypes[k] === 'minutes' ? sum + ' mins' : (sum / 100).toFixed(2).replace('.', ',') + ' €'}
              </Col>
            </Row>

            // <Row key={walletVenue + '-' + walletTypes[k] + '-' + k}>
            //   <Col>
            //     {walletVenue} {walletTitle}
            //   </Col>
            //   <Col>
            //     <Row style={{ marginBottom: 2 }}>
            //       <Col>{walletTypes[k] === 'minutes' ? sum + ' mins' : sum / 100 + ' €'}</Col>
            //       <Col>
            //         <Button
            //           color='link'
            //           size={'sm'}
            //           style={{ margin: 0 }}
            //           onClick={() => this.showStorageTransaction(walletTypes[k], this.state.storages[i].id)}
            //         >
            //           Näytä tapahtumat
            //         </Button>
            //       </Col>
            //     </Row>
            //   </Col>
            // </Row>
          )
        }
      }
    } else {
      response.push(
        <Row key={'storageloading'} style={{ marginTop: 20 }}>
          <Col>Pankki/Varasto</Col>
          <Col>Ei tapahtumia</Col>
        </Row>
      )
    }

    return response
  }

  showSaldoTransaction = (category, venue) => {
    if (
      this.state.selectedsaldotype &&
      this.state.selectedsaldotype.category === category &&
      this.state.selectedsaldotype.venue === venue
    ) {
      this.setState({ selectedsaldotype: null })
    } else {
      const selectedsaldotype = { venue, category }
      this.setState({ selectedsaldotype })
    }
  }

  showStorageTransaction = (category, venue) => {
    if (
      this.state.selectedstoragetype &&
      this.state.selectedstoragetype.category === category &&
      this.state.selectedstoragetype.venue === venue
    ) {
      this.setState({ selectedstoragetype: null })
    } else {
      const selectedstoragetype = { venue, category }
      // console.log('selectedstoragetype', selectedstoragetype)
      this.setState({ selectedstoragetype })
    }
  }

  renderSaldoTransactions = () => {
    if (!this.state.selectedsaldotype) {
      return <></>
    }
    const _selectedTransactions = this.state.saldos
      .filter((venuesaldos) => venuesaldos.id === this.state.selectedsaldotype.venue)
      .map((s) => s[this.state.selectedsaldotype.category])
      .flat(1).reverse()
    // do some formatting for transactions
    // eslint-disable-next-line array-callback-return
    const selectedTransactions = _selectedTransactions.map((t, i) => {
      if (t.purchasetype === 'reservation' || t.purchasetype === 'activity') {
        if (t.type === 'refund') {
          return (
            <Row
              key={'rtrans-' + i}
              style={{ marginLeft: 10, marginRight: 10, paddingTop: 3, paddingBottom: 3 }}
              className='border-bottom'
            >
              <Col xs={'2'} sm={'2'} md={'2'} lg={'2'}>
                {t.description.startsWith('Peruttu') ? 'Peruutus' : 'Myynti'}
              </Col>
              <Col xs={'5'} sm={'5'} md={'5'} lg={'5'}>
                {t.description}
              </Col>
              <Col xs={'2'} sm={'2'} md={'2'} lg={'2'}>
                {t.amount / 100} €
              </Col>
              <Col xs={'3'} sm={'3'} md={'3'} lg={'3'}>
                {t.timestamp}
              </Col>
            </Row>
          )
        } else if (t.type === 'purchase') {
          return (
            <Row
              key={'rtrans-' + i}
              style={{ marginLeft: 10, marginRight: 10, paddingTop: 3, paddingBottom: 3 }}
              className='border-bottom'
            >
              <Col xs={'2'} sm={'2'} md={'2'} lg={'2'}>
                Varaus
              </Col>
              <Col xs={'5'} sm={'5'} md={'5'} lg={'5'}>
                {t.description}
              </Col>
              <Col xs={'2'} sm={'2'} md={'2'} lg={'2'}>
                {t.amount / 100} €
              </Col>
              <Col xs={'3'} sm={'3'} md={'3'} lg={'3'}>
                {t.timestamp}
              </Col>
            </Row>
          )
        }
      } else {
        let title = ''
        if (t.type === 'compensation') {
          title = 'Hyvitys'
        } else if (t.type === 'transfer') {
          title = 'Siirto'
        } else if (t.type === 'deposit') {
          title = 'Talletus'
        } else {
          return <></>
        }
        return (
          <Row
            key={'rtrans-' + i}
            style={{ marginLeft: 10, marginRight: 10, minHeight: 40, paddingTop: 3, paddingBottom: 3 }}
            className='border-bottom'
          >
            <Col xs={'2'} sm={'2'} md={'2'} lg={'2'}>
              {title}
            </Col>
            <Col xs={'5'} sm={'5'} md={'5'} lg={'5'}>
              {t.description}
            </Col>
            <Col xs={'2'} sm={'2'} md={'2'} lg={'2'}>
              {t.amount / 100} €
            </Col>
            <Col xs={'3'} sm={'3'} md={'3'} lg={'3'}>
              {t.timestamp}
            </Col>
          </Row>
        )
      }
    })
    return selectedTransactions
  }

  renderStorageTransactions = () => {
    if (!this.state.selectedstoragetype) {
      return <></>
    }
    const _selectedTransactions = this.state.storages
      .filter((venuesaldos) => venuesaldos.id === this.state.selectedstoragetype.venue)
      .map((s) => s[this.state.selectedstoragetype.category])
      .flat(1).reverse()
    // do some formatting for transactions
    // eslint-disable-next-line array-callback-return
    const selectedTransactions = _selectedTransactions.map((t, i) => {
      if (t.purchasetype === 'reservation') {
        if (t.type === 'refund') {
          return (
            <Row
              key={'rtrans-' + i}
              style={{ marginLeft: 10, marginRight: 10, paddingTop: 3, paddingBottom: 3 }}
              className='border-bottom'
            >
              <Col xs={'2'} sm={'2'} md={'2'} lg={'2'}>
                {t.description.startsWith('Peruttu') ? 'Peruutus' : 'Tallennus'}
              </Col>
              <Col xs={'5'} sm={'5'} md={'5'} lg={'5'}>
                {t.description}
              </Col>
              <Col xs={'1'} sm={'1'} md={'1'} lg={'1'}>
                {this.state.selectedstoragetype.category === 'minutes' ? t.amount + ' min' : t.amount / 100 + ' €'}
              </Col>
              <Col xs={'1'} sm={'1'} md={'1'} lg={'1'}>
                {t.category}
              </Col>
              <Col xs={'1'} sm={'1'} md={'1'} lg={'1'}>
                {t.timestamp}
              </Col>
              <Col xs={'1'} sm={'1'} md={'1'} lg={'1'}>
                {t.validuntil}
              </Col>
            </Row>
          )
        } else if (t.type === 'purchase') {
          return (
            <Row
              key={'rtrans-' + i}
              style={{ marginLeft: 10, marginRight: 10, paddingTop: 3, paddingBottom: 3 }}
              className='border-bottom'
            >
              <Col xs={'2'} sm={'2'} md={'2'} lg={'2'}>
                Varaus
              </Col>
              <Col xs={'5'} sm={'5'} md={'5'} lg={'5'}>
                {t.description}
              </Col>
              <Col xs={'2'} sm={'2'} md={'2'} lg={'2'}>
                {this.state.selectedstoragetype.category === 'minutes' ? t.amount + ' min' : t.amount / 100 + ' €'}
              </Col>
              <Col xs={'3'} sm={'3'} md={'3'} lg={'3'}>
                {t.timestamp}
              </Col>
            </Row>
          )
        }
      } else {
        let title = ''
        if (t.type === 'compensation') {
          title = 'Hyvitys'
        } else if (t.type === 'transfer') {
          title = 'Siirto'
        } else if (t.type === 'deposit') {
          title = 'Talletus'
        } else {
          return <></>
        }
        return (
          <Row
            key={'rtrans-' + i}
            style={{ marginLeft: 10, marginRight: 10, minHeight: 40, paddingTop: 3, paddingBottom: 3 }}
            className='border-bottom'
          >
            <Col xs={'2'} sm={'2'} md={'2'} lg={'2'}>
              {title}
            </Col>
            <Col xs={'5'} sm={'5'} md={'5'} lg={'5'}>
              {t.description}
            </Col>
            <Col xs={'2'} sm={'2'} md={'2'} lg={'2'}>
              {t.amount / 100} €
            </Col>
            <Col xs={'3'} sm={'3'} md={'3'} lg={'3'}>
              {t.timestamp}
            </Col>
          </Row>
        )
      }
    })
    return selectedTransactions
  }

  toggleAddSaldo = () => {
    this.setState({ showAddSaldoRow: !this.state.showAddSaldoRow })
  }

  toggleAddStorage = () => {
    this.setState({ showAddStorageRow: !this.state.showAddStorageRow })
  }

  toggleAddMembership = () => {
    this.setState({ showAddMembershipRow: !this.state.showAddMembershipRow })
  }

  handleSaldoSaved = () => {
    this.getSaldo()
    this.toggleAddSaldo()
  }

  handlePersonCdataSaved = () => {
    this.getPersonCdata()
    this.togglePersonCdataEdit()
  }

  handleStorageSaved = () => {
    this.getStorage()
    this.toggleAddStorage()
  }

  handleMembershipSaved = () => {
    this.getMemberships()
    this.toggleAddMembership()
  }

  handleBenefitcardSaved = () => {
    this.getBenefitCards()
  }

  handleMembershipTerminated = () => {
    this.getMemberships()
  }

  handleCPersonDataChange = (field, value) => {
    const person = { ...this.state.personCdata }
    person[field] = value
    this.setState({ personCdata: person, personcDataSaved: false })
  }

  onSaveSaldoPayment = async () => {
    this.setState({ savingsaldo: true })
    const amount = parseInt((parseFloat(this.state.saldoamount.replace(',', '.')) * 100).toFixed(0))
    const description = this.state.saldodescription
    const type = this.state.saldotype
    const wallet = this.state.saldowallet
    const venue = this.state.saldovenue
    const userid = this.props.person.id
    /*const result = */ await this.props.firebase.addSaldoTransaction(userid, type, amount, description, wallet, venue)
    this.setState(
      {
        saldodescription: null,
        saldotype: null,
        saldowallet: null,
        saldovenue: null,
        saldoamount: 0,
        savingsaldo: false,
      },
      this.handleSaldoSaved
    )
  }

  onSaveStoragePayment = async () => {
    this.setState({ savingstorage: true })
    const amount = parseInt((parseFloat(this.state.storageamount.replace(',', '.')) * 100).toFixed(0))
    const description = this.state.storagedescription
    const type = this.state.storagetype
    const wallet = this.state.storagewallet
    const venue = this.state.storagevenue
    const userid = this.props.person.id
    /*const result = */ await this.props.firebase.addStorageTransaction(
      userid,
      type,
      amount,
      description,
      wallet,
      venue
    )
    this.setState(
      {
        storagedescription: null,
        storagetype: null,
        storagewallet: null,
        storagevenue: null,
        storageamount: 0,
        savingstorage: false,
      },
      this.handleStorageSaved
    )
  }

  onSaveMembership = async () => {
    this.setState({ savingmembership: true })
    const membershipid = this.state.membershipselection
    const note = this.state.membershipnote
    const start = this.state.membershipstart
    const end = this.state.membershipend
    const permanent = this.state.membershippermanent
    const userid = this.props.person.id
    await this.props.firebase.addNewMembership(userid, membershipid, start, end, note, permanent)
    this.setState(
      {
        membershipnote: null,
        membershipend: moment.utc().endOf('year').format('YYYY-MM-DD'),
        membershipstart: moment.utc().format('YYYY-MM-DD'),
        membershippermanent: false,
        membershipselection: '',
        savingmembership: false,
      },
      this.handleMembershipSaved
    )
  }

  onTerminateMembership = async (id) => {
    this.setState({ savingmembership: true })
    const end = moment.utc().subtract(1, 'day').format('YYYY-MM-DD')
    const permanent = false
    const userid = this.props.person.id
    await this.props.firebase.terminateMembership(userid, id, end, permanent)
    this.setState({ savingmembership: false }, this.handleMembershipTerminated)
  }

  openRemoveDialog = (id) => {
    this.toggleRemoveDialog()
    this.setState({ removablePersonId: id })
  }

  togglePersonCdataEdit = () => {
    if (this.state.personcDataEdit) {
      this.setState({
        personcDataSaved: true, personcDataEdit: !this.state.personcDataEdit, personCdata: {}
      })
    } else {
      this.setState({ personcDataEdit: !this.state.personcDataEdit })
    }
  }

  savePersonCdata = async () => {
    const { id } = this.props.person
    const { cfirstname, clastname, cstreetaddress, ccity, czip } = this.state.personCdata
    const _person = {
      id,
    }
    if (cfirstname) _person.firstname = cfirstname
    if (clastname) _person.lastname = clastname
    if (cstreetaddress) _person.streetaddress = cstreetaddress
    if (ccity) _person.city = ccity
    if (czip) _person.zip = czip
    await this.props.firebase.updatePerson(_person)
    this.handlePersonCdataSaved()
  }

  renderPersonData = () => {
    const { id, phone, firstname, lastname, email, streetaddress, city, zip } = this.props.person
    const { cfirstname, clastname, cstreetaddress, ccity, czip } = this.state.personCdata
    // console.log('personCdata', this.state.personCdata)
    return (
      <>
        <Row id={id}>
          <Col xs={3}></Col>
          <Col xs={3} className='pt-1'><b>Asiakkaan tiedot</b></Col>
          <Col xs={6}><b>Laskutuksen tiedot (jos eri)</b><EditIconButton onClick={this.togglePersonCdataEdit} /></Col>
        </Row>
        <Row id={id}>
          <Col xs={3}>Etunimi</Col>
          <Col xs={3}>{firstname}</Col>
          {this.state.personcDataEdit ? (
            <Col xs={3}>
              <TextField
                id='cfirstname'
                label='Etunimi'
                type='text'
                size='small'
                value={cfirstname || ''}
                onChange={(e) => this.handleCPersonDataChange('cfirstname', e.target.value)}
              />
            </Col>
          ) : cfirstname ? (
            <Col xs={3}>{cfirstname}</Col>) : null}
        </Row>
        <Row>
          <Col xs={3}>Sukunimi</Col>
          <Col xs={3}>{lastname}</Col>
          {this.state.personcDataEdit ? (
            <Col xs={3}>
              <TextField
                id='clastname'
                label='Sukunimi'
                type='text'
                size='small'
                value={clastname || ''}
                onChange={(e) => this.handleCPersonDataChange('clastname', e.target.value)}
              />
            </Col>
          ) : clastname ? (
            <Col xs={3}>{clastname}</Col>) : null}
        </Row>
        <Row>
          <Col xs={3}>Email</Col>
          <Col xs={3}>{email}</Col>
        </Row>
        <Row>
          <Col xs={3}>Puhelin</Col>
          <Col xs={3}>{phone}</Col>
        </Row>
        <Row>
          <Col xs={3}>Osoite</Col>
          <Col xs={3}>{streetaddress}</Col>
          {this.state.personcDataEdit ? (
            <Col xs={3}>
              <TextField
                id='cstreetaddress'
                label='Osoite'
                type='text'
                size='small'
                value={cstreetaddress || ''}
                onChange={(e) => this.handleCPersonDataChange('cstreetaddress', e.target.value)}
              />
            </Col>
          ) : cstreetaddress ? (
            <Col xs={3}>{cstreetaddress}</Col>) : null}
        </Row>
        <Row>
          <Col xs={3}>Kaupunki</Col>
          <Col xs={3}>{city}</Col>
          {this.state.personcDataEdit ? (
            <Col xs={3}>
              <TextField
                id='ccity'
                label='Kaupunki'
                type='text'
                size='small'
                value={ccity || ''}
                onChange={(e) => this.handleCPersonDataChange('ccity', e.target.value)}
              />
            </Col>
          ) : ccity ? (
            <Col xs={3}>{ccity}</Col>) : null}
        </Row>
        <Row>
          <Col xs={3}>Postinumero</Col>
          <Col xs={3}>{zip}</Col>
          {this.state.personcDataEdit ? (
            <Col xs={3}>
              <TextField
                id='czip'
                label='Postinumero'
                type='text'
                size='small'
                value={czip || ''}
                onChange={(e) => this.handleCPersonDataChange('czip', e.target.value)}
              />
            </Col>
          ) : czip ? (
            <Col xs={3}>{czip}</Col>) : null}
        </Row>
        {this.state.pin ? (
          <Row>
            <Col xs={3}>PIN</Col>
            <Col xs={3}>{this.state.pin}</Col>
          </Row>
        ) : null}
        {this.state.personcDataEdit ? (
          <Row>
            <Col xs={3}></Col>
            <Col xs={3}></Col>
            <Col xs={3}>
              <Button color='primary' size='sm' onClick={this.savePersonCdata} disabled={this.state.personcDataSaved}>
                Tallenna
              </Button>
            </Col>
          </Row>
        ) : null}

        {/* <Row>
          <Col xs={12} className='text-right'>
            <Button color='danger' onClick={() => this.openRemoveDialog(id)}>
              Poista asiakkuus (Piilota)
            </Button>
          </Col>
        </Row> */}
      </>
    )
  }

  toggleNav = (tab) => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab })
  }

  removeCustomershipFromPerson = async () => {
    await this.props.firebase.removeCustomership(this.props.person.id)
    this.toggleRemoveDialog()
  }

  renderAddSaldoRow = () => {
    return (
      <React.Fragment>
        <Row style={{ marginTop: 20 }}>
          {this.state.venues.length > 0 ? (
            <Col md='3'>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id='venue'>Paikka</InputLabel>
                <Select
                  fullWidth
                  labelId='venue-label'
                  id='venue'
                  value={this.state.saldovenue || ''}
                  onChange={this.handleSaldoVenue}
                >
                  {this.state.venues.map((v, i) => {
                    return (
                      <MenuItem key={'venue' + i} value={v.toLowerCase()}>
                        {v}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Col>
          ) : null}
          <Col md='3'>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id='saldotype'>Saldo</InputLabel>
              <Select
                labelId='saldotype-label'
                id='saldotype'
                value={this.state.saldowallet || ''}
                onChange={this.handleSaldoWallet}
              >
                <MenuItem value={'cash'}>Käteinen</MenuItem>
                {/* <MenuItem value={'sportmoney'}>Liikuntaraha</MenuItem> */}
              </Select>
            </FormControl>
          </Col>
          <Col md='3'>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id='paymenttype'>Tapahtuma</InputLabel>
              <Select
                fullWidth
                labelId='paymenttype-label'
                id='paymenttype'
                value={this.state.saldotype || ''}
                onChange={this.handleSaldoType}
              >
                <MenuItem key={'none'}></MenuItem>
                {paymenttypes.map((pt) => {
                  return (
                    <MenuItem key={pt.value} value={pt.value}>
                      {pt.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Col>
          <Col md='2'>
            <TextField
              id='paymentamount'
              label='Summa'
              type='text'
              value={this.state.saldoamount || ''}
              InputProps={{
                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
              }}
              onChange={this.handleSaldoAmount}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col md='8'>
            <TextField
              fullWidth
              id='saldodescription'
              label='Kuvaus'
              type='text'
              value={this.state.saldodescription || ''}
              onChange={this.handleSaldoDescription}
            />
          </Col>
          <Col md='4'>
            <Button
              outline
              size='sm'
              color='success'
              disabled={!(this.state.saldowallet && this.state.saldoamount && this.state.saldotype)}
              onClick={this.onSaveSaldoPayment}
            >
              {this.state.savingsaldo ? <Spinner size='sm' /> : 'Lisää'}
            </Button>
            <Button outline color='primary' size='sm' onClick={this.toggleAddSaldo}>
              Peruuta
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  renderAddSaldoButton = () => {
    return (
      <Row style={{ marginTop: 5, marginBottom: 10 }}>
        <Col>
          <AddCircleIcon fontSize='small' onClick={this.toggleAddSaldo} /> Lisää saldoa
        </Col>
      </Row>
    )
  }

  renderAddStorageRow = () => {
    return (
      <React.Fragment>
        <Row style={{ marginTop: 20 }}>
          {this.state.venues.length > 0 ? (
            <Col md='3'>
              <FormControl style={{ width: '100%' }}>
                <InputLabel id='venue'>Paikka</InputLabel>
                <Select
                  fullWidth
                  labelId='venue-label'
                  id='venue'
                  value={this.state.storagevenue || ''}
                  onChange={this.handleStorageVenue}
                >
                  {this.state.venues.map((v, i) => {
                    return (
                      <MenuItem key={'venue' + i} value={v.toLowerCase()}>
                        {v}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Col>
          ) : null}
          <Col md='3'>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id='saldotype'>Pankki/Varasto</InputLabel>
              <Select
                labelId='saldotype-label'
                id='saldotype'
                value={this.state.storagewallet || ''}
                onChange={this.handleStorageWallet}
              >
                <MenuItem value={'cash'}>Käteinen</MenuItem>
                <MenuItem value={'minutes'}>Aika</MenuItem>
              </Select>
            </FormControl>
          </Col>
          <Col md='3'>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id='paymenttype'>Tapahtuma</InputLabel>
              <Select
                fullWidth
                labelId='paymenttype-label'
                id='paymenttype'
                value={this.state.storagetype || ''}
                onChange={this.handleStorageType}
              >
                <MenuItem key={'none'}></MenuItem>
                {paymenttypes.map((pt) => {
                  return (
                    <MenuItem key={pt.value} value={pt.value}>
                      {pt.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Col>
          <Col md='2'>
            <TextField
              id='paymentamount'
              label='Määrä'
              type='text'
              value={this.state.storageamount || ''}
              InputProps={{
                endAdornment: <InputAdornment position='end'></InputAdornment>,
              }}
              onChange={this.handleStorageAmount}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col md='8'>
            <TextField
              fullWidth
              id='saldodescription'
              label='Kuvaus'
              type='text'
              value={this.state.storagedescription || ''}
              onChange={this.handleStorageDescription}
            />
          </Col>
          <Col md='4'>
            <Button
              outline
              size='sm'
              color='success'
              disabled={!(this.state.storagewallet && this.state.storageamount && this.state.storagetype)}
              onClick={this.onSaveStoragePayment}
            >
              {this.state.savingstorage ? <Spinner size='sm' /> : 'Tallenna'}
            </Button>
            <Button outline color='primary' size='sm' onClick={this.toggleAddStorage}>
              Peruuta
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  renderAddStorageButton = () => {
    return (
      <Row style={{ marginTop: 5, marginBottom: 10 }}>
        <Col>
          <AddCircleIcon fontSize='small' onClick={this.toggleAddStorage} /> Lisää pankkiin/varastoon tapahtuma
        </Col>
      </Row>
    )
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue })
  }

  renderAddMembershipRow = () => {
    if (this.state.showAddMembershipRow) {
      return (
        <>
          <Row style={{ marginTop: 10 }}>
            {this.state.membershipoptions.length > 0 ? (
              <Col md='4'>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id='membership'>Jäsenyys</InputLabel>
                  <Select
                    fullWidth
                    labelId='membership-label'
                    id='membership'
                    value={this.state.membershipselection || ''}
                    onChange={this.handleMembershipSelection}
                  >
                    {this.state.membershipoptions.map((v, i) => {
                      return (
                        <MenuItem key={'membrs' + i} value={v.id}>
                          {v.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Col>
            ) : null}
            <Col md='3'>
              <TextField
                id='membershipstart'
                label='Alkupäivä'
                type='date'
                value={this.state.membershipstart || moment.utc().format('YYYY-MM-DD')}
                onChange={this.handleMembershipStart}
              />
            </Col>
            <Col md='3'>
              <TextField
                id='membershipend'
                label='Loppupäivä'
                type='date'
                value={this.state.membershipend || moment.utc().endOf('year').format('YYYY-MM-DD')}
                onChange={this.handleMembershipEnd}
                disabled={this.state.membershippermanent}
              />
            </Col>
            <Col md='2'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.membershippermanent}
                    onChange={this.handleMembershipPermanent}
                    name='membershippermanent'
                    color='primary'
                  />
                }
                label='Pysyvä'
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }}>
            <Col md='8'>
              <TextField
                fullWidth
                id='membershipnote'
                label='Muistiinpano'
                type='text'
                value={this.state.membershipnote || ''}
                onChange={this.handleMembershipNote}
              />
            </Col>
            <Col md='4'>
              <Button
                outline
                size='sm'
                color='success'
                disabled={!(this.state.membershipselection && this.state.membershipstart && this.state.membershipend)}
                onClick={this.onSaveMembership}
              >
                {this.state.savingmembership ? <Spinner size='sm' /> : 'Lisää'}
              </Button>
              <Button outline color='primary' size='sm' onClick={this.toggleAddMembership}>
                Peruuta
              </Button>
            </Col>
          </Row>
        </>
      )
    } else {
      return (
        <Row style={{ marginTop: 5, marginBottom: 10 }}>
          <Col md='1'>
            <AddCircleIcon fontSize='small' onClick={this.toggleAddMembership} />
          </Col>
          <Col>Lisää jäsenyys</Col>
        </Row>
      )
    }
  }


  onChangeBlockMessage = (e) => {
    const a = this.state.acls
    a.acl.blockedMessage = e.target.value
    this.setState({ acls: a })
  }

  renderAclsTab = () => {
    console.log('this.state.acls', this.state.acls)
    if (this.state.acls) {
      return (
        <React.Fragment>
          {ACL_FIELDS.map((k, i) => {
            if (k === 'allowpostpayment') {
              return (
                <Row style={{ marginTop: 10 }} key={'acl' + i}>
                  <Col md='12'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.acls.acl?.[k]}
                          onChange={(e) => this.setAcl({ allowpostpayment: e.target.checked })}
                          color='primary'
                        />
                      }
                      label='Maksupakotuksen ohitus'
                    />
                  </Col>
                </Row>
              )
            } else if (k === 'blocked') {
              return (
                <Row style={{ marginTop: 10 }} key={'acl' + i}>
                  <Col md='12'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.acls.acl?.[k]}
                          onChange={(e) => this.setAcl({ blocked: e.target.checked })}
                          color='primary'
                        />
                      }
                      label='Varauskielto'
                    />
                  </Col>
                </Row>
              )
            } else if (k === 'blockedMessage') {
              return (
                <Row style={{ marginTop: 10 }} key={'acl' + i}>
                  <Col md='12'>
                    <TextField
                      fullWidth
                      id='blockedMessage'
                      label='Varauskiellon syy (näkyy käyttäjälle)'
                      type='text'
                      value={this.state.acls.acl?.[k] || ''}
                      onChange={this.onChangeBlockMessage}
                    />
                    <Button
                      outline
                      color='primary'
                      size='sm'
                      onClick={() => this.setAcl({ blockedMessage: this.state.acls.acl?.[k] })}
                    >
                      Tallenna viesti
                    </Button>
                  </Col>
                </Row>
              )
            } else if (k === 'quotaoverride') {
              return (
                <Row style={{ marginTop: 10 }} key={'acl' + i}>
                  <Col md='12'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.acls.acl?.[k] || false}
                          onChange={(e) => this.setAcl({ quotaoverride: e.target.checked })}
                          color='primary'
                        />
                      }
                      label='Saa varata enemmän kuin mahdollinen irtovuorojen varausrajoitus.'
                    />
                  </Col>
                </Row>
              )
            }
            return null
          }).filter((v) => v)}
        </React.Fragment>
      )
    } else {
    }
  }

  renderInvoicingTab = () => {
    if (this.state.invoicing) {
      return (<><pre>{JSON.stringify(this.state.invoicing, null, 2)}</pre></>)
    }
    return null
  }

  renderMemberShipTab = () => {
    return (
      <React.Fragment>
        {this.renderPersonMemberships()}
        {this.renderAddMembershipRow()}
      </React.Fragment>
    )
  }

  handleMessageCollapse = (index) => {
    let open = this.state.messageopen
    open[index] = !open[index]
    this.setState({ messageopen: open })
  }

  renderMessages = () => {
    return this.state.messages.map((m, i) => {
      return (
        <React.Fragment key={'msg' + i}>
          <ListItem onClick={() => this.handleMessageCollapse(i)}>
            {this.state.messageopen && this.state.messageopen[i] ? <ExpandLess /> : <ExpandMore />}
            <ListItemText
              primary={m.subject}
              secondary={moment.unix(m.timestamp._seconds).format('DD.MM.YYYY HH:mm') + ' - ' + m.recipient}
            />
            {m.state === 'failed' ? (
              <ListItemSecondaryAction>
                <WarningIcon color='error' />
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
          <Collapse in={this.state.messageopen[i]} timeout='auto' unmountOnExit>
            <Row style={{ marginTop: 15, marginBottom: 30, marginLeft: 30 }}>
              <Col md='12'>
                <Typography variant='body1' color='textSecondary' component='pre'>
                  {m.body}
                </Typography>
              </Col>
            </Row>
          </Collapse>
          <Divider />
        </React.Fragment>
      )
    })
  }

  openMessageModal = (type, id) => {
    let _m = this.state.messaging
    _m.isOpen = !_m.isOpen
    _m.type = type
    if (type === 'passwordreset') {
      this.generatePasswordReset(id)
    }
    // const person = this.state.searchpersons.find((r) => r.id === id)
    this.setState({ messaging: _m })
  }

  generatePasswordReset = async (email) => {
    // console.log(email, ' to reset')
    let response = await this.props.firebase.getPasswordResetLink(email)
    // console.log(response)
    if (response.data.error) {
      this.setState({ passwordResetLink: response.data.error })
    } else {
      this.setState({ passwordResetLink: response.data.link })
    }
  }

  setNewEmail = (event) => {
    const newemail = event.target.value
    this.setState({ newemail })
  }

  setMessagebody = (event) => {
    let _m = this.state.messaging
    _m.body = event.target.value
    this.setState({ messaging: _m })
  }

  setMessageSubject = (event) => {
    let _m = this.state.messaging
    _m.subject = event.target.value
    if (_m.type === 'notification') {
      _m.toolong = event.target.value.length > 80
    }
    this.setState({ messaging: _m })
  }

  sendMessage = () => {
    this.setState({ sending: true })
    this.props.firebase
      .sendMessage(
        this.state.messaging.body,
        this.state.messaging.subject,
        this.props.person.email,
        this.props.person.id,
        ['usersearch']
      )
      .then((response) => {
        // console.log(response)
        this.closeMessageModal()
      })
  }

  sendConfirmation = () => {
    this.setState({ sending: true })
    this.props.firebase.sendConfirmation(this.state.newemail, this.props.person.id, 'fi').then((response) => {
      // console.log(response)
      this.closeMessageModal()
    })
  }

  closeMessageModal = () => {
    let _m = this.state.messaging
    _m.isOpen = false
    this.setState({ messaging: _m, passwordResetLink: 'Luodaan linkkiä ...', sending: false })
  }

  copyClicked = () => {
    this.setState({ copied: true })
    setTimeout(() => {
      this.setState({ copied: false })
    }, '6000')
  }

  createMessageModal = () => {
    let user = this.props.person
    let phone = user && user.phone ? user.phone : ''
    if (this.state.messaging.type === 'call') {
      return (
        <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
          <ModalHeader toggle={this.closeMessageModal}>Soita</ModalHeader>
          <ModalBody>
            <a href={'tel://' + phone}>{phone}</a>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.closeMessageModal}>
              Sulje
            </Button>
          </ModalFooter>
        </Modal>
      )
    } else if (this.state.messaging.type === 'notification') {
      return (
        <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
          <ModalHeader toggle={this.closeMessageModal}>Lähetä notifikaatio appsiin</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for='notificationmessagebody'>Notifikaatio osa (80 merkkiä)</Label>
                <Input
                  invalid={this.state.messaging.toolong}
                  type='textarea'
                  name='notificationmessagebody'
                  id='notificationmessagebody'
                  onChange={this.setMessageSubject}
                />
              </FormGroup>
              <FormGroup>
                <Label for='notificationmessagebody'>Viesti (näkyy käyttäjälle appsissa)</Label>
                <Input
                  type='textarea'
                  name='notificationmessagebody'
                  id='notificationmessagebody'
                  onChange={this.setMessagebody}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.sendMessage}>
              {this.state.sending ? <Spinner size='sm' /> : 'Lähetä'}
            </Button>{' '}
            <Button color='secondary' onClick={this.closeMessageModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )
    } else if (this.state.messaging.type === 'email') {
      return (
        <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
          <ModalHeader toggle={this.closeMessageModal}>Lähetä sähköposti</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for='emailsubject'>Otsikko</Label>
                <Input type='text' name='emailsubject' id='emailsubject' onChange={this.setMessageSubject} />
              </FormGroup>
              <FormGroup>
                <Label for='emailbody'>Viesti</Label>
                <Input type='textarea' name='emailbody' id='emailbody' onChange={this.setMessagebody} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.sendMessage}>
              {this.state.sending ? <Spinner size='sm' /> : 'Lähetä'}
            </Button>{' '}
            <Button color='secondary' onClick={this.closeMessageModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )
    } else if (this.state.messaging.type === 'passwordreset') {
      let r = this.state.passwordResetLink.startsWith('http')
      return (
        <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
          <ModalHeader toggle={this.closeMessageModal}>Salasanan nollaus</ModalHeader>
          <ModalBody>
            {r ? (
              <React.Fragment>
                <code>{this.state.passwordResetLink}</code>
                <br />
                <br />
                <CopyToClipboard
                  text={this.state.passwordResetLink ? this.state.passwordResetLink : ''}
                  onCopy={this.copyClicked}
                >
                  <Button disabled={this.state.copied}>{this.state.copied ? 'Kopioitu' : 'Kopioi'}</Button>
                </CopyToClipboard>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Spinner size={'sm'} /> Luodaan linkkiä ...
              </React.Fragment>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.closeMessageModal}>
              Sulje
            </Button>
          </ModalFooter>
        </Modal>
      )
    } else if (this.state.messaging.type === 'emailchange') {
      return (
        <Modal isOpen={this.state.messaging.isOpen} toggle={this.closeMessageModal} className={'className'}>
          <ModalHeader toggle={this.closeMessageModal}>Sähköpostin vaihto</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for='newemail'>Uusi email</Label>
                <Input type='text' name='newemail' id='newemail' onChange={this.setNewEmail} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalBody>
            Käyttäjälle lähtee uuteen sähköpostiin viesti jossa on vahvistuskoodi jolla hän voi vahvistaa uuden
            sähköpostiosoitteen.
            <br />
            <br />
            Vahvistaminen
            <br />
            <br />
            * Käyttäjä kirjautuu vanhalla osoitteella varausjärjestelmään
            <br />
            * Omat tiedot kohdassa on nappi vahvistuskoodi josta painamalla pääsee syöttämään vahvistuksen
            <br />
            * Käyttäjän sähköposti ei muutu mikäli vahvistuskoodia ei syötetä
            <br />
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.sendConfirmation}>
              {this.state.sending ? <Spinner size='sm' /> : 'Lähetä vahvistusviesti'}
            </Button>{' '}
            <Button color='secondary' onClick={this.closeMessageModal}>
              Peru
            </Button>
          </ModalFooter>
        </Modal>
      )
    }
  }

  getColDefinitionsForReservations = () => {
    const cols = [
      {
        headerName: 'Päivä',
        field: 'starttime',
        disableClickEventBubbling: true,
        valueFormatter: (params) => {
          return new Date(params.value).toLocaleDateString()
        },
      },
      {
        headerName: 'Alku',
        field: 'start',
        disableClickEventBubbling: true,
      },
      {
        headerName: 'Loppu',
        field: 'end',
        disableClickEventBubbling: true,
      },
      { headerName: 'Kenttä', field: 'court' },
      {
        headerName: 'Vakio/Irto',
        field: 'reservationtype',
      },
      {
        headerName: 'Hinta',
        field: 'cost',
        valueFormatter: (params) => {
          return params.value + ' €'
        },
      },
      {
        headerName: 'Maksettu',
        field: 'paid',
      },
      {
        headerName: 'Laskutettu',
        field: 'invoiced',
        maxWidth: 70,
      },
      {
        headerName: 'Tila',
        field: 'stateName',
        maxWidth: 70,
      },
      {
        headerName: 'Muokkaa',
        field: 'edit',
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <EditButton
              onClick={() => {
                this.handleSelectEvent(params.row.id, params.row.occurency)
              }}
            />
          )
        },
      },
    ]
    return cols.map((x) => {
      return { ...x, flex: 1 / cols.length }
    })
  }

  render() {
    if (!this.props.person) {
      return <></>
    }

    // if (this.state.loading) {
    //   return (
    //     <>
    //       {this.renderPersonData()}
    //       <Row>
    //         <Col>
    //           <Spinner />
    //         </Col>
    //       </Row>
    //     </>
    //   )
    // } else {
    const { id, firstname, lastname, email } = this.props.person
    return (
      <div style={{ padding: 20, backgroundColor: 'white' }}>
        <Row>
          <Col>
            <Typography variant='h4' gutterBottom>
              {firstname} {lastname}
            </Typography>
          </Col>
          <Col className='text-right'>
            <Button size={'sm'} onClick={() => this.removeCustomershipFromPerson()} color='danger'>
              Poista asiakkuus (piilota)
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <MuiButton
              variant='outlined'
              size='small'
              onClick={(el) => {
                this.openMessageModal('call', id)
              }}
            >
              <PhoneIcon fontSize='small' /> Soita
            </MuiButton>{' '}
            <MuiButton
              variant='outlined'
              size='small'
              onClick={(el) => {
                this.openMessageModal('email', email)
              }}
            >
              <MailOutlineIcon fontSize='small' /> Lähetä email
            </MuiButton>{' '}
            <MuiButton
              variant='outlined'
              size='small'
              onClick={(el) => {
                this.openMessageModal('passwordreset', email)
              }}
            >
              <VpnKeyIcon fontSize='small' /> Salasanan vaihto
            </MuiButton>{' '}
            <MuiButton
              variant='outlined'
              size='small'
              onClick={(el) => {
                this.openMessageModal('emailchange', id)
              }}
            >
              <AlternateEmailIcon fontSize='small' /> Vaihda email
            </MuiButton>
            {' '}
            {this.state.productpilots &&
              <MuiButton
                variant='outlined'
                size='small'
                onClick={(el) => {
                  this.toggleAddToInvoiceDialog()
                }}
              >
                <ReceiptIcon fontSize='small' /> Lisää laskulle
              </MuiButton>
            }
          </Col>
        </Row>
        <Row className='mt-2 border-bottom mb-2'>
          <Col>
            <Tabs
              value={this.state.tabValue}
              indicatorColor='primary'
              // textColor="primary"
              onChange={this.handleTabChange}
              // aria-label="disabled tabs example"
              variant='scrollable'
            >
              <Tab label='Yhteystiedot' />
              <Tab label='Saldot' />
              <Tab label='Jäsenyydet' />
              <Tab label='Sarjakortit' />
              <Tab label='Varaukset' />
              <Tab label='Viestit' />
              <Tab label='Oikeudet' />
              {this.state.productpilots && <Tab label='Laskutettavaa' />}
              {/* <Tab label='Laskut' /> */}
            </Tabs>
          </Col>
        </Row>
        <Row>
          <Col>
            <TabPanel value={this.state.tabValue} index={0}>
              {this.renderPersonData()}
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={1}>
              {!this.state.saldoloading && !this.state.storageloading ? (
                <React.Fragment>
                  <Row>
                    <Col>
                      <Typography variant='h6' gutterBottom>
                        Saldo
                      </Typography>{' '}
                      {this.state.showAddSaldoRow ? this.renderAddSaldoRow() : this.renderAddSaldoButton()}
                    </Col>
                  </Row>
                  <Row>
                    <Col>{this.renderSaldoData()}</Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col>{this.renderSaldoTransactions()}</Col>
                  </Row>
                  <Row className='border-top'>
                    <Col>
                      <Typography variant='h6' gutterBottom>
                        Pankki / Varasto
                      </Typography>{' '}
                      {this.state.showAddStorageRow ? this.renderAddStorageRow() : this.renderAddStorageButton()}
                    </Col>
                  </Row>
                  <Row>
                    <Col>{this.renderStorageData()}</Col>
                  </Row>
                  <Row>
                    <Col>{this.renderStorageTransactions()}</Col>
                  </Row>
                </React.Fragment>
              ) : (
                <Row className='text-center'>
                  <Col>
                    <Spinner />
                  </Col>
                </Row>
              )}
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={2}>
              {this.state.membershipsloading ? (
                <Row className='text-center'>
                  <Col>
                    <Spinner />
                  </Col>
                </Row>
              ) : (
                this.renderMemberShipTab()
              )}
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={3}>
              {!this.state.cardsloading ? (
                <PersonBenefitCards cards={this.state.cards} benefitcards={this.state.benefitcards} personId={this.props.person.id} onSaved={this.handleBenefitcardSaved} />
              ) : (
                <Row className='text-center'>
                  <Col>
                    <Spinner />
                  </Col>
                </Row>
              )}
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={4}>
              <div style={{ display: 'flex', minHeight: 'calc(100vh - 400px)', backgroundColor: '#FFFFFF' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={this.state.formattedReservations}
                    columns={this.getColDefinitionsForReservations()}
                    density='compact'
                    pageSize={20}
                    disableSelectionOnClick={true}
                    rowsPerPageOptions={[20, 50, 100]}
                    pagination
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={5}>
              {!this.state.messagesloading ? (
                this.renderMessages()
              ) : (
                <Row className='text-center'>
                  <Col>
                    <Spinner />
                  </Col>
                </Row>
              )}
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={6}>
              {this.state.aclsloading ? (
                <Row className='text-center'>
                  <Col>
                    <Spinner />
                  </Col>
                </Row>
              ) : (
                this.renderAclsTab()
              )}
            </TabPanel>
            <TabPanel value={this.state.tabValue} index={7}>
              {this.state.invoicingloading ? (
                <Row className='text-center'>
                  <Col>
                    <Spinner />
                  </Col>
                </Row>
              ) : (
                  <PersonProductOrders person={this.props.person} orders={this.state.invoicing} onUpdate={this.updateProductOrders} />
                // this.renderInvoicingTab()
              )}
            </TabPanel>
           {/*  <TabPanel value={this.state.tabValue} index={6}> */}
            {/* {this.renderPayments()} */}
            {/* </TabPanel> */}
          </Col>
        </Row>
        <Modal size='sm' isOpen={this.state.loadingModalOpen} toggle={this.toggleLoadingModal} backdrop={'static'}>
          <ModalBody>
            <Spinner />
          </ModalBody>
        </Modal>
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={this.state.removePersonConfirmation}
          onClose={this.toggleRemoveDialog}
          aria-labelledby='max-width-dialog-title'
          style={{ zIndex: '1000' }}
        >
          <DialogTitle id='max-width-dialog-title'>Vahvista poisto</DialogTitle>
          <DialogContent>
            Oletko aivan varma, että haluat poistaa asiakkuuden tältä käyttäjältä? Muista takistaa onko käyttäjällä
            maksamattomia varauksia ennen kuin poistat asiakkuuden. Poistamiseen menee hetken aikaa jonka jälkeen
            henkilö ei löydy enää hausta.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.removeCustomershipFromPerson()} color='danger'>
              Poista asiakkuus (piilota)
            </Button>
            <Button onClick={this.toggleRemoveDialog} color='primary'>
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
        {this.createMessageModal()}
        <ShopProductDialog title={'Lisää tuote laskulle'} open={this.state.addToInvoiceDialog} onClose={this.toggleAddToInvoiceDialog} payer={this.props.person} onUpdate={this.updateProductOrders} />
      </div>
    )
  }
  // }
}

export default withFirebase(PersonDetails2)
