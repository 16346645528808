export const _benefitCardUISchema = {
  createcode: {
    'ui:description': 'Huom! Ominaisuus tuettuna vain Digioven kulunvalvonnan kanssa.',
  },
  accessstart: {
    'ui:description': 'Esimerkiksi 6:30',
  },
  accessend: {
    'ui:description': 'Esimerkiksi 22:00',
  },
  accesslevels: {
    'ui:description': 'Katso mahdolliset arvot Digiovesta. Erottimena pilkku.',
  },
}
export const _benefitCardClass = {
  name: { type: 'string', title: 'Sarjakortin nimi' },
  price: { type: 'number', title: 'Hinta' },
  // count: { type: 'number', title: 'Käyntikertojen määrä' },
  // duration: { type: 'number', title: 'Kesto (päivää)' },
  description: { type: 'string', title: 'Kuvaus' },
  validuntil: { type: 'string', format: 'date', title: 'Voimassaolon viimeinen päivä' },
  availableinshop: {
    type: 'string',
    title: 'Näytetäänkö verkkokaupassa (Ominaisuus ei vielä käytössä)',
    enum: ['true', 'false'],
    enumNames: ['Kyllä', 'Ei'],
  },
  sport: {
    type: 'string',
    title: 'Laji',
    enum: [],
    enumNames: [],
  },
  type: {
    type: 'string',
    title: 'Sarjakortin tyyppi',
    enum: ['time', 'count'],
    enumNames: ['Aikapohjainen', 'Kertapohjainen'],
  },
}
export const _benefitCardClassDeps = {
  type: {
    oneOf: [
      {
        properties: {
          type: {
            enum: ['time'],
          },
          duration: {
            type: 'number',
            title: 'Kesto (päivää)',
          },
          createcode: {
            type: 'string',
            title: 'Luodaanko kortille pääsykoodi? (tukee Digiovea)',
            enum: ['true', 'false'],
            enumNames: ['Kyllä. Huom! Tukee Digiovea!', 'Ei'],
          },
          required: ['duration', 'createcode'],
        },
      },
      {
        properties: {
          type: {
            enum: ['count'],
          },
          count: {
            type: 'number',
            title: 'Käyntikertojen määrä',
          },
          required: ['count'],
        },
      },
    ],
  },
  availableinshop: {
    oneOf: [
      {
        properties: {
          availableinshop: {
            enum: ['true'],
          },
          vat: { type: 'number', title: 'ALV', enum: [0, 10, 14, 24, 25.5], enumNames: ['0%', '10%', '14%', '24%', '25,5%'] },
          accounting: { type: 'string', title: 'Kirjanpidontili' },
          productCode: { type: 'string', title: 'Tuotekoodi' },
        }
      },
      {
        properties: {
          availableinshop: {
            enum: ['false'],
          },
        }
      }
    ]
  },
  createcode: {
    oneOf: [
      {
        properties: {
          createcode: {
            enum: ['true'],
          },
          accesslevels: {
            type: 'string',
            title: 'Kortin pääsykoodin kulkutasot.',
          },
          required: ['accesslevels'],
        },
      },
      {
        properties: {
          createcode: {
            enum: ['false'],
          },
        },
      },
    ],
  },
}
