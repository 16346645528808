import React from 'react'
// import DemoAlert from 'views/components/DemoAlert'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import Switch from 'react-bootstrap-switch'
import Select from 'react-select'
import {
  FormControl,
  FormLabel,
  FormGroup as MuiFormGroup,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import moment from 'moment-timezone'
import 'moment/locale/fi'
import { Prompt } from 'react-router-dom'
import TinyEditor from '../Editor/TinyEditor'
import { v4 as uuidv4 } from 'uuid'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const TIMEZONE_OFFSET = new Date().getTimezoneOffset()

const localizer = momentLocalizer(moment)

const DnDCalendar = withDragAndDrop(Calendar)

const weekdaysAsResources = [
  { resourceId: 1, resourceTitle: 'Maanantai' },
  { resourceId: 2, resourceTitle: 'Tiistai' },
  { resourceId: 3, resourceTitle: 'Keskiviikko' },
  { resourceId: 4, resourceTitle: 'Torstai' },
  { resourceId: 5, resourceTitle: 'Perjantai' },
  { resourceId: 6, resourceTitle: 'Lauantai' },
  { resourceId: 7, resourceTitle: 'Sunnuntai' },
]

const CONFIRMATION_TYPES = [
  { value: 'reservation', label: 'Varaus' },
  { value: 'move', label: 'Siirto' },
  { value: 'cancellation', label: 'Peruutus' },
]

const PricingToolBar = () => {
  return <></>
}

const INIT_TEMPLATE = {
  venue: 'all',
  lang: 'all',
  channel: 'mail',
  memberships: [],
  enabled: true,
  default: false,
  collection: 'reservation', // activity
  schedule: [],
  resources: [],
  message: {
    subject: '',
    body: '',
    htmlbody: '',
  },
}

const DEFAULT_CONFIRMATION_TEMPLATES = [
  {
    type: 'reservation',
    category: 'all',
    venue: 'all',
    lang: 'all',
    channel: 'mail',
    memberships: [],
    enabled: true,
    default: true,
    collection: 'reservation',
    message: {
      subject: 'Varausvahvistus {SUBJECT_SHORT}',
      body:
        'Hei {PERSON_NAME},\r\n\r\nVahvistetun varauksen tiedot,\r\n\r\n' +
        'Aika	{RESERVATION_DATE_TIME},\r\n\r\nPaikka	{RESERVATION_VENUE},\r\n\r\nKenttä	{RESERVATION_COURT} / {RESERVATION_SPORT} \r\n\r\nHinta	{RESERVATION_PRICE},\r\n\r\nTervetuloa!',
      htmlbody: '',
    },
  },
  {
    type: 'move',
    category: 'all',
    venue: 'all',
    lang: 'all',
    channel: 'mail',
    memberships: [],
    enabled: true,
    default: true,
    collection: 'reservation',
    message: {
      subject: 'Vuoron siirto {SUBJECT_SHORT}',
      body:
        'Hei {PERSON_NAME},\r\n\rSiirretyn varauksen tiedot,\r\n\r\n' +
        'Aika	{RESERVATION_DATE_TIME},\r\n\r\nPaikka	{RESERVATION_VENUE},\r\n\r\nKenttä	{RESERVATION_COURT} / {RESERVATION_SPORT} \r\n\r\nTervetuloa!',
      htmlbody: '',
    },
  },
  {
    type: 'cancellation',
    category: 'all',
    venue: 'all',
    lang: 'all',
    channel: 'mail',
    memberships: [],
    enabled: true,
    default: true,
    collection: 'reservation',
    message: {
      subject: 'Vuoron peruutus {SUBJECT_SHORT}',
      body:
        'Hei {PERSON_NAME},\r\n\rPeruutetun varauksen tiedot,\r\n\r\n' +
        'Aika	{RESERVATION_DATE_TIME},\r\n\r\nPaikka	{RESERVATION_VENUE},\r\n\r\nKenttä	{RESERVATION_COURT} / {RESERVATION_SPORT}',
        htmlbody: '',
    }
  },
]

function formatSchedule(schedule) {
  return schedule.map((s) => {
    return {
      id: s.id,
      start: moment('1970-01-01 ' + s.start).toDate(),
      end: moment('1970-01-01 ' + s.end).toDate(),
      resourceId: s.day,
    }
  })
}

function ConfirmationMessageRow(props) {
  const {
    index: i,
    template,
    openEditMessageModal,
    openEditScheduleModal,
    openEditResourcesModal,
    updateTemplateName,
    isDefault,
  } = props
  return (
    <Row key={i} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.10)' }}>
      <Col md='3' xs='12'>
        <FormGroup>
          <Label for={'label.' + i}>Tyyppi</Label>
          <Select
            className='react-select primary'
            classNamePrefix='react-select'
            name={'label.' + i}
            id={'label.' + i}
            isDisabled={template.default}
            value={
              template.type
                ? CONFIRMATION_TYPES.find((x) => x.value === template.type)
                : // ? { value: this.state.confirmations[i].type, label: this.state.confirmations[i].type }
                  ''
            }
            defaultValue={CONFIRMATION_TYPES[0]}
            // onChange={this.setType}
            options={CONFIRMATION_TYPES}
          />
        </FormGroup>
      </Col>
      <Col md='1' xs='12'>
        <Label for={'enabled.' + i}>Käytössä</Label>{' '}
        <div name={'enabled.' + i}>
          <Switch
            defaultValue={template.enabled}
            onColor='primary'
            offColor='primary'
            id={'enabled.' + i}
            name={'enabled.' + i}
            // onChange={this.onChange}
            disabled={template.default}
          />
        </div>
      </Col>
      <Col md='3' xs='12' style={{ paddingRight: 30 }}>
        <TextField
          fullWidth
          id={'diplayName.' + i}
          label='Nimi'
          value={
            template.default
              ? 'Oletusviesti. Tarkista sisältö'
              : template.displayName
              ? template.displayName
              : ''
          }
          disabled={template.default}
          onChange={updateTemplateName}
        />
      </Col>
      <Col>
        <Button color={'success'} onClick={() => openEditMessageModal(i)}>
          Viesti
        </Button>
        <Button
          disabled={isDefault}
          color={'success'}
          onClick={() => openEditScheduleModal(i)}
        >
          Aikataulu
        </Button>
        <Button
          disabled={isDefault}
          color={'success'}
          onClick={() => openEditResourcesModal(i)}
        >
          Resurssit
        </Button>
      </Col>
    </Row>
  )
}

class ConfirmationMessageTemps extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmations: [],
      resourcecategories: [],
      resources: [],
      categoryOptions: [],
      loadingTemplates: true,
      loadingCategories: true,
      loadingResources: true,
      saved: true,
      showMessageModal: false,
      showScheduleModal: false,
      showResourcesModal: false,
      selectedIndex: -1,
      showDynamicHelp: false,
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase
      .getMessageTemplates()
      .onSnapshot((doc) => {
        if (doc.exists) {
          this.setState({
            confirmations:
              doc.data().confirmations || DEFAULT_CONFIRMATION_TEMPLATES,
            loadingTemplates: false,
          })
        } else {
          this.setState({
            loadingTemplates: false,
            confirmations: DEFAULT_CONFIRMATION_TEMPLATES,
          })
        }
      })
    this.unsubscribeCats = this.props.firebase
      .getResourceCategories()
      .onSnapshot((doc) => {
        if (doc.exists) {
          const resourcecategories = doc.data().categories
          const _categoryOptions = resourcecategories.map((rc) => ({
            value: rc.category.toLowerCase(),
            label: rc.category,
          }))
          _categoryOptions.push({ value: 'all', label: 'Kaikki' })

          this.setState({
            resourcecategories: doc.data().categories,
            categoryOptions: _categoryOptions,
            loadingCategories: false,
          })
        } else {
          this.setState({
            loadingCategories: false,
          })
        }
      })
    this.props.firebase.getResourses().then((_resources) => {
      const resources = _resources.docs
        .map((sh) => {
          return { ...sh.data(), id: sh.id }
        })
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
      if (resources) {
        this.setState({
          resources: resources,
          loadingResources: false,
        })
      } else {
        this.setState({
          resources: [],
          loadingResources: false,
        })
      }
    })
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
    if (this.unsubscribeCats) {
      this.unsubscribeCats()
    }
  }

  addConfirmationRow = () => {
    let j = this.state.confirmations
    j.push(JSON.parse(JSON.stringify(INIT_TEMPLATE)))
    this.setState({ confirmations: j })
  }

  toggleMessage = () => {
    this.setState({ showMessageModal: !this.state.showMessageModal })
  }

  toggleSchedule = () => {
    this.setState({ showScheduleModal: !this.state.showScheduleModal })
  }

  toggleResources = () => {
    this.setState({ showResourcesModal: !this.state.showResourcesModal })
  }

  setCategory = (selection) => {
    let _a = this.state.confirmations
    let _s = selection.value
    _a[this.state.selectedIndex].category = _s
    this.setState({ confirmations: _a, saved: false })
  }

  handleMessageBodyChange = (html) => {
    let j = this.state.confirmations
    if (!j[this.state.selectedIndex].message)
      j[this.state.selectedIndex].message = { htmlbody: '', subject: '' }
    j[this.state.selectedIndex].message.htmlbody = html
    this.setState({ confirmations: j, saved: false })
  }

  handleMessageSubjectChange = (e) => {
    let j = this.state.confirmations
    if (!j[this.state.selectedIndex].message)
      j[this.state.selectedIndex].message = { body: '', subject: '' }
    j[this.state.selectedIndex].message.subject = e.target.value
    this.setState({ confirmations: j, saved: false })
  }

  handleResourceChange = (e) => {
    let j = this.state.confirmations
    if (!j[this.state.selectedIndex].resources)
      j[this.state.selectedIndex].resources = []
    if (e.target.checked) {
      j[this.state.selectedIndex].resources.push(e.target.name)
    } else {
      j[this.state.selectedIndex].resources = j[
        this.state.selectedIndex
      ].resources.filter((x) => x !== e.target.name)
    }
    this.setState({ confirmations: j, saved: false })
  }

  onSave = (event) => {
    event.preventDefault()
    let _rows = this.state.confirmations.map((r) => {
      delete r.empty
      return r
    })
    this.props.firebase
      .addMessageTemplates({
        confirmations: _rows,
      })
      .then((dataSnapshot) => {
        this.setState({ saved: true })
      })
  }

  handleSelectEvent = (event) => {
    console.log(this.state.confirmations[this.state.selectedIndex].schedule)
    if (window.confirm('Poista ?')) {
      let j = this.state.confirmations
      j[this.state.selectedIndex].schedule = j[
        this.state.selectedIndex
      ].schedule.filter((e) => e.id !== event.id)
      this.setState({ confirmations: j, saved: false })
    }
  }

  handleSelectSlot = (slotInfo) => {
    console.log(slotInfo)
    let s = moment(slotInfo.start)
    let e = moment(slotInfo.end)
    if (e.diff(s, 'minutes') < 30) {
      e = moment(s).add(30, 'minutes')
    }
    let _ePlaceholder = {
      id: uuidv4(),
      start: s.format('HH:mm'),
      end: e.format('HH:mm'),
      day: slotInfo.resourceId,
    }
    let j = this.state.confirmations
    if (!j[this.state.selectedIndex].schedule) j[this.state.selectedIndex].schedule = []
    j[this.state.selectedIndex].schedule.push(_ePlaceholder)
    this.setState({ confirmations: j, saved: false })
  }

  moveEvent = ({ event, start, end, resourceId }) => {
    let j = this.state.confirmations
    j[this.state.selectedIndex].schedule = j[this.state.selectedIndex].schedule.map((e) => {
      if (e.id === event.id) {
        const _s = moment(start)
        const _e = moment(end)
        return { ...e, start: _s.format('HH:mm'), end: _e.format('HH:mm'), day: resourceId }
      } else {
        return e
      }
    })
    this.setState({ confirmations: j, saved: false })
  }

  resizeEvent = ({ event, start, end }) => {
    let j = this.state.confirmations
    j[this.state.selectedIndex].schedule = j[this.state.selectedIndex].schedule.map((e) => {
      if (e.id === event.id) {
        const _s = moment(start)
        const _e = moment(end)
        return { ...e, start: _s.format('HH:mm'), end: _e.format('HH:mm') }
      } else {
        return e
      }
    })
    this.setState({ confirmations: j, saved: false })
  }

  toggleDynamicHelp = () => {
    this.setState({ showDynamicHelp: !this.state.showDynamicHelp })
  }

  render() {
    if (!this.state.loadingCategories && !this.state.loadingTemplates) {
      return (
        <>
          <div className='content'>
            <Row>
              <Col md='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col lg='10' md='5' sm='5'>
                        <CardTitle tag='h5'>Vahvistusviestit</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.confirmations.map((c, i) => {
                      return (
                        <ConfirmationMessageRow
                          key={i}
                          index={i}
                          template={c}
                          isDefault={c.default}
                          openEditMessageModal={() => {
                            this.setState({ selectedIndex: i })
                            this.toggleMessage()
                          }}
                          openEditScheduleModal={() => {
                            this.setState({ selectedIndex: i })
                            this.toggleSchedule()
                          }}
                          openEditResourcesModal={() => {
                            this.setState({ selectedIndex: i })
                            this.toggleResources()
                          }}
                          updateTemplateName={(e) => {
                            let j = this.state.confirmations
                            j[i].displayName = e.target.value
                            this.setState({ confirmations: j, saved: false })
                          }}
                        />
                      )
                    })}
                    {/* {this.messageTemplateRows()} */}
                    <Row>
                      <Col style={{ marginTop: '20px' }}>
                        <Button
                          className='btn-round btn-icon'
                          color='primary'
                          size='sm'
                          onClick={() => {
                            this.addConfirmationRow()
                          }}
                        >
                          <i className='fa fa-plus' />
                        </Button>{' '}
                        Lisää vahvistusviesti
                      </Col>
                    </Row>
                    <Row>
                      <Col className='ml-auto mr-auto' sm='12'>
                        <Button
                          disabled={this.state.saved}
                          color='primary'
                          onClick={this.onSave}
                        >
                          Tallenna muutokset
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Message modal */}
            <Modal
              isOpen={this.state.showMessageModal}
              toggle={this.toggleMessage}
              size='lg'
            >
              <ModalHeader toggle={this.toggleMessage}>Viesti</ModalHeader>
              <ModalBody>
              <div>
            <Label for='messagetemplates'>Dynaamiset kentät</Label>
            {this.state.showDynamicHelp ? <KeyboardArrowUpIcon onClick={this.toggleDynamicHelp} /> : <KeyboardArrowDownIcon onClick={this.toggleDynamicHelp} />}
          </div>
          {this.state.showDynamicHelp ? (
          <div>
            <Row>
              <Col>&#123;SUBJECT_SHORT&#125;</Col>
              <Col>Vakiomuotoinen otsikko</Col>
            </Row>
            <Row>
              <Col>&#123;PERSON_NAME&#125;</Col>
              <Col>Asiakkaan etu- ja sukunimi</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_DATE_TIME&#125;</Col>
              <Col>Varauksen päivä ja aika</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_VENUE&#125;</Col>
              <Col>Paikka</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_COURT&#125;</Col>
              <Col>Kenttä</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_SPORT&#125;</Col>
              <Col>Kategoria / laji</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_PRICE&#125;</Col>
              <Col>Hinta</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_CODE&#125;</Col>
              <Col>Sisäänpääsykoodi</Col>
            </Row>
            <Row>
              <Col>&#123;RESERVATION_EXTRAS&#125;</Col>
              <Col>Lisätuotteiden vahvistustekstit</Col>
            </Row>
          </div>
            ): null }


                <FormGroup>
                  <Label for='messageSubject'>Otsikko</Label>
                  <Input
                    id='messageSubject'
                    value={
                      this.state.confirmations?.[this.state.selectedIndex]
                        ?.message.subject
                        ? this.state.confirmations[this.state.selectedIndex]
                            ?.message.subject
                        : ''
                    }
                    name='messageSubject'
                    onChange={this.handleMessageSubjectChange}
                  />
                </FormGroup>
                <TinyEditor
                  value={
                    this.state.confirmations?.[this.state.selectedIndex]
                      ?.message.htmlbody
                      ? this.state.confirmations[this.state.selectedIndex]
                          ?.message.htmlbody
                      : this.state.confirmations?.[this.state.selectedIndex]
                          ?.message.body
                      ? this.state.confirmations[
                          this.state.selectedIndex
                        ]?.message.body.replace(/\n/g, '<br/>')
                      : ''
                  }
                  onChange={this.handleMessageBodyChange}
                  onClose={this.handleMessageBodyChange}
                  disableSave={true}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color='primary'
                  onClick={() => {
                    this.toggleMessage()
                  }}
                >
                  Sulje
                </Button>
              </ModalFooter>
            </Modal>
            {/* Schedule modal */}
            <Modal
              isOpen={this.state.showScheduleModal}
              toggle={this.toggleSchedule}
              size='xl'
            >
              <ModalHeader toggle={this.toggleSchedule}>Aikataulu</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md='12'>
                    <DnDCalendar
                      style={{ height: '85vh' }}
                      ref={this.myRef}
                      selectable
                      localizer={localizer}
                      events={formatSchedule(this.state.confirmations?.[this.state.selectedIndex]?.schedule || [])}
                      views={['day']}
                      step={30}
                      min={new Date(1970, 0, 1, 23, 0, 0)}
                      defaultView={Views.DAY}
                      scrollToTime={new Date(1970, 0, 1, 12)}
                      defaultDate={new Date(1970, 0, 1, 12)}
                      onSelectEvent={(event) => this.handleSelectEvent(event)}
                      onSelectSlot={this.handleSelectSlot}
                      // onView={this.updateView}
                      components={{ toolbar: PricingToolBar }}
                      // eventPropGetter={this.eventStyleGetter}
                      resources={weekdaysAsResources}
                      resourceIdAccessor='resourceId'
                      resourceTitleAccessor='resourceTitle'
                      draggableAccessor={(event) => true}
                      onEventDrop={this.moveEvent}
                      onEventResize={this.resizeEvent}
                      resizable
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  color='primary'
                  onClick={() => {
                    this.toggleSchedule()
                  }}
                >
                  Sulje
                </Button>
              </ModalFooter>
            </Modal>
            {/* Resources modal */}
            <Modal
              isOpen={this.state.showResourcesModal}
              toggle={this.toggleResources}
              size='lg'
            >
              <ModalHeader toggle={this.toggleResources}>Resurssit</ModalHeader>
              <ModalBody>
                <div>
                  <p>
                    Valitse kategoria tai yksittäiset resurssit. Jos yksikin
                    resurssi valittu, niin kategoriaa ei huomioida.
                  </p>
                  <div
                    style={{
                      display:
                        this.state.confirmations[this.state.selectedIndex]
                          ?.resources &&
                        this.state.confirmations[this.state.selectedIndex]
                          ?.resources.length > 0
                          ? 'none'
                          : 'block',
                    }}
                  >
                    <FormGroup>
                      <Label for={'catlabel'}>Kategoria</Label>
                      <Select
                        className='react-select primary'
                        classNamePrefix='react-select'
                        name={'catlabel'}
                        id={'catlabel'}
                        isDisabled={
                          this.state.confirmations[this.state.selectedIndex]
                            ?.default ||
                          (this.state.confirmations[this.state.selectedIndex]
                            ?.resources &&
                            this.state.confirmations[this.state.selectedIndex]
                              ?.resources.length > 0)
                        }
                        value={
                          this.state.confirmations[this.state.selectedIndex]
                            ?.category
                            ? this.state.categoryOptions.find(
                                (x) =>
                                  x.value ===
                                  this.state.confirmations[
                                    this.state.selectedIndex
                                  ].category
                              )
                            : ''
                        }
                        defaultValue={{ value: 'all', label: 'Kaikki' }}
                        onChange={this.setCategory}
                        options={this.state.categoryOptions}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div>
                  <FormControl component='fieldset'>
                    <FormLabel component='legend'>Valitse resurssit</FormLabel>
                    <MuiFormGroup>
                      {this.state.resources.map((r, i) => {
                        return (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                checked={
                                  this.state.confirmations?.[
                                    this.state.selectedIndex
                                  ]?.resources
                                    ? this.state.confirmations?.[
                                        this.state.selectedIndex
                                      ]?.resources.includes(r.id)
                                    : false
                                }
                                name={r.id}
                                onChange={this.handleResourceChange}
                              />
                            }
                            label={r.displayName}
                          />
                        )
                      })}
                    </MuiFormGroup>
                  </FormControl>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color='primary'
                  onClick={() => {
                    this.toggleResources()
                  }}
                >
                  Sulje
                </Button>
              </ModalFooter>
            </Modal>
          </div>
          <Prompt
            when={!this.state.saved}
            message='Muutoksia ei ole tallennettu. Haluatko varmasti poistua?'
          />
        </>
      )
    } else {
      return <></>
    }
  }
}

export default ConfirmationMessageTemps
