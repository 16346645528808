import React, { Component } from 'react';
import { withFirebase } from '../Fb';
import Reservation from '../Classes/Reservation';
import { Divider, TextField, Typography, Backdrop } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button } from 'reactstrap'
import moment from 'moment'
import algoliasearch from 'algoliasearch'
import SaldopaymentDialog from '../Dialogs/SaldopaymentDialog'
import StoragepaymentDialog from '../Dialogs/StoragepaymentDialog'
import ReservationModal from '../Inputs/ReservationModal';

const algoliaclient = algoliasearch('6WV1D95IXL', 'd7e7d2238757ecd7bfd520b2b3a5562f');
const algoliaprofile = algoliaclient.initIndex('profile')
const paymenttypes = [
  { value: 'cash', label: 'Käteinen' },
  { value: 'sportmoney-smartum', label: 'Smartum' },
  { value: 'sportmoney-epassi', label: 'ePassi' },
  { value: 'sportmoney-edenred', label: 'Edenred' },
  { value: 'gift-certificate', label: 'Lahjakortti' },
  { value: 'personelbenefit', label: 'Henkilökuntaetu' },
  { value: 'invoice', label: 'Manuaalinen lasku' },
]
const PROFILE_FIELDS = ['isblocked', 'ispastpayment', 'isrecurring', 'favoriteSport', 'memberships', 'saldos', 'storage']

class ReservationDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      reservation: {},
      id: props.id,
      booking: props.booking,
      reservationClass: null,
      occurency: props.occurency,
      userprofile: null,
      paymentamount: 0,
      note: '',
      saving: false,
      showSaldopaymentDialog: false,
      showStoragepaymentDialog: false,
      reservationModalOpen: false,
    }
  }


  fetchReservation = (update) => {
    this.props.firebase.getReservation(this.props.id).then((reservation) => {
      if (!reservation.exists) return console.log('Reservation not found')
      const _r = new Reservation(reservation.data())
      if (update) {
        const paymentStatus = _r.getPaymentStatus(this.props.occurency.format('YYYY-MM-DD'))
        console.log('fetchReservation update', this.props)
        console.log('fetchReservation update', paymentStatus)
        if (paymentStatus.unpaid === 0) {
          if (this.props.onClose) {
            this.props.onClose()
            return
          }
        }
      }
      console.log(this.props.occurency)
      console.log('fetchReservation', _r.getPaymentStatus(this.props.occurency.format('YYYY-MM-DD')) )
      this.setState({ reservation: { ...reservation.data(), id: reservation.id }, reservationClass: _r, loading: false, booking: this.props.booking, paymentamount: _r.getPaymentStatus(this.props.occurency.format('YYYY-MM-DD')).unpaid, note: '', saving: false})
      this.updateProfile(_r.getFirstPayerSimple().id)
    })
  }

  componentDidMount() {
    console.log('ReservationDetails componentDidMount', this.props.id)
    if (!this.props.id) return console.log('No reservation id')
    this.fetchReservation()
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      console.log('ReservationDetails componentDidUpdate', this.props.id)
      this.setState({ loading: true }, this.fetchReservation)
    }
  }

  savePayment = (type, sum) => {
    console.log('savePayment', this.state.paymentamount, this.state.note, sum, type)
    let __amount = Math.round(this.state.paymentamount * 100)
    if (sum) {
      const _sum = parseFloat(sum.replace(',', '.')) || 0
      __amount = Math.round(_sum * 100)
    }
    const payment = {
      type,
      typelabel: paymenttypes.find((x) => x.value === type).label,
      amount: __amount,
      occurency: moment.utc(this.state.booking.start).format('YYYY-MM-DD')
    }
    if (this.state.note && this.state.note.length > 0) payment.note = this.state.note
    this.setState({ saving: true })
    this.props.firebase.addPayment(this.state.booking.id, payment).then((result) => {
      console.log('Payment saved', result)
      if (result.error) return alert('Payment failed')
      this.fetchReservation(true)
    })
  }

  handleSavedSaldoPayment = () => {
    this.setState({ showSaldopaymentDialog: false, saving: false, showStoragepaymentDialog: false})
    this.fetchReservation(true)
  }

  saveSaldoPayment = (type, sum) => {
    console.log('saveSaldoPayment', this.state.paymentamount, this.state.note, sum, type)
    let __amount = Math.round(this.state.paymentamount * 100)
    if (sum) {
      if (typeof sum === 'string') {
        const _sum = parseFloat(sum.replace(',', '.')) || 0
        __amount = Math.round(_sum * 100)
      } else {
        __amount = Math.round(sum * 100)
      }
    }
    const payment = {
      type,
      typelabel: type === 'saldo' ? 'Saldomaksu' : 'Tuntipankki',
      amount: __amount,
      occurency: moment.utc(this.state.booking.start).format('YYYY-MM-DD')
    }
    if (this.state.note && this.state.note.length > 0) payment.note = this.state.note
    this.setState({ saving: true })
    this.props.firebase.addSaldoPayment(payment).then((result) => {
      console.log('Payment saved', result)
      if (result.error) return alert('Payment failed')
      this.fetchReservation(true)
    })
  }

  updateProfile = async (id) => {
    const customerAttributes = PROFILE_FIELDS.map((f) => this.props.firebase.customerid + '.' + f)
    let profile = { firstname: '', lastname: '', email: '', phone: '', streetaddress: '', yob: '', zip: '', city: '', ...customerAttributes }
    try {
      profile = await algoliaprofile.getObject(id, {
        attributesToRetrieve: ['firstname', 'lastname', 'email', 'phone', 'streetaddress', 'yob', 'zip', 'city', ...customerAttributes]
      })
      const _profile = { ...profile, id: profile.objectID }
      for (let profileField of PROFILE_FIELDS) {
        if (_profile[this.props.firebase.customerid] && _profile[this.props.firebase.customerid][profileField]) {
          _profile[profileField] = _profile[this.props.firebase.customerid][profileField]
          delete _profile[this.props.firebase.customerid][profileField]
        }
      }
      this.setState({ userprofile: _profile })
    } catch (error) {
      console.log(error)
      this.setState({ userprofile: null })
    }
  }

  toggleReservationModal = () => {
    this.setState({ reservationModalOpen: !this.state.reservationModalOpen })
  }

  openReservationModal = async () => {
    const start = moment.utc(this.state.reservation.resourcegroups[0].start)
    const end = moment.utc(this.state.reservation.resourcegroups[0].end)
    const selectedReservation = {
      id: this.state.reservation.id,
      start: moment.utc(this.props.occurency).hour(start.hour()).minutes(start.minutes()).format(),
      end: moment.utc(this.props.occurency).hour(end.hour()).minutes(end.minutes()).format(),
    }
    selectedReservation.data = { ...this.state.reservation }
    console.log('openReservationModal', selectedReservation)
    this.setState({ selectedReservationResource: selectedReservation }, this.toggleReservationModal)
  }

  render() {
    if (this.state.loading && !this.state.reservation.id) return (<div>Loading...</div>)
    const title = this.state.reservationClass ? this.state.reservationClass.getInvoicingTitle() : ''
    const person = this.state.reservationClass ? this.state.reservationClass.getFirstPayerSimple() : {}
    const _saldo = this.state.userprofile?.saldos?.default?.cash ? this.state.userprofile.saldos.default.cash / 100 : 0
    const saldo = _saldo ? (_saldo).toFixed(2).replace('.', ',') : 0
    const _storage = this.state.userprofile?.storage?.default?.cash ? this.state.userprofile.storage.default.cash / 100 : 0
    const storage = _storage ? (_storage).toFixed(2).replace('.', ',') : 0
    const paymentStatus = this.state.reservationClass ? this.state.reservationClass.getPaymentStatus(this.props.occurency.format('YYYY-MM-DD')) : { unpaid: 0, cost: 0 }
    const unpaid = paymentStatus.unpaid ? paymentStatus.unpaid : 0
    // const half = unpaid / 2
    // const quarter = unpaid / 4

    console.log('this.props.occurency', this.props.occurency)


    return (
      <div>
        <Typography variant="h5" component="h2">
          {title} 
        </Typography>
        <Typography variant="h6" component="h2">
          {person.firstname && person.lastname ? `(${person.firstname} ${person.lastname})` : null} {person.email ? `(${person.email})` : null} {person.phone ? `(${person.phone})` : null}
        </Typography>
        <Typography variant="h6" component="p">
          {unpaid} €
        </Typography>
        <Button onClick={this.openReservationModal}>Näytä varaus</Button>
        {saldo ? (<React.Fragment>
          <Divider />
          <Typography variant="body1" component="p">
            Saldoa {saldo} €
          </Typography>
          <Button onClick={() => this.setState({ showSaldopaymentDialog: true })}>Maksa saldolta</Button>
          {/* <Button onClick={() => this.saveSaldoPayment('saldo', _saldo >= unpaid ? unpaid : saldo)}>{_saldo >= unpaid ? 'Maksa saldolta kokonaan' : 'Maksa saldolta ' + saldo + ' €'}</Button>
          <Button onClick={() => this.saveSaldoPayment('saldo', _saldo >= half ? half : saldo)}>{_saldo >= half ? 'Maksa saldolta 1/2 ' + half + ' €' : 'Maksa saldolta ' + saldo + ' €'}</Button>
          <Button onClick={() => this.saveSaldoPayment('saldo', _saldo >= quarter ? quarter : saldo)}>{_saldo >= quarter ? 'Maksa saldolta 1/4 ' + quarter + ' €' : 'Maksa saldolta ' + saldo + ' €'}</Button> */}

          <Divider />
          </React.Fragment>): null}
        {this.state.reservationClass && !this.state.reservationClass.isRecurrent() && storage ? <React.Fragment>
          <Divider />
          <Typography variant="body" component="p">
            Pankissa {storage} €
          </Typography><Button onClick={() => this.setState({ showStoragepaymentDialog: true })}>Maksa tuntipankista</Button>
          </React.Fragment>
         : null}
        <Divider />
        <br />
        <TextField
          type="number"
          id="outlined-multiline-static"
          label="Summa"
          variant="outlined"
          style={{ backgroundColor: 'white' }}
          value={this.state.paymentamount}
          onChange={(e) => this.setState({ paymentamount: e.target.value })}
        /><br/>
        <TextField
          fullWidth
          type="text"
          id="outlined-multiline-static"
          label="Muistiinpano"
          variant="outlined"
          style={{ backgroundColor: 'white', marginTop: 10 }}
          value={this.state.note}
          onChange={(e) => this.setState({ note: e.target.value })}
        />

        <br />
        {paymenttypes.map((x) => <><Button key={x.value} onClick={() => this.savePayment(x.value)}>{x.label}</Button><br/></>)}
        <Divider />
        {/* <div>
          isrecurrent : {this.state.reservationClass ? <p>{JSON.stringify(this.state.reservationClass.isRecurrent())}</p>:null}
        </div> */}
        {/* <pre>{this.state.reservationClass.toJSON()}</pre>
        <pre>{JSON.stringify(this.state.booking, null, 2)}</pre>
        <pre>{JSON.stringify(this.state.occurency, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(this.state.userprofile, null, 2)}</pre>
        <pre>{JSON.stringify(this.state.booking, null, 2)}</pre> */}
        <Backdrop style={{ color: '#fff', zIndex: 10000 }} open={this.state.saving}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <SaldopaymentDialog
            open={this.state.showSaldopaymentDialog}
            onClose={() => this.setState({ showSaldopaymentDialog: false, saving: false })}
            onSave={this.handleSavedSaldoPayment}
            reservation={this.state.reservation}
            occurency={this.props.occurency}
          />
        <StoragepaymentDialog
            open={this.state.showStoragepaymentDialog}
            onClose={() => this.setState({ showStoragepaymentDialog: false, saving: false })}
            onSave={this.handleSavedSaldoPayment}
            reservation={this.state.reservation}
            occurency={this.props.occurency}
        />
        <ReservationModal
          isOpen={this.state.reservationModalOpen}
          toggle={this.toggleReservationModal}
          resource={this.state.selectedReservationResource}
          tags={null}
          { ...this.props }
        />
      </div>
    )
  }

}

export default withFirebase(ReservationDetails)