import React, { useRef, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { Button, Col, Row } from 'reactstrap'

export default function TinyEditor({ onClose, value, onChange, disableSave }) {
  const editorRef = useRef(null)
  const [content, setContent] = React.useState(value || '')

  const onChangeContent = () => {
    if (editorRef.current) {
      onClose(editorRef.current.getContent())
    }
  }

  useEffect(() => {
    if (onChange) {
      const delayDebounceFn = setTimeout(() => {
        if(editorRef.current) {
          onChange(editorRef.current.getContent())
        }
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  const handleEditorChange = (content, editor) => {
    console.log('Content was updated:', content)
    setContent(content)
  }

  return (
    <>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={content || ''} 
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'preview',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
        onEditorChange={handleEditorChange}
      />
      {!disableSave && (
        <Row className='justify-content-end'>
          <Col xs='auto'>
            <Button color='primary' onClick={onChangeContent}>
              {'Valmis'}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}
