import React, { Component } from 'react'
import moment from 'moment-timezone'
import { withFirebase } from '../../components/Fb'
import { TextField, Backdrop } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, Row, Col } from 'reactstrap'
import ReservationDetails from '../Details/ReservationDetails'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

// eslint-disable-next-line no-extend-native
Array.prototype.sortClosestFirst = function (timewindow = 90) {
  const now = moment().add(moment().tz('Europe/Helsinki').utcOffset(), 'minutes').unix()
  const closest = this.filter((a) => Math.abs(now - a.unixstamp) < 60 * timewindow)
  const others = this.filter((a) => Math.abs(now - a.unixstamp) >= 60 * timewindow)
  return closest.concat(others)
}

const getPercentage = (cost, percent) => {
  return Math.floor(cost * (percent / 100))
}

class SimplePosList extends Component {
  constructor(props) {
    console.log('SimplePosList', props)
    super(props)
    this.state = {
      loading: true,
      reservations: [],
      selectedDate: props.date,
      filter: '',
      saving: false,
      filters: { recurrencies: true },
    }
  }

  componentDidMount() {
    this.props.firebase.getResourses().then((resources) => {
      this.setState({ resources: resources.docs.map((doc) => doc.data()) }, this.listenBookings)
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      this.setState({ selectedDate: this.props.date, loading: true, selectedRow: null }, this.listenBookings)
    }
  }

  flattenBookings = (bookings) => {
    // console.log('flattenBookings', bookings, this.state.resources)
    if (!bookings) return []
    if (!this.state.resources) return []
    const _b = bookings.filter((x) => !(x.id.startsWith('ph-') || x.id.startsWith('placeholder-')))
    const _flattened = _b.map((x) => {
      console.log(x)
      let _court = this.state.resources.find((y) => y.id === x.resourceId)
      const courtname = _court ? _court.displayName : ''
      return {
        id: x.id,
        start: moment.utc(x.start).format('HH:mm'),
        end: moment.utc(x.end).format('HH:mm'),
        court: x.resourceId,
        courtname: courtname,
        isrecurring: x.data.isrecurring,
        cost: x.paymentstatus.cost / 100,
        paid: x.paymentstatus.cost - x.paymentstatus.paid > 0 ? 'Ei' : 'Kyllä',
        title: x.title,
        occurency: { date: moment.utc(x.start).format('YYYY-MM-DD') },
        original: { ...x },
      }
    })
    return _flattened
  }

  filterData = () => {
    if (this.state.filters && !this.state.filters.recurrencies) {
      const _raw = this.state.rawReservations.filter((r) => !r.data.isrecurring)
      this.setState({ loading: false, filtered: _raw })      
    } else {
      this.setState({ loading: false, filtered: this.state.rawReservations })
    }
  }

  formatBooking = (booking) => {
    if (!booking) return null
    const _court = this.state.resources.find((y) => y.id === booking.resourceId)
    const courtname = _court ? _court.displayName : ''
    const unixstamp = moment.utc(booking.start).unix()
    const unpaid = booking.paymentstatus
      ? Math.round(booking.paymentstatus.cost) - Math.round(booking.paymentstatus.paid)
      : 0
    return { ...booking, courtname, unixstamp, unpaid }
  }

  filterBookings = (bookings) => {
    if (!bookings) return null
    /*const _b = */
    return bookings
      .filter((b) => {
        const ph = !(b.id.startsWith('ph-') || b.id.startsWith('placeholder-'))
        const active = b.data.state !== 'cancelled' && b.data.state !== 'onsale'
        const paid = b.paymentstatus && Math.round(b.paymentstatus.cost) - Math.round(b.paymentstatus.paid) <= 0
        return ph && active && !paid
      })
      .map((b) => this.formatBooking(b))
      .sort((a, b) => a.unixstamp - b.unixstamp)
      .sortClosestFirst()
    // return sortClosestFirst(_b)
  }

  listenBookings = () => {
    if (!this.state.selectedDate) return
    const date = moment(this.state.selectedDate)
    if (date.isValid()) {
      if (date.year() < 2030 && date.year() > 2020) {
        if (this.bookingsRef) {
          this.setState({ shards: null })
          this.unsubscribeBookings()
        }
        // sharded
        this.bookingsRef = this.props.firebase.listenBookingIndex(
          // moment.utc(this.state.selectedDate, 'YYYY-MM-DD').format('YYYYMMDD')
          this.state.selectedDate.format('YYYYMMDD')
        )
        this.unsubscribeBookings = this.bookingsRef.onSnapshot((snap) => {
          const combinedBookings = /*this.state.rawReservations ||*/ []
          const shards = this.state.shards || {}
          snap.forEach((doc) => {
            shards[doc.id] = Object.keys(doc.data()).map((key) => {
              return { ...doc.data()[key], id: key }
            })
            combinedBookings.push(
              ...Object.keys(doc.data()).map((key) => {
                return { ...doc.data()[key], id: key }
              })
            )
          })
          const bookings = this.filterBookings(combinedBookings)
          this.setState({ rawReservations: bookings }, this.filterData)
        })
        // ---sharded
      }
    }
  }

  selectRow = (id) => {
    if (this.selectRow && this.state.selectedRow === id) {
      this.setState({ selectedRow: null })
    } else {
      this.setState({ selectedRow: id })
    }
  }

  savePayment = (reservationid, percent, e) => {
    e.stopPropagation()
    console.log('savePayment', reservationid, percent)
    const booking = this.state.rawReservations.find((x) => x.id === reservationid)
    const amount = getPercentage(booking.paymentstatus.cost, percent) //Math.floor(booking.paymentstatus.cost * (percent / 100))
    const payment = {
      type: 'cash',
      typelabel: 'Käteinen',
      amount: amount,
      occurency: moment.utc(booking.start).format('YYYY-MM-DD')
    }
    this.setState({ saving: true })
    this.props.firebase.addPayment(reservationid, payment).then((result) => {
      console.log('Payment saved', result)
      if (result.error) return alert('Payment failed')
      this.setState({ saving: false, selectedRow: null })
    })
  }

  handleFilterChange = (e) => {
    const filters = this.state.filters
    filters[e.target.name] = e.target.checked
    this.setState({ filters }, this.filterData)
  }

  render() {
    if (this.state.loading) return <div>Loading...</div>
    let filtered = this.state.filtered
    if (this.state.filter && this.state.filter.length > 2 && this.state.filtered) {
      filtered = this.state.filtered.filter((x) => {
        return x.title.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1
      })
    }
    let bgcolor = 'white'
    return (
      <React.Fragment>
        <Row>
          <Col>
        <TextField
          variant='outlined'
          size='small'
          label='Hae otsikolla'
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => this.setState({ filter: e.target.value })}
          value={this.state.filter}
          style={{ marginTop: 20, marginBottom: 20, backgroundColor: 'white'}}
            />
          <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.filters.recurrencies}
                  onChange={this.handleFilterChange}
                  name='recurrencies'
                  color='primary'
                />
              }
              label='Vakiovuorot'
              style={{ marginLeft: 20, marginTop: 20 }}
                />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <table style={{ borderStyle: 'solid', borderWidth: 1, width: '100%' }}>
              <tbody>
                {filtered &&
                  filtered.map((x) => {
                    if (this.state.selectedRow && this.state.selectedRow === x.id) {
                      bgcolor = 'lightblue'
                    } else {
                      bgcolor = 'white'
                    }
                    const fullPaymentOk = x.paymentstatus && x.paymentstatus.paid === 0
                    const halfPaymentOk = x.paymentstatus && x.paymentstatus.paid <= (x.paymentstatus.cost / 2)
                    const quarterPaymentOk = x.paymentstatus && x.paymentstatus.paid <= (x.paymentstatus.cost * 0.75)
                    return (
                      <tr key={x.id} onClick={() => this.selectRow(x.id)} style={{ borderBottom: '1px solid lightgray'}}>
                        <td style={{ backgroundColor: bgcolor, paddingLeft: 10  }}>{moment.utc(x.start).format('HH:mm')}</td>
                        <td style={{ backgroundColor: bgcolor }}>{moment.utc(x.end).format('HH:mm')}</td>
                        <td style={{ backgroundColor: bgcolor }}>{x.courtname}</td>
                        <td style={{ backgroundColor: bgcolor }}>{x.title}</td>
                        <td style={{ backgroundColor: bgcolor }}>
                          {x.unpaid - Math.round(x.paymentstatus.cost) !== 0 ? (x.paymentstatus.cost / 100) + ' €': ''}
                        </td>
                        <td style={{ backgroundColor: bgcolor }}>{x.unpaid / 100} €</td>
                        <td style={{ backgroundColor: bgcolor }}>{x.data.isrecurring ? 'Vakio' : 'Irto'}</td>
                        <td style={{ backgroundColor: bgcolor }}>{x.data.tags && x.data.tags.join(' ')}</td>
                        <td style={{ backgroundColor: bgcolor }}>
                          <Button color='success' size='sm' className='btn-round' disabled={!fullPaymentOk} onClick={(e) => this.savePayment(x.id, 100, e)}>
                            {(getPercentage(x.paymentstatus.cost, 100) / 100).toFixed(2)} €
                          </Button>
                        </td>
                        <td style={{ backgroundColor: bgcolor }}>
                          <Button color='success' size='sm' className='btn-round' disabled={!halfPaymentOk} onClick={(e) => this.savePayment(x.id, 50, e)}>
                            {(getPercentage(x.paymentstatus.cost, 50) / 100).toFixed(2)} € (1/2)
                          </Button>
                        </td>
                        <td style={{ backgroundColor: bgcolor }}>
                          <Button color='success' size='sm' className='btn-round' disabled={!quarterPaymentOk} onClick={(e) => this.savePayment(x.id, 25, e)}>
                            {(getPercentage(x.paymentstatus.cost, 25) / 100).toFixed(2)} € (1/4)
                          </Button>
                        </td>
                        {/* <td style={{ backgroundColor: bgcolor }}>{JSON.stringify(x.paymentstatus)}</td> */}
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </Col>
          <Col md={6}>{this.state.selectedRow ? <ReservationDetails id={this.state.selectedRow} booking={this.state.selectedRow ? this.state.rawReservations.find((x) => x.id === this.state.selectedRow) : null} occurency={this.state.selectedDate} onClose={() => this.setState({ selectedRow: null })} profile={this.props.profile} user={this.props.user} /> : null}</Col>
        </Row>
        <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={this.state.saving}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {/* <pre>{JSON.stringify(this.state.rawReservations, null, 2)}</pre> */}
      </React.Fragment>
    )
  }
}

export default withFirebase(SimplePosList)
