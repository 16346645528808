import React from 'react'
import { Col, Row, Button, ButtonGroup, Badge, Label, Spinner, Modal, ModalBody, Input } from 'reactstrap'
import TextField from '@material-ui/core/TextField'

import { withFirebase } from '../../components/Fb'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import ReservationModal from '../../components/Inputs/ReservationModal'
import moment from 'moment-timezone'
import 'moment/locale/fi'
import Alert from '@material-ui/lab/Alert'
// import Switch from 'react-bootstrap-switch'
// import { duration } from '@material-ui/core'
import { TinyColor } from '@ctrl/tinycolor'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'

const datenavidation = { cursor: 'pointer', margin: 10 }

// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
// import FirstPageIcon from '@material-ui/icons/FirstPage';
// import LastPageIcon from '@material-ui/icons/LastPage';
// import LanguageIcon from '@material-ui/icons/Language';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import { MotionPhotosOffTwoTone } from '@mui/icons-material'

// moment.tz.setDefault('Etc/UTC');
moment.tz.setDefault('Etc/GMT-3')
const localizer = momentLocalizer(moment)
// const localizer = momentLocalizer(moment_timezone)
const TIMEFORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

// const RECURRENT_COLOR = '#3d1c94'
const SHARE_COLOR = '#afa277'
const UNKNOWNSHARE_COLOR = '#e40668'
// const CANCELLED_COLOR = '#f05f40'
// const ONSALE_COLOR = '#f09440'
// const RESERVED_COLOR = '#e9f040'
// const HVS_COLOR = '#fdc400'
// const HEDMAN_COLOR = '#16ba64'

// const datenavidation = { cursor: 'pointer', margin: 10 }

// const ResourceHeaderContainer =
//   ({ lightsOnOff, lightStatus }) =>
//   (props) => {
//     // console.log('ResourceHeaderContainer')
//     // console.log(props)
//     return <ResourceHeader event={props} lightsOnOff={lightsOnOff} lightStatus={lightStatus} />
//     }

function EmptyToolbar() {
  return <div className='rbc-toolbar'></div>
}

// function ResourceHeader(props) {
//   let event = props.event
//   return (
//     <div>
//       <span style={{ marginRight: '20px' }} onClick={(e) => props.lightsOnOff(event.resource.id)}>
//         <FontAwesomeIcon icon={faLightbulb} color={props.lightStatus[event.resource.id] ? 'gold' : 'gray'} />
//       </span>
//       {event.label}
//     </div>
//   )
// }

function Event({ event, onPopoverButtonClick }) {
  let titleClass = ''
  let title = event.title
  // if (!title || title === '') {
  //   titleClass = 'importtitle'
  //   if (event.data?.persons?.payers) {
  //     let _p = event.data?.persons?.payers[0]
  //     if (_p.title) {
  //       title = title + '(k:' + _p.title + ')'
  //     }
  //   }
  // }
  // if (event.data?.persons?.owner && event.data.persons.owner[0]) {
  //   titleClass = 'importtitle'
  //   let _o = event.data?.persons?.owner[0]
  //   if (!_o.id) {
  //     if (_o.title) {
  //       title = title + '(o:' + _o.title + ')'
  //     } else if (_o.importtitle) {
  //       title = title + ' o:' + _o.importtitle
  //     }
  //   }
  // }
  if (event.data?.title) {
    title = event.data.title
  }

  let _backgroundStyle = {
    borderTopStyle: 'solid',
    borderWidth: 1,
    borderColor: 'lavender',
    width: '100%',
    backgroundColor: 'tomato',
    marginBottom: 2,
  }
  // let _s = { height: 4, width: '0%', backgroundColor: 'mediumseagreen' }
  // if (event.data && event.data.isrecurring) {
  //   _backgroundStyle.display = 'none'
  // }

  // if (event.paymentstatus && event.paymentstatus.cost > 0) {
  //   _s.width = (event.paymentstatus.paid / event.paymentstatus.cost) * 100 + '%'
  // } else if (event.paymentstatus && event.paymentstatus.cost === 0 && event.paymentstatus.paid === 0) {
  //   _s.width  = '100%'
  // }
  // if (event.data?.notitle) {
  //   _s.width = '0%'
  //   _backgroundStyle.width = '0%'
  // }

  return (
    <span>
      <div style={_backgroundStyle}>{/* <div style={_s}></div> */}</div>
      <div className={titleClass}>{title}</div>
    </span>
  )
}

const sortResources = (a, b) => {
  if (!a.order) a.order = 1000
  if (!b.order) b.order = 1000
  if (a.order < b.order) {
    return -1
  }
  if (a.order > b.order) {
    return 1
  }
  return 0
}

class RecurrentCourtsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.datenavigatorRef = React.createRef()
    this.filterRef = React.createRef()
    this.state = {
      loading: true,
      resources: null,
      // reservationAlert: null,
      lights: {},
      reservationModal: false,
      resourceModal: false,
      showcancelled: false,
      showonsale: false,
      selectedDate: moment.utc().startOf('isoWeek').format('YYYY-MM-DD'),
      selectedWeek: moment.utc().startOf('isoWeek').format('YYYY-MM-DD'),
      loadingModalOpen: false,
      events: [],
      calendarHeight: '75vh',
      copymode: false,
      copyids: [],
      copyModalOpen: false,
      copyStartDate: null,
      copyEndDate: null,
      copyOverrideMinus: 0,
      copyOverrideMinusPercent: 0,
      copyevents: [],
      copying: false
    }
  }

  updateFilters = () => {
    if (this.state.resources) {
      const _fResources = this.state.rawResources.filter((e) => {
        if (this.state.categoryFilter && this.state.categoryFilter !== 'all') {
          return this.state.categoryFilter === e.category
        }
        return true
      })
      _fResources.sort(sortResources)
      // console.log(_fResources)
      this.setState({
        resources: _fResources,
      })
    }
  }

  filterData = () => {
    let reservationsLoaded = false

    let events = []

    if (this.state.shards) reservationsLoaded = true
    if (this.state.shards) {
      const rawreservations = []
      // eslint-disable-next-line array-callback-return
      Object.keys(this.state.shards).map((shardkey) => {
        rawreservations.push(...this.state.shards[shardkey])
      })

      events = rawreservations
        .filter((x) => x?.data.isrecurring)
        .map((x) => {
          if (this.state.copyids && this.state.copyids.length > 0 && this.state.copyids.includes(x.id)) {
            x.copyselected = true
          } else {
            delete x.copyselected
          }
          return x
        })
        .filter((x) => {
          if (x.data?.state === 'cancelled' && x.data?.recurrencyState === 'cancelled') return false
          return true
        })
    }

    let _fResources = []
    if (reservationsLoaded) {
      _fResources = this.state.rawResources.filter((e) => {
        if (this.state.categoryFilter && this.state.categoryFilter !== 'all') {
          return this.state.categoryFilter === e.category
        }
        return true
      })
      _fResources.sort(sortResources)
    }
    this.setState(
      {
        loaded: reservationsLoaded, // && resourcesLoaded,
        events,
        resources: _fResources,
      },
      // console.timeEnd('filter')
    )
  }

  setResource = (event) => {
    this.setState({ input: JSON.parse(event.target.value) })
  }

  addNew = () => {
    this.setState({
      modalNotice: !this.state.modalNotice,
    })
  }

  toggleLights = (id) => {}

  hideLightsAlert = () => {}

  lightsOnOff = (id) => {}

  renderRows = () => {}

  moveDate = (days) => {
    let date
    if (days > 0) {
      date = moment.utc(this.state.selectedDate).add(days, 'days')
    } else if (days < 0) {
      date = moment.utc(this.state.selectedDate).subtract(-days, 'days')
    } else {
      date = moment.utc()
    }
    if (date.isValid()) {
      this.setState({ selectedDate: date.format('YYYY-MM-DD') }, this.listenBookings)
    }
  }

  moveWeek = (weeks) => {
    let date
    if (weeks > 0) {
      date = moment.utc(this.state.selectedWeek).add(weeks, 'weeks')
    } else if (weeks < 0) {
      date = moment.utc(this.state.selectedWeek).subtract(-weeks, 'weeks')
    } else {
      date = moment.utc()
    }
    if (date.isValid()) {
      this.setState({ selectedWeek: date.format('YYYY-MM-DD'), selectedDate: date.format('YYYY-MM-DD') }, this.listenBookings)
    }
  }

  handleDateChange = (event) => {
    const date = moment(event.target.value)
    if (date.isValid()) {
      this.setState({ selectedDate: event.target.value }, this.listenBookings)
    } else {
      // console.log('not valid date')
    }
    //(e) => this.setState({ selectedDate: e.target.value }, this.listenBookings)
  }

  setSelectedDate = (date) => {
    if (date.isValid()) {
      this.setState({ selectedDate: date.format('YYYY-MM-DD') }, this.listenBookings)
    }
  }


  createDownloadLink = () => {
    const rows = this.state.csvData.data.csv
    let csvContent = "data:text/csv;charset=utf-8," 
      + rows.join("\n")
    console.log(csvContent)
    let encodedUri = encodeURI(csvContent)
    let link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "recurrents.csv")
    document.body.appendChild(link) // Required for FF
    link.click()
  }
    


  downloadWeekRecurrings = async () => {
    this.setState({ loadingCsv: true, csvData: null })
    const startdate = moment(this.state.selectedWeek).startOf('isoWeek').format('YYYY-MM-DD')
    const enddate = moment(this.state.selectedWeek).endOf('isoWeek').format('YYYY-MM-DD')
    const csvData = await this.props.firebase.getRecurringsCsv(startdate, enddate)
    this.setState({ loadingCsv: false, csvData }, this.createDownloadLink)
  }

  getOpeningTimes = () => {
    // console.log('getOpeningTimes')
    this.props.firebase
      .getOpeningTimes()
      .get()
      .then((doc) => {
        if (doc.exists) {
          let days = doc.data().defaults.map((day) => {
            let morning = JSON.parse(
              JSON.stringify({
                allDay: false,
                resourceId: '',
                title: 'Dark',
                start: 'T01:00:00.000Z',
                data: { notitle: true },
              })
            )
            let evening = JSON.parse(
              JSON.stringify({
                allDay: false,
                resourceId: '',
                title: 'Dark',
                end: 'T23:59:59.000Z',
                data: { notitle: true },
              })
            )
            morning.end = 'T' + day.start.replace('.', ':') + ':00.000Z'
            evening.start = 'T' + day.end.replace('.', ':') + ':00.000Z'
            return { day: day.day, events: [morning, evening] }
          })
          // console.log('Set Openings')
          this.setState(
            {
              openingtimes: days,
            },
            this.filterData
          )
        }
      })
  }

  listenBookings = () => {
    // console.log('Listen bookings')
    const date = moment(this.state.selectedDate)
    if (date.isValid()) {
      if (date.year() < 2030 && date.year() > 2020) {
        if (this.bookingsRef) {
          this.setState({ shards: null })
          this.unsubscribeBookings()
        }
        // sharded
        this.bookingsRef = this.props.firebase.listenBookingIndex(
          moment.utc(this.state.selectedDate, 'YYYY-MM-DD').format('YYYYMMDD')
        )
        this.unsubscribeBookings = this.bookingsRef.onSnapshot((snap) => {
          const combinedBookings = this.state.rawReservations || []
          const shards = this.state.shards || {}
          snap.forEach((doc) => {
            shards[doc.id] = Object.keys(doc.data()).map((key) => {
              return { ...doc.data()[key], id: key }
            })
            combinedBookings.push(
              ...Object.keys(doc.data()).map((key) => {
                return { ...doc.data()[key], id: key }
              })
            )
          })
          this.setState({ loadingModalOpen: false, rawReservations: combinedBookings, shards }, this.filterData)
        })
        // ---sharded
      }
    }
  }

  toggleModalNotice = () => {
    this.setState({
      modalNotice: !this.state.modalNotice,
    })
  }

  changeFontSize = (bigger) => {
    let font = parseInt(window.getComputedStyle(document.getElementsByTagName('body')[0]).fontSize)
    if (bigger) {
      font++
    } else {
      font--
    }
    document.getElementsByTagName('body')[0].style.fontSize = font.toString() + 'px'
    document.querySelectorAll('.importtitle').forEach((element) => {
      element.style.fontSize = font.toString() + 'px'
    })
  }

  async componentDidMount() {
    this.unsubscribeResources = this.props.firebase.listenResourses().onSnapshot((snapshot) => {
      let _r = snapshot.docs.map((sh) => {
        // console.log(sh.data())
        return { ...sh.data(), id: sh.id }
      })
      this.setState({ rawResources: _r }, this.filterData)
    })
    this.listenBookings()
    const _cats = await this.props.firebase.getColorCategories().get()
    if (_cats.exists) {
      const categories = _cats.data().categories.filter((c) => c.enabled)
      const colors = { categories }
      const recurrentColor = categories.find((c) => c.type === 'reservationtype' && c.category === 'vakio')
      const onsaleColor = categories.find((c) => c.type === 'reservationtype' && c.category === 'onsale')
      const onetimeColor = categories.find((c) => c.type === 'reservationtype' && c.category === 'yksittäinen')

      this.setState({
        colorcategories: colors,
        recurrentColor: recurrentColor ? recurrentColor.color : null,
        onsaleColor: onsaleColor ? onsaleColor.color : null,
        onetimeColor: onetimeColor ? onetimeColor.color : null,
      })
    }
    if (this.filterRef && this.filterRef.current) {
      let calendarSpace = Math.round(
        ((window.innerHeight - this.filterRef.current.clientHeight - 62 - 30) / window.innerHeight) * 100
      )
      calendarSpace = calendarSpace > 80 ? 80 : calendarSpace
      this.setState({ calendarHeight: calendarSpace + 'vh' })
    }
  }

  componentWillUnmount() {
    this.unsubscribeResources()
    if (this.bookingsRef) {
      this.unsubscribeBookings()
      // this.bookingsRef.off('value', this.unsubscribeBookings)
    }
    if (this.freetimesRef) {
      this.freetimesRef.off('value', this.unsubscribeFreetimes)
    }
    if (this.infotextRef) {
      this.infotextRef.off('value', this.unsubscribeInfoTexts)
    }
  }

  toggleReservationModal = () => {
    if (this.state.reservationModal) {
      let _e = this.state.events.filter((e) => e.id !== 'placeholder')
      this.setState({
        reservationModal: !this.state.reservationModal,
        events: _e,
      })
    } else {
      this.setState({ reservationModal: !this.state.reservationModal })
    }
  }

  // toggleReservationAlert = () => {
  //   this.setState({ reservationAlert: !this.state.reservationAlert })
  // }

  clearState = () => {
    this.setState({
      selectedResource: null,
    })
  }

  setReservationProperty = (property, value) => {
    // console.log(property + ' => ' + value)
    let w = this.state.selectedResource ? this.state.selectedResource : {}
    w[property] = value
    this.setState({ selectedResource: w })
  }

  // reservationAlert = () => {
  //   return (
  //     <Alert color='danger' isOpen={this.state.reservationAlert} toggle={this.toggleReservationAlert} fade={true}>
  //       Vanhaa tapahtumaa ei voi muuttaa.
  //     </Alert>
  //   )
  // }

  reservationModal = () => {
    return (
      <ReservationModal
        isOpen={this.state.reservationModal}
        toggle={this.toggleReservationModal}
        resource={this.state.selectedResource}
        tags={
          this.state.colorcategories
            ? this.state.colorcategories.categories.filter((c) => c.type === 'tag' && c.enabled)
            : []
        }
        {...this.props}
        enableEdit={true}
      />
    )
  }

  toggleLoadingModal = () => {
    this.setState({ loadingModalOpen: !this.state.loadingModalOpen })
  }

  handleSelectEvent = async (event) => {
    if (this.state.copymode) {
      let _c = [...this.state.copyids]
      let _ce = [...this.state.copyevents]
      // let _e = [...this.state.events]
      if (this.state.copyids.includes(event.id)) {
        // remove
        _c = [...this.state.copyids].filter((d) => {
          return event.id !== d
        })
        _ce = [...this.state.copyevents].filter((d) => {
          return event.id !== d.id
        })
        // _e = [...this.state.events].map((j) => {
        //   if(event.id === j.id)
        //     delete j.copyselected
        //   return j
        // })
      } else {
        _c.push(event.id)
        _ce.push(event)
        // _e = [...this.state.events].map((d) => {
        //   if(event.id === d.id)
        //     d.copyselected = true
        //   return d
        // })
      }
      this.setState({ copyids: _c, copyevents: _ce }, this.filterData)
    } else {
      this.toggleLoadingModal()
      const reservation = await this.props.firebase.getReservation(event.id)
      let canOpen = true
      if (this.props.profile.role === 'PARTNER') {
        const ok = event?.data?.persons?.payers.find((p) => {
          if (p.contacts) {
            const isContact = p.contacts.find((x) => x.id === this.props.user.uid)
            return isContact ? true : false
          } else {
            return p.id === this.props.user.uid
          }
        })
        if (!ok) canOpen = false
      }
      if (reservation.exists && canOpen) {
        const selectedResource = JSON.parse(JSON.stringify(event))
        selectedResource.data = { ...reservation.data(), id: reservation.id }
        this.toggleLoadingModal()
        this.setState({ selectedResource }, this.toggleReservationModal)
      } else if (!canOpen) {
        alert('Ei oikeuksia avata')
        this.toggleLoadingModal()
      } else {
        alert('Varausta ei löydy')
        this.toggleLoadingModal()
      }
    }
  }

  openMenu = (event) => {}

  handleSelectSlot = (slotInfo) => {
    // console.log('handleSelectSlot')
    // console.log(slotInfo)
    let s = moment(slotInfo.start)
    let e = moment(slotInfo.end)
    if (e.diff(s, 'minutes') < 60) {
      e = moment(s).add(60, 'minutes')
    }
    this.setState({ selectedResource: null })
    let _ePlaceholder = {
      id: 'placeholder',
      start: s.add(180, 'minutes').toDate(),
      end: e.add(180, 'minutes').toDate(),
      resourceId: slotInfo.resourceId,
    }
    let newEvents = [...this.state.events]
    newEvents.push(_ePlaceholder)
    this.setState({ events: newEvents })
    this.setReservationProperty('start', s.format(TIMEFORMAT))
    this.setReservationProperty('end', e.format(TIMEFORMAT))
    this.setReservationProperty('resourceid', slotInfo.resourceId)
    this.toggleReservationModal()
  }

  updateFilter = (filter, status) => {
    let fss = {}
    if (filter === 'category') {
      fss = { categoryFilter: status }
    }
    this.setState(fss, this.updateFilters)
  }

  getDayFilters = () => {
    const today = moment.utc(this.state.selectedWeek)
    return ['maanantai', 'tiistai', 'keskiviikko', 'torstai', 'perjantai', 'lauantai', 'sunnuntai'].map(
      (day, index) => {
        const dayIndex = index + 1
        const move =
          dayIndex - today.isoWeekday() < 0 ? 7 + (dayIndex - today.isoWeekday()) : dayIndex - today.isoWeekday()
        const weekday = moment.utc(this.state.selectedWeek).add(move, 'days')
        return (
          <Button
            size={'xs'}
            key={index}
            className='btn-round'
            color='success'
            outline
            type='button'
            onClick={() => this.setSelectedDate(weekday)}
            active={moment.utc(this.state.selectedDate).isoWeekday() === weekday.isoWeekday()}
          >
            {day}
          </Button>
        )
      }
    )
  }

  getCategoryFilters = () => {
    if (this.state.rawResources) {
      let _cs = this.state.rawResources.map((e) => e.category)
      let _dc = [...new Set(_cs)]
      const _c = _dc.map((e) => {
        return (
          <Button
            size={'xs'}
            key={e}
            className='btn-round'
            color='info'
            outline
            type='button'
            onClick={() => this.updateFilter('category', e)}
            active={this.state.categoryFilter === e}
          >
            {e}
          </Button>
        )
      })
      _c.push(
        <Button
          size={'xs'}
          className='btn-round'
          key='typeall'
          color='info'
          outline
          type='button'
          onClick={() => this.updateFilter('category', 'all')}
          active={!this.state.categoryFilter || this.state.categoryFilter === 'all'}
        >
          Kaikki
        </Button>
      )
      return _c
    } else {
      return [
        <Button
          size={'xs'}
          key='typeall'
          className='btn-round'
          color='info'
          outline
          type='button'
          onClick={() => this.updateFilter('category', 'all')}
          active={!this.state.categoryFilter || this.state.categoryFilter === 'all'}
        >
          Kaikki
        </Button>,
      ]
    }
  }

  popoverButtonClickHandler = (event) => {
    // console.log(event)
  }

  // showMoved = (event) => {
  //   if (event.shiftKey) {
  //     this.setState({ showmoved: !this.state.showmoved }, this.filterData)
  //   }
  // }

  // setShowCancelled = (event) => {
  //   this.setState({ showcancelled: event.state.value }, this.filterData)
  // }

  // setShowOnsale = (event) => {
  //   this.setState({ showonsale: event.state.value }, this.filterData)
  // }

  endAccessor = (event) => {
    // return moment(event.end).toDate()
    return moment(event.end).subtract(180, 'minutes').toDate()
  }

  startAccessor = (event) => {
    return moment(event.start).subtract(180, 'minutes').toDate()
  }

  dateChanged = (event) => {
    this.setState({ selectedDate: moment.utc(event).format('YYYY-MM-DD') }, this.listenBookings)
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    let s = {
      color: 'white',
    }

    if (event.data) {
      if (event.data.isrecurring || event.data.parentid) {
        if (this.state.recurrentColor) {
          s.backgroundColor = `rgba(${this.state.recurrentColor.r}, ${this.state.recurrentColor.g}, ${this.state.recurrentColor.b}, ${this.state.recurrentColor.a})`
        }
      } else {
        if (this.state.onetimeColor && !event.data.parentid) {
          s.backgroundColor = `rgba(${this.state.onetimeColor.r}, ${this.state.onetimeColor.g}, ${this.state.onetimeColor.b}, ${this.state.onetimeColor.a})`
        }
      }
      // if (event.data.state === 'onsale' && this.state.onsaleColor) {
      //   s.backgroundColor = `rgba(${this.state.onsaleColor.r}, ${this.state.onsaleColor.g}, ${this.state.onsaleColor.b}, ${this.state.onsaleColor.a})`
      // }

      // if (event.data.parentid) {
      //   s.fontStyle = 'italic'
      // }

      // if (event.data.state === 'reserved') {
      //   s.backgroundColor = RESERVED_COLOR // greenyellow
      // }

      if (event.data.persons?.owner && event.data.persons.owner[0]) {
        // console.log(event.data.persons.owner)
        let _p = event.data.persons.owner[0]
        if (_p.businessId || _p.email || _p.name) {
          s.backgroundColor = SHARE_COLOR
        } else if (_p.title) {
          s.backgroundColor = UNKNOWNSHARE_COLOR
        }
      }

      if (event.data.persons?.payers && this.state.colorcategories) {
        let _p = event.data.persons.payers[0]
        if (_p.email) {
          let userColor = this.state.colorcategories.categories.find(
            (c) => c.type === 'user' && _p.email.indexOf(c.category) > -1
          )
          if (userColor) {
            s.backgroundColor = `rgba(${userColor.color.r}, ${userColor.color.g}, ${userColor.color.b}, ${userColor.color.a})`
          }
        }

        if (event.data.tags) {
          let tagColor = this.state.colorcategories.categories.find(
            (c) => c.type === 'tag' && event.data.tags.includes(c.category)
          )
          if (tagColor) {
            s.backgroundColor = `rgba(${tagColor.color.r}, ${tagColor.color.g}, ${tagColor.color.b}, ${tagColor.color.a})`
          }
        }

        // && _p.email.indexOf('hvstennis.fi') > -1) {
        //   s.backgroundColor = HVS_COLOR
        // }
        // if (_p.email && _p.email.indexOf('tennishedman.fi') > -1) {
        //   s.backgroundColor = HEDMAN_COLOR
        // }
      }
      if (event.data.state === 'cancelled') {
        // s.backgroundColor = CANCELLED_COLOR // tomato
      } else if (event.data.state === 'onsale') {
        // s.fontStyle = 'italic'
        // s.borderStyle = 'dashed'
        // s.backgroundClip = 'padding-box'
        // if (this.state.onsaleColor) {
        //   s.backgroundColor = `rgba(${this.state.onsaleColor.r}, ${this.state.onsaleColor.g}, ${this.state.onsaleColor.b}, ${this.state.onsaleColor.a})`
        // } else {
        //   s.backgroundColor = `rgb(244,243,239)`
        // }
      }

      if (event.data.state === 'open') {
        s.backgroundColor = `rgb(0,0,0)`
      }

      const color1 = new TinyColor(s.backgroundColor)
      if (color1.isLight()) s.color = 'black'

      if (event.paymentstatus) {
        if (event.paymentstatus.cost === event.paymentstatus.paid && event.paymentstatus.cost > 0) {
          // s['text-decoration'] = 'underline'
        }
      }

      if (event.data.notitle) {
        s.color = `rgba(128, 128, 128, 0.0)`
        s.backgroundColor = `rgba(128, 128, 128, 0.8)`
      }

      if (event?.copyselected) {
        // s.backgroundColor = `black`
        s.borderColor = `green`
        s.borderWidth = `3px`
        s.backgroundColor = `rgba(128, 128, 128, 0.8)`
      }
    }
    return {
      style: s,
    }
  }

  setCopyEndDate = (e) => {
    this.setState({ copyEndDate: e.target.value })
  }

  setCopyStartDate = (e) => {
    this.setState({ copyStartDate: e.target.value })
  }

  setCopyOverrideMinus = (e) => {
    this.setState({ copyOverrideMinus: e.target.value })
  }

  setCopyOverrideMinusPercent = (e) => {
    this.setState({ copyOverrideMinusPercent: e.target.value })
  }


  copyRows = async () => {
    this.setState({ copying: true })
    const response = await this.props.firebase.copyRecurrectsBatchAction(
      this.state.copyids,
      this.state.copyStartDate,
      this.state.copyEndDate,
      this.state.copyOverrideMinus,
      this.state.copyOverrideMinusPercent
    )
    console.log(response)
    if (response.error) {
      alert(response.message)      
      this.setState({ copying: false })
    } else {
      this.setState({ copying: false }, this.toggleCopyModal)
    }
  }

  copyModal = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={this.state.copyModalOpen}
        onClose={this.toggleCopyModal}
        aria-labelledby='max-width-dialog-title'
        style={{ zIndex: '1100' }}
      >
        <DialogTitle id='max-width-dialog-title'>Kopioi vuorot ajalle</DialogTitle>
        <DialogContent>
          <TextField
            id='recurstart'
            label='Alkupäivä'
            type='date'
            value={this.state.copyStartDate ? this.state.copyStartDate : ''}
            onChange={this.setCopyStartDate}
            InputLabelProps={{
              shrink: true,
            }}
          />{' '}
          <TextField
            id='recurstart'
            label='Loppupäivä'
            type='date'
            value={this.state.copyEndDate ? this.state.copyEndDate : ''}
            onChange={this.setCopyEndDate}
            InputLabelProps={{
              shrink: true,
            }}
          />{' '}
          <TextField
            id='recuroverrideminus'
            label='Alennus hinnastosta'
            type='number'
            value={this.state.copyOverrideMinus ? this.state.copyOverrideMinus : 0}
            onChange={this.setCopyOverrideMinus}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {' tai '}
          <TextField
            id='recuroverrideminuspercent'
            label='Alennus hinnastosta prosentteina'
            type='number'
            value={this.state.copyOverrideMinusPercent ? this.state.copyOverrideMinusPercent : 0}
            onChange={this.setCopyOverrideMinusPercent}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogContent>
          {this.state.copyevents.map((_r) => {
            return (
              <Row key={Math.random()} className='border-bottom'>
                <Col md='3' className='pt-1'>
                  {_r.title}
                </Col>
                <Col md='3' className='pt-1'>
                  {moment.utc(_r.start).format('dddd')}
                </Col>
                <Col md='2' className='pt-1'>
                  {moment.utc(_r.start).format('HH:mm')}
                </Col>
                <Col md='2' className='pt-1'>
                  {moment.utc(_r.end).format('HH:mm')}
                </Col>
                <Col md='2' className='pt-1'>
                  {this.state.resources.find((x) => x.id === _r.resourceId).displayName}
                </Col>
              </Row>
            )
          })}
        </DialogContent>

        <DialogActions>
          <Alert severity="error">Olethan muistanut asettaa hinnaston valitsemallesi ajalle.</Alert>
          <Button disabled={!(this.state.copyStartDate && this.state.copyEndDate)} onClick={this.copyRows} color='primary'>
            {this.state.copying ? <Spinner size={'sm'} /> : 'Kopioi'}
          </Button>
          <Button disabled={this.state.copying} onClick={this.toggleCopyModal} color='secondary'>
            Sulje
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  toggleCopyModal = () => {
    this.setState({ copyModalOpen: !this.state.copyModalOpen, copyStartDate: null, copyEndDate: null })
  }

  loadingModal = () => {
    return (
      <Modal size='sm' isOpen={this.state.loadingModalOpen} toggle={this.toggleLoadingModal} backdrop={'static'}>
        <ModalBody></ModalBody>
      </Modal>
    )
  }

  displayFree = (event) => {
    event.stopPropagation()
    if (event.ctrlKey && event.shiftKey) {
      this.listenFreeTimes()
    }
  }

  setCopyMode = () => {
    // let _e = [...this.state.events].map((j) => {
    //   delete j.copyselected
    //   return j
    // })
    this.setState({ copymode: !this.state.copymode, copyids: [], copyevents: [] }, this.filterData)
  }

  selectAll = () => {
    let _c = [...this.state.copyids]
    let _ce = [...this.state.copyevents]
    for (let e of this.state.events) {
      if (!this.state.copyids.includes(e.id)) _c.push(e.id)
      _ce.push(e)
    }
    this.setState({ copyids: _c, copyevents: _ce }, this.filterData)
  }

  render() {
    // const today = new Date()

    // if (this.state.loaded) {
    return (
      <>
        <div className='content'>
          <div ref={this.filterRef}>
            <Row>
              <Col md='8' /*className='d-flex justify-content-center'*/>
                <ButtonGroup className='flex-wrap'>{this.getCategoryFilters()}</ButtonGroup>
              </Col>
              <Col md='4'>
                {this.state.colorcategories
                  ? this.state.colorcategories.categories
                      .filter((c) => c.enabled)
                      .map((cat, i) => {
                        return (
                          <Badge
                            key={cat.label + '-' + i}
                            size='sm'
                            style={{
                              backgroundColor: `rgba(${cat.color.r}, ${cat.color.g}, ${cat.color.b}, ${cat.color.a})`,
                            }}
                          >
                            {cat.label}
                          </Badge>
                        )
                      })
                  : null}
              </Col>
            </Row>
            <Row>
              <Col md='6' className='align-middle'>
                 <KeyboardArrowLeftIcon onClick={() => this.moveWeek(-1)} style={datenavidation} />
                  <strong style={{ textTransform: 'capitalize' }}>
                    Viikko {moment.utc(this.state.selectedDate).format('WW (dddd D.M.YYYY)')}
                  </strong>
                <KeyboardArrowRightIcon onClick={() => this.moveWeek(1)} style={datenavidation} />

              </Col>
              <Col md='6' className='text-right'>
                <Label check className='checklabel'>
                  <Input
                    type='checkbox'
                    className='checks'
                    style={{ opacity: 1, position: 'absolute', visibility: 'visible' }}
                    defaultValue={this.state.copymode}
                    onChange={this.setCopyMode}
                  />{' '}
                  Kopiointitila
                </Label>{' '}
                <Button size='sm' disabled={!this.state.copymode} onClick={this.selectAll}>
                  Valitse kaikki
                </Button>
                {this.state.copyids && this.state.copyids.length > 0 ? (
                  <div>
                    <Button size='sm' onClick={this.toggleCopyModal}>
                      {'Kopioi ' + this.state.copyids.length + ' valittua'}
                    </Button>
                  </div>
                ) : (
                  ''
                )}
                <Button size='sm' onClick={this.downloadWeekRecurrings} disabled={this.state.loadingCsv} >{this.state.loadingCsv ? <Spinner size='sm' /> : 'Lataa viikon vuorot exceliin'}</Button>
              </Col>
            </Row>
            <Row>
              <Col md='7'>
                <ButtonGroup className='flex-wrap'>{this.getDayFilters()}</ButtonGroup>
              </Col>
              <Col md='5' >
              <Alert severity='info' style={{ fontSize: 'small' }}>
                <strong>Info!</strong> Vakiovuorokalenterissa näkyy kaikki vuorot, jotka ovat alkamis- ja päättymispäivän mukaan voimassa. Kalenterissa näkyy myös myynnissä olevat ja perutut vuorot. Esimerkiksi kesken kauden irtisanotun vakiovuoron saa pois tästä kalenterista vaihtamalla vakiovuoron päättymispäiväksi viimeisen voimassaolleen vuoron päivän.
              </Alert>
            </Col>
            </Row>
          </div>
          <Row>
            <Col md='12'>
              <Calendar
                ref={this.myRef}
                style={{ height: this.state.calendarHeight }}
                selectable
                localizer={localizer}
                events={this.state.events ? this.state.events : []}
                startAccessor={this.startAccessor}
                endAccessor={this.endAccessor}
                onNavigate={this.dateChanged}
                views={['day']}
                step={30}
                min={new Date(1972, 0, 1, 6, 0, 0)}
                max={new Date(1972, 0, 1, 22, 59, 59)}
                defaultView={Views.DAY}
                scrollToTime={new Date(1972, 1, 1, 23)}
                date={moment.utc(this.state.selectedDate, 'YYYY-MM-DD').toDate()} // defaultDate={today}
                // onDoubleClickEvent={(event) => this.openMenu(event)}
                onSelectEvent={(event) => this.handleSelectEvent(event)}
                onSelectSlot={this.handleSelectSlot}
                onView={this.updateView}
                components={{
                  event: Event,
                  toolbar: EmptyToolbar,
                }}
                eventPropGetter={this.eventStyleGetter}
                resources={this.state.resources}
                resourceIdAccessor='id'
                resourceTitleAccessor='displayName'
                // formats={{ eventTimeRangeFormat: () => null }}
              />
            </Col>
          </Row>
          {this.reservationModal()}
          {this.loadingModal()}
          {this.copyModal()}
        </div>
        <div><pre>{this.state.csvData ? JSON.stringify(this.state.csvData) : ''}</pre></div>
      </>
    )
  }
}

export default withFirebase(RecurrentCourtsContainer)
