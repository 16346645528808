// import React from 'react'
// import CustomerPersonContainer from './views/admin/CustomerPersonContainer'
import CustomerPersonSearchContainer from './views/admin/CustomerPersonBoxContainer'
import CustomerUserContainer from './views/admin/CustomerUserContainer'
import CustomerOrganisationContainer from './views/admin/CustomerOrganisationSearchContainer'
import AdminCourtsContainer from './views/admin/CourtsContainer'
import CashierCourtsContainer from './views/cashiers/CourtsContainer'
import MainContainer from './views/cashiers/MainContainer'
import PersonsContrainer from './views/cashiers/PersonsContrainer'
import ResourcesContainer from './views/admin/ResourcesContainer'
import ActivitiesContainer from './views/admin/ActivitiesContainer'
import MembershipsContainer from './views/admin/MembershipsContainer'
import ContractsContainer from './views/admin/ContractsContainer'

import ConfigOpeningTimes from './views/admin/ConfigOpeningTimes'
import ConfigResourceCategoriesContainer from './views/admin/ConfigResourceCategoriesContainer'
// import ConfigResourceConfigsContainer from './views/admin/ConfigResourceConfigsContainer'
import ConfigColorsContainer from './views/admin/ConfigColorsContainer'
import ConfigInfoTexts from './views/admin/ConfigInfoTexts'
import ConfigTerms from './views/admin/ConfigTerms'

import PricingCalendarContainer from './views/admin/PricingCalendarContainer'
import MembershipPricingCalendarContainer from './views/admin/MembershipPricingCalendarContainer'
import GiftcodePricingCalendarContainer from './views/admin/GiftcodePricingCalendarContainer'
// import ShareholderCalendarContainer from './views/admin/ShareholderCalendarContainer'
import RecurrentCourtsContainer from './views/admin/RecurrentCourtsContainer'
import InvoicingContainer from './views/admin/InvoicingContainer'
import InvoicesContainer from './views/admin/InvoicesContainer'
import UnpaidContainer from './views/cashiers/UnpaidContainer'
import Logout from './views/Logout.jsx'
// import ConfigCustomerExtradata from './components/Config/CustomerExtradata'

// import NotInDemoContainer from './views/admin/NotInDemoContainer'
import ReportingContainer from './views/admin/ReportingContainer'
import ConfigMessageTemplates from './views/admin/ConfigMessageTemplates'
import GiftcodeContainer from './views/admin/GiftcodeContainer'
import ReservationExtrasContainer from './views/admin/ReservationExtrasContainer'
import MediaLibraryContainer from './views/admin/MediaLibraryContainer'
import BannersContainer from './views/admin/BannersContainer'
import TextBannersContainer from './views/admin/TextBannersContainer'
import AdminGuidesContainer from './views/admin/AdminGuidesContainer'
import CashierGuidesContainer from './views/cashiers/CashierGuidesContainer'
import ConfigRecurringContractTerms from './views/admin/ConfigRecurringContractTerms'
// import ConfigReminderTemplates from './views/admin/ConfigReminderTemplates'

//import AutomationMarketingContainer from './views/admin/AutomationMarketingContainer'

import BenefitCardsContainer from './views/admin/BenefitCardsContainer.jsx'
import ConfigCustomerViewability from './views/admin/ConfigCustomerViewability'

/*
import ShopContainer from './views/admin/ShopContainer'
import ProductsContainer from './views/admin/ProductsContainer'
import PartnerContainer from './views/admin/PartnerContainer'
import DevHistory from './views/admin/DevHistory'
import { BACKEND_RELEASES, RELEASE_HISTORY, MOBILE_RELEASES } from './readme'
*/
import TBDContainer from './views/admin/TBDContainer'
import LightsForce from './views/admin/LightsForce'
import SimplePosContainer from './views/admin/SimplePosContainer'
/*
import CustomersLiveContainer from './views/admin/CustomersLiveContainer'
import ConfigDash from './views/admin/ConfigDash'
import AdminCourtsDNDContainer from './views/admin/CourtsDNDContainer'
import UsersContainer from './views/admin/UsersContainer'
*/

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import RepeatIcon from '@material-ui/icons/Repeat'
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import GavelIcon from '@material-ui/icons/Gavel';
import EuroIcon from '@material-ui/icons/Euro';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DoneIcon from '@material-ui/icons/Done';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import TodayIcon from '@material-ui/icons/Today';
import ScheduleIcon from '@material-ui/icons/Schedule';
import BuildIcon from '@material-ui/icons/Build';
import TuneIcon from '@material-ui/icons/Tune';
import ApartmentIcon from '@material-ui/icons/Apartment';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
// import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';

import ProvidersContainer from './views/admin/ProvidersContainer.jsx'
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined'
import ShopProductsContainer from './views/admin/ShopProductsContainer.jsx'
import ProshopContainer from './components/Proshop/ProshopContainer.jsx'
import RecurringSalesContainer from './views/admin/RecurringSalesContainer.jsx'
import RecurringSalePricingCalendarContainer from './views/admin/RecurringSalePricingCalendarContainer.jsx'
// import MembershipListsContainer from './views/admin/MembershipListsContainer.jsx'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import BuildingMaintenanceContainer from './views/admin/BuildingMaintenanceContainer.jsx'
import ReservationBenefitCardsContainer from './views/admin/ReservationBenefitCardsContainer.jsx'
import ValueCardsContainer from './views/admin/ValueCardsContainer.jsx'
import ReservationBenefitCardCalendarContainer from './views/admin/ReservationBenefitCardCalendarContainer.jsx'
import BuildingMaintenanceAdminContainer from './components/Partners/BuildingMaintenanceAdminContainer.jsx'
import BuildingMaintenanceProductsContainer from './components/Partners/BuildingMaintenanceProductsContainer.jsx'
import BuildingMaintenanceTicketsContainer from './components/Maintenance/BuildingMaintenanceTicketsContainer.jsx'
import BuildingMaintenanceTicketsSettingsContainer from './components/Maintenance/BuildingMaintenanceTicketsSettingsContainer.jsx'
import TarmoIcon from './components/Partners/TarmoIcon.js'
import ProshopSettingsContainer from './components/Proshop/ProshopSettingsContainer.jsx'
// import WeekPlannerCourtsContainer from './views/admin/WeekPlannerCourtsContainer.jsx'


const PRODUCT_PILOT_CUSTOMERS = [
  'ramu-Tq22jQlBPgbsiJ26bNxg',
  'testilanmailapelikeskus-sgaVLykN',
  'tali-2GjtFLost8pDf0dWwmNc',
  'kerttula-asfji2300203',
  'nokiatennis-1tmwvtS3',
  'evs-4FD8m7rCa7wOuCADvzZZ',
  'valkeakoskentennistuki-22MbYNtH'
]

const PROSHOP_PILOT_CUSTOMERS = [
  'ramu-Tq22jQlBPgbsiJ26bNxg',
  'testilanmailapelikeskus-sgaVLykN',
  // 'monniklubi-8duqSkYj'
]


const routes = [
  {
    path: '/calendar',
    name: 'Kalenteri',
    icon: 'nc-icon nc-layout-11',
    component: AdminCourtsContainer,
    layout: '/admin',
  },
  // {
  //   path: '/teamweekplanner',
  //   name: 'Viikkosuunnittelu',
  //   icon: 'nc-icon nc-layout-11',
  //   component: WeekPlannerCourtsContainer,
  //   layout: '/admin',
  // },
  {
    path: '/activities',
    name: 'Aktiviteetit/Tapahtumat',
    icon: 'nc-icon nc-spaceship',
    component: ActivitiesContainer,
    layout: '/admin',
    betatext: '',
    beta: true,
  },
  // {
  //   path: '/dndcalendar',
  //   name: 'DND',
  //   icon: 'nc-icon nc-layout-11',
  //   component: AdminCourtsDNDContainer,
  //   layout: '/admin',
  // },
  // {
  //   path: '/customers',
  //   collapse: true,
  //   name: 'Asiakkaat',
  //   icon: 'nc-icon nc-single-02',
  //   state: 'customersCollapse',
  //   views: [
  {
    path: '/customers/persons',
    name: 'Henkilöt',
    icon: 'nc-icon nc-circle-10',
    component: CustomerPersonSearchContainer,
    layout: '/admin',
  },
  {
    path: '/customers/organizations',
    name: 'Yritykset',
    icon: 'nc-icon nc-bank',
    component: CustomerOrganisationContainer,
    layout: '/admin',
  },
  {
    path: '/recurrents',
    name: 'Vakiovuorot',
    icon: 'nc-icon nc-layout-11',
    component: RecurrentCourtsContainer,
    layout: '/admin',
  },
  {
    path: '/recurring',
    name: 'Vakiot',
    collapse: true,
    icon: 'nc-icon nc-layout-11',
    iconcomponent: RepeatIcon,
    state: 'recurringCollapse',
    layout: '/admin',
    views: [
      {
        path: '/recurring/reservations',
        name: 'Vuorot',
        icon: 'nc-icon nc-layout-11',
        component: RecurrentCourtsContainer,
        iconcomponent: DateRangeIcon,
        layout: '/admin',
      },
      {
        path: '/recurring/sales',
        name: 'Myyntikampanjat',
        icon: 'nc-icon nc-paper',
        component: RecurringSalesContainer,
        iconcomponent: NewReleasesIcon,
        layout: '/admin',
      },
      {
        path: '/recurring/pricing',
        name: 'Myynnin hinnastot',
        icon: 'nc-icon nc-paper',
        iconcomponent: EuroIcon,
        component: RecurringSalePricingCalendarContainer,
        layout: '/admin',
      },
      {
        path: '/recurring/confirmation',
        name: 'Ostot/Vahvistukset',
        icon: 'nc-icon nc-paper',
        component: TBDContainer,
        iconcomponent: DoneIcon,
        layout: '/admin',
      },
      {
        path: '/recurring/contracts',
        name: 'Sopimukset',
        icon: 'nc-icon nc-paper',
        component: ContractsContainer,
        iconcomponent: GavelIcon,
        layout: '/admin',
      },
      {
        path: '/recurring/contractterms',
        name: 'Sopimusehdot',
        icon: 'nc-icon nc-paper',
        component: ConfigRecurringContractTerms,
        layout: '/admin',
      },
    ],
    customers: ['ramu-Tq22jQlBPgbsiJ26bNxg', 'testilanmailapelikeskus-sgaVLykN']
  },
  {
    path: '/invoice',
    name: 'Laskutus',
    collapse: true,
    icon: 'nc-icon nc-money-coins',
    state: 'invoicingCollapse',
    layout: '/admin',
    views: [
      {
        path: '/invoicing',
        name: 'Laskutettavaa',
        icon: 'nc-icon nc-money-coins',
        component: InvoicingContainer,
        layout: '/admin',
      },
      {
        path: '/invoices',
        name: 'Laskut',
        icon: 'nc-icon nc-money-coins',
        component: InvoicesContainer,
        layout: '/admin',
      },
    ],
  },
  // {
  //   path: '/customers/live',
  //   name: 'Live',
  //   icon: 'nc-icon nc-sound-wave',
  //   component: CustomersLiveContainer,
  //   layout: '/admin',
  // },
  //   ],
  //   layout: '/admin',
  // },
  {
    path: '/users',
    name: 'Käyttäjät',
    icon: 'nc-icon nc-single-02',
    component: CustomerUserContainer,
    layout: '/admin',
  },
  {
    path: '/member',
    name: 'Jäsenyydet',
    collapse: true,
    icon: 'nc-icon nc-badge',
    state: 'memberCollapse',
    layout: '/admin',
    views: [
      {
        path: '/memberships',
        name: 'Jäsentyypit',
        icon: 'nc-icon nc-badge',
        component: MembershipsContainer,
        layout: '/admin',
      },
      {
        path: '/membershippricings',
        name: 'Jäsenhinnoittelu',
        icon: 'nc-icon nc-money-coins',
        component: MembershipPricingCalendarContainer,
        layout: '/admin',
      },
      // {
      //   path: '/membershiplists',
      //   name: 'Jäsenten listaus',
      //   icon: 'nc-icon nc-paper',
      //   component: MembershipListsContainer,
      //   layout: '/admin',
      // }
    ],
  },
  {
    path: '/giftcodes',
    name: 'Kampanjat',
    collapse: true,
    icon: 'nc-icon nc-tag-content',
    state: 'giftcodeCollapse',
    layout: '/admin',
    views: [
      {
        path: '/giftcodes',
        name: 'Etukoodit',
        icon: 'nc-icon nc-tag-content',
        component: GiftcodeContainer,
        layout: '/admin',
      },
      {
        path: '/giftcodeppricings',
        name: 'Etukoodien hinnoittelu',
        icon: 'nc-icon nc-money-coins',
        component: GiftcodePricingCalendarContainer,
        layout: '/admin',
      },
    ],
  },
  {
    path: '/reservationproducts',
    name: 'Lisätuotteet',
    icon: 'nc-icon nc-cart-simple',
    component: ReservationExtrasContainer,
    layout: '/admin',
    betatext: '',
    beta: true,
  },
  {
    path: '/shop/products',
    name: 'Tuotteet',
    icon: 'nc-icon nc-bag-16',
    component: ShopProductsContainer,
    layout: '/admin',
    betatext: '',
    beta: true,
    customers: PRODUCT_PILOT_CUSTOMERS
  },
  {
    path: '/proshop',
    name: 'Proshop',
    collapse: true,
    icon: 'nc-icon nc-shop',
    layout: '/admin',
    betatext: 'testi',
    beta: true,
    customers: PROSHOP_PILOT_CUSTOMERS,
    views: [
      {
        path: '/proshop/products',
        name: 'Tuotteet',
        icon: 'nc-icon nc-bag-16',
        component: ProshopContainer,
        betatext: 'testi',
        beta: true,
        layout: '/admin',
      },
      {
        path: '/proshop/orders',
        name: 'Tilaukset',
        icon: 'nc-icon nc-basket',
        component: TBDContainer,
        betatext: 'testi',
        beta: true,
        layout: '/admin',
      }
    ]
  },
  {
    path: '/cards',
    name: 'Kortit',
    collapse: true,
    icon: 'nc-icon nc-badge',
    iconcomponent: CreditCardIcon,
    layout: '/admin',
    state: 'cardsCollapse',
    views: [
      {
        path: '/benefitcards',
        name: 'Aktiviteetit',
        icon: 'nc-icon nc-badge',
        iconcomponent: FontDownloadIcon,
        component: BenefitCardsContainer,
        layout: '/admin',
      },
      {
        path: '/reservationcards',
        name: 'Varaukset',
        icon: 'nc-icon nc-badge',
        iconcomponent: TodayIcon,
        component: ReservationBenefitCardsContainer,
        layout: '/admin',
      },
      {
        path: '/rescardsschedules',
        name: 'Varauskorttien ajat',
        icon: 'nc-icon nc-badge',
        iconcomponent: ScheduleIcon,
        component: ReservationBenefitCardCalendarContainer,
        layout: '/admin',
      },
      // {
      //   path: '/valuecards',
      //   name: 'Arvo/lahjakortit',
      //   icon: 'nc-icon nc-money-coins',
      //   iconcomponent: CardGiftcardIcon,
      //   component: ValueCardsContainer,
      //   layout: '/admin',
      // }
    ],
  },
  {
    path: '/snappos',
    name: 'PikaKassa',
    iconcomponent: KeyboardIcon,
    component: SimplePosContainer,
    layout: '/admin',
    customers: ['ramu-Tq22jQlBPgbsiJ26bNxg', 'impivaarantenniskeskus-ejwHU2iX', 'testilanmailapelikeskus-sgaVLykN', 'lahdentennisjasquash-zLco5rzk', 'evs-4FD8m7rCa7wOuCADvzZZ', 'nallisport-20FA1VH3']
  },
  {
    path: '/reports',
    name: 'Raportit',
    icon: 'nc-icon nc-chart-pie-36',
    // component: NotInDemoContainer,
    component: ReportingContainer,
    layout: '/admin',
  },
  {
    path: '/buildingmainenance',
    name: 'Kiinteistöhuolto',
    icon: 'nc-icon nc-settings',
    collapse: true,
    iconcomponent: ApartmentIcon,
    layout: '/admin',
    state: 'buildingmaintenanceCollapse',
    views: [
      {
        path: '/tickets',
        name: 'Tiketit',
        icon: 'nc-icon nc-settings',
        component: BuildingMaintenanceTicketsContainer,
        iconcomponent: BuildIcon,
        layout: '/admin',
      }
      //,
      // {
      //   path: '/executions',
      //   name: 'Puhtaanapito',
      //   icon: 'nc-icon nc-settings',
      //   component: BuildingMaintenanceContainer,
      //   serviceIcon: TarmoIcon,
      //   iconcomponent: RestoreFromTrashIcon,
      //   layout: '/admin',
      // },
      // {
      //   path: '/buildingorders',
      //   name: 'Tavaratilaukset',
      //   icon: 'nc-icon nc-settings',
      //   serviceIcon: TarmoIcon,
      //   component: BuildingMaintenanceProductsContainer,
      //   iconcomponent: ShoppingBasketIcon,
      //   layout: '/admin',
      // },
      // {
      //   path: '/buildingadmin',
      //   name: 'Tehtävähallinta',
      //   icon: 'nc-icon nc-settings',
      //   serviceIcon: TarmoIcon,
      //   component: BuildingMaintenanceAdminContainer,
      //   iconcomponent: TuneIcon,
      //   layout: '/admin',
      // },
    ],
  },
  // {
  //   path: '/partners',
  //   name: 'Partnerit',
  //   icon: 'nc-icon nc-spaceship',
  //   component: PartnerContainer,
  //   layout: '/admin',
  // },
  // {
  //   path: '/shop',
  //   name: 'Shop',
  //   icon: 'nc-icon nc-shop',
  //   collapse: true,
  //   layout: '/admin',
  //   state: 'shopCollapse',
  //   views: [
  //     {
  //       path: '/shop/products',
  //       name: 'Tuotteet',
  //       icon: 'nc-icon nc-bag-16',
  //       component: ProductsContainer,
  //       properties: { releases: BACKEND_RELEASES },
  //       layout: '/admin',
  //     },
  //     {
  //       path: '/shop/orders',
  //       name: 'Tilaukset',
  //       icon: 'nc-icon nc-basket',
  //       component: TBDContainer,
  //       properties: { releases: BACKEND_RELEASES },
  //       layout: '/admin',
  //     }
  //   ]
  // },
  {
    path: '/notifications',
    name: 'Ilmoitukset',
    collapse: true,
    icon: 'nc-icon nc-bell-55',
    state: 'notificationsCollapse',
    layout: '/admin',
    views: [
      {
        path: '/banners',
        name: 'Bannerit',
        icon: 'nc-icon nc-image',
        component: BannersContainer,
        layout: '/admin',
      },
      {
        path: '/textbanners',
        name: 'Tekstibannerit',
        icon: 'nc-icon nc-paper',
        component: TextBannersContainer,
        layout: '/admin',
      },
      {
        path: '/infotexts',
        name: 'Kassa Infotekstit',
        icon: 'nc-icon nc-bell-55',
        component: ConfigInfoTexts,
        layout: '/admin',
      }]
    
  },
  {
    path: '/medialibrary',
    name: 'Mediakirjasto',
    icon: 'nc-icon nc-album-2',
    component: MediaLibraryContainer,
    layout: '/admin',
  },
  // {
  //   path: '/automationmarketing',
  //   name: 'Markkinoitointiautomaatio',
  //   icon: 'nc-icon nc-atom',
  //   component: AutomationMarketingContainer,
  //   layout: '/admin',
  //   betatext: '',
  //   beta: true,


  // },
  {
    path: '/leagues',
    name: 'Hallisarjat',
    icon: 'nc-icon nc-trophy',
    component: TBDContainer,
    layout: '/admin',
  },
  {
    path: '/customers/contracts',
    name: 'Sopimukset',
    icon: 'nc-icon nc-paper',
    component: ContractsContainer,
    layout: '/admin',
  },
  {
    path: '/config',
    name: 'Asetukset',
    collapse: true,
    icon: 'nc-icon nc-settings',
    iconcomponent: SettingsOutlinedIcon,
    state: 'configCollapse',
    views: [
      {
        path: '/resources',
        name: 'Resurssit',
        icon: 'nc-icon nc-single-copy-04',
        component: ResourcesContainer,
        layout: '/admin',
      },
      {
        path: '/pricing',
        name: 'Hinnoittelu',
        icon: 'nc-icon nc-money-coins',
        component: PricingCalendarContainer,
        layout: '/admin',
      },
      {
        path: '/terms',
        name: 'Varausehdot',
        icon: 'nc-icon nc-paper',
        component: ConfigTerms,
        layout: '/admin',
      },
      {
        path: '/config/customerviewability',
        name: 'Asiakkaan menut',
        icon: 'nc-icon nc-spaceship',
        component: ConfigCustomerViewability,
        layout: '/admin',
      },
      // {
      //   path: '/benefitcards',
      //   name: 'Sarjakortit',
      //   icon: 'nc-icon nc-badge',
      //   component: BenefitCardsContainer,
      //   layout: '/admin',
      // },
    
      // {
      //   path: '/shareholders',
      //   name: 'Osakaskartta',
      //   icon: 'nc-icon nc-paper',
      //   component: ShareholderCalendarContainer,
      //   layout: '/admin',
      // },
      {
        path: '/config/resourcecategories',
        name: 'Resurssiluokat',
        icon: 'nc-icon nc-bullet-list-67',
        component: ConfigResourceCategoriesContainer,
        layout: '/admin',
      },
      {
        path: '/config/openingtimes',
        name: 'Aukioloajat',
        icon: 'nc-icon nc-sun-fog-29',
        component: ConfigOpeningTimes,
        layout: '/admin',
      },
      {
        path: '/config/providers',
        name: 'Kumppanit',
        iconcomponent: BusinessOutlinedIcon,
        component: ProvidersContainer,
        layout: '/admin',
      },
      // }
      // {
      //   path: '/config/resourceconfigs',
      //   name: 'Resurssin asetukset',
      //   icon: 'nc-icon nc-settings-gear-65',
      //   component: ConfigResourceConfigsContainer,
      //   layout: '/admin',
      // },
      {
        path: '/config/colorconfigs',
        name: 'Kalenterin väritys',
        icon: 'nc-icon nc-tag-content',
        component: ConfigColorsContainer,
        layout: '/admin',
      },
      {
        path: '/config/confirmtemplates',
        name: 'Viestiasetukset',
        icon: 'nc-icon nc-single-copy-04',
        component: ConfigMessageTemplates,
        layout: '/admin',
      },
      {
        path: '/config/ticketadmin',
        name: 'Huollon tiketit',
        icon: 'nc-icon nc-settings',
        component: BuildingMaintenanceTicketsSettingsContainer,
        iconcomponent: TuneIcon,
        layout: '/admin',
      },
      {
        path: '/config/proshop',
        name: 'Proshop',
        icon: 'nc-icon nc-settings',
        component: ProshopSettingsContainer,
        iconcomponent: TuneIcon,
        layout: '/admin',
        customers: PROSHOP_PILOT_CUSTOMERS,
      },

      // {
      //   path: '/config/remindertemplates',
      //   name: 'Muistutusviestit',
      //   icon: 'nc-icon nc-single-copy-04',
      //   component: ConfigReminderTemplates,
      //   layout: '/admin',
      // },
      // {
      //   path: '/config/infotexts',
      //   name: 'Infoviestit',
      //   icon: 'nc-icon nc-single-copy-04',
      //   component: ConfigInfoTexts,
      //   layout: '/admin',
      // },
      {
        path: '/recurringcontractterms',
        name: 'Vakiovuoron sopimusehdot',
        icon: 'nc-icon nc-paper',
        component: ConfigRecurringContractTerms,
        layout: '/admin',
      },

      // {
      //   path: '/config/customerextras',
      //   name: 'Asiakaslisätiedot',
      //   icon: 'nc-icon nc-single-copy-04',
      //   component: ConfigCustomerExtradata,
      //   layout: '/admin'
      // }
    ],
    layout: '/admin',
  },
  // {
  //   path: '/groups',
  //   name: 'Ryhmät',
  //   icon: 'nc-icon nc-circle-10',
  //   component: TBDContainer,
  //   properties: { releases: BACKEND_RELEASES },
  //   layout: '/admin',
  // },
  // {
  //   path: '/happenings',
  //   name: 'Tapahtumat',
  //   icon: 'nc-icon nc-pin-3',
  //   component: TBDContainer,
  //   properties: { releases: BACKEND_RELEASES },
  //   layout: '/admin',
  // },
  // {
  //   path: '/billing',
  //   name: 'Laskutus',
  //   icon: 'nc-icon nc-paper',
  //   component: TBDContainer,
  //   properties: { releases: BACKEND_RELEASES },
  //   layout: '/admin',
  // },
  // {
  //   path: '/reports',
  //   name: 'Raportit',
  //   icon: 'nc-icon nc-chart-pie-36',
  //   component: TBDContainer,
  //   properties: { releases: BACKEND_RELEASES },
  //   layout: '/admin',
  // },
  // {
  //   path: '/messaging',
  //   name: 'Viestintä',
  //   icon: 'nc-icon nc-chat-33',
  //   component: TBDContainer,
  //   properties: { releases: BACKEND_RELEASES },
  //   layout: '/admin',
  // },
  // {
  //   path: '/main',
  //   name: 'Dev Timeline',
  //   icon: 'nc-icon nc-atom',
  //   component: DevHistory,
  //   properties: { releasehistory: RELEASE_HISTORY, backendreleases: BACKEND_RELEASES, mobilereleases: MOBILE_RELEASES },
  //   layout: '/admin',
  // },

  {
    path: '/courts',
    name: 'Kentät',
    icon: 'nc-icon nc-layout-11',
    component: MainContainer,
    layout: '/cashiers',
  },
  {
    path: '/customers',
    name: 'Asiakkaat',
    icon: 'nc-icon nc-single-02',
    component: PersonsContrainer,
    layout: '/cashiers',
  },
  {
    path: '/products',
    name: 'Tuotteet',
    icon: 'nc-icon nc-shop',
    component: CashierCourtsContainer,
    layout: '/cashiers',
  },
  {
    path: '/next',
    name: 'Seuraavat vapaat',
    icon: 'nc-icon nc-satisfied',
    component: CashierCourtsContainer,
    layout: '/cashiers',
  },
  {
    path: '/happenings',
    name: 'Tapahtumat',
    icon: 'nc-icon nc-key-25',
    component: CashierCourtsContainer,
    layout: '/cashiers',
  },
  {
    path: '/messages',
    name: 'Viestit',
    icon: 'nc-icon nc-chat-33',
    component: CashierCourtsContainer,
    layout: '/cashiers',
  },
  {
    path: '/lights',
    name: 'Valot',
    icon: 'nc-icon nc-bulb-63',
    component: LightsForce,
    layout: '/admin',
    customers: ['ramu-Tq22jQlBPgbsiJ26bNxg', 'lahdentennisjasquash-zLco5rzk']
  },
  {
    path: '/guides',
    name: 'Video-ohjeet',
    icon: 'nc-icon nc-pin-3',
    component: AdminGuidesContainer,
    layout: '/admin',
  },
  {
    path: '/logout',
    name: 'Logout',
    icon: 'nc-icon nc-button-power',
    component: Logout,
    layout: '/admin',
  },
  {
    path: '/calendar',
    name: 'Kalenteri',
    icon: 'nc-icon nc-layout-11',
    component: AdminCourtsContainer,
    layout: '/cashier',
  },
  {
    path: '/customers/persons',
    name: 'Henkilöt',
    icon: 'nc-icon nc-circle-10',
    component: CustomerPersonSearchContainer,
    layout: '/cashier',
  },
  {
    path: '/snappos',
    name: 'PikaKassa',
    iconcomponent: KeyboardIcon,
    component: SimplePosContainer,
    layout: '/cashier',
    customers: ['ramu-Tq22jQlBPgbsiJ26bNxg', 'impivaarantenniskeskus-ejwHU2iX', 'testilanmailapelikeskus-sgaVLykN']
  },
  {
    path: '/unpaid',
    name: 'Maksamattomat',
    icon: 'nc-icon nc-money-coins',
    component: UnpaidContainer,
    layout: '/cashier',
  },
  { 
    path: '/activities',
    name: 'Aktiviteetit/Tapahtumat',
    icon: 'nc-icon nc-spaceship',
    component: ActivitiesContainer,
    layout: '/cashier',
    betatext: '',
    beta: true,
  },
  {
    path: '/lights',
    name: 'Valot',
    icon: 'nc-icon nc-bulb-63',
    component: LightsForce,
    layout: '/cashier',
    customers: ['ramu-Tq22jQlBPgbsiJ26bNxg', 'lahdentennisjasquash-zLco5rzk']
  },
  {
    path: '/logout',
    name: 'Logout',
    icon: 'nc-icon nc-button-power',
    component: Logout,
    layout: '/cashier',
  },
  {
    path: '/guides',
    name: 'Video-ohjeet',
    icon: 'nc-icon nc-pin-3',
    component: CashierGuidesContainer,
    layout: '/cashier',
  },

  {
    path: '/calendar',
    name: 'Kalenteri',
    icon: 'nc-icon nc-layout-11',
    component: AdminCourtsContainer,
    layout: '/partner',
  },
  {
    path: '/logout',
    name: 'Logout',
    icon: 'nc-icon nc-button-power',
    component: Logout,
    layout: '/partner',
  },
  {
    path: '/calendar',
    name: 'Kalenteri',
    icon: 'nc-icon nc-layout-11',
    component: AdminCourtsContainer,
    layout: '/cooperative',
  },
  {
    path: '/logout',
    name: 'Logout',
    icon: 'nc-icon nc-button-power',
    component: Logout,
    layout: '/cooperative',
  },

]

const getProfileRoutes = (role) => {
  if (!role) role = 'ANON'
  return routes.filter((r) => r.layout === '/' + role.toLowerCase())
}

export default getProfileRoutes

/*

*/
