import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/analytics'
import 'firebase/storage';
import 'firebase/firebase-firestore'
import moment from 'moment'
import 'moment/locale/fi'
import FuseCache from './fusecache'
import { v4 as uuidv4 } from 'uuid'


const config = {
  apiKey: 'AIzaSyB_aqXQI6DuVinrjeKX7doEHWEd-dIN1mQ',
  authDomain: 'falcon-328a1.firebaseapp.com',
  databaseURL: 'https://falcon-328a1.firebaseio.com',
  projectId: 'falcon-328a1',
  storageBucket: 'falcon-328a1.appspot.com',
  messagingSenderId: '640167253821',
  appId: '1:640167253821:web:48fd942ebc8e0923a59754',
  measurementId: 'G-F1DTX4NT39',
}

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.database()
    this.firestore = app.firestore()
    this.analytics = app.analytics()
    this.functions = app.functions()
    this.customerid = null
    this.storage = app.storage()
    this.cache = new FuseCache()
    this.TaskEvent = app.storage.TaskEvent
  }

  getRandomId = () => this.firestore.collection('random').doc().id
  getTimestamp = (dateString) => app.firestore.Timestamp.fromDate(moment(dateString, 'DD.MM.YYYY').toDate())
  getTimestampFromMoment = (dateMoment) => app.firestore.Timestamp.fromDate(dateMoment.toDate())
  getToday = () => app.firestore.Timestamp.fromDate(new Date())
  getCustomerId = () => this.customerid

  getAdminAPICall = () => {
    this.functions.region = 'europe-west1'
    return this.functions.httpsCallable('adminapi-call')
  }



  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email)

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password)

  listenUiVersion = () => {
    // console.log('return ref')
    return this.db.ref('/manageui').orderByKey()
  }

  listenUiVersionVenue = (env) => {
    // console.log('return ref')
    return this.db.ref('/venuemanager/' + env).orderByKey()
  }

  listenLastread = () => {
    // console.log('listening lastread', '/venuemanagernews/' + this.auth.currentUser.uid)
    return this.db.ref('/venuemanagernews/' + this.auth.currentUser.uid).orderByKey()
  }

  updateLastread = () => {
    this.db.ref('/venuemanagernews/' + this.auth.currentUser.uid).set({ lastread: Date.now() })
  }


  listenModules = () => {
    if (this.customerid) {
      return this.firestore.collection('subscriptions').doc(this.customerid)
    } else {
      return null
    }
  }

  enableModule = async (module, price) => {
    try {
      this.functions.region = 'europe-west1'
      const enableModule = this.functions.httpsCallable('customer-modules')
      const response = await enableModule({ action: 'enable', module, price, cid: this.customerid })
      return response
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  disableModule = async (module) => {
    try {
      this.functions.region = 'europe-west1'
      const disableModule = this.functions.httpsCallable('customer-modules')
      const response = await disableModule({ action: 'disable', module, cid: this.customerid })
      return response
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  listenUnpaidInvoiceAlert = () => {
    console.log('listening unpaidInvoiceAlert', '/customerinvoices/' + this.customerid)
    if (this.customerid) {
      return this.firestore.collection('customerinvoices').doc(this.customerid)
    } else {
      return null
    }
  }


  // *** User API ***
  user = (uid) => this.firestore.collection('users').doc(uid)
  users = () => this.firestore.collection('users')

  logEvent = (eventName) => {
    // console.log('fb event')
    this.analytics.logEvent('goal_completion', { name: 'lever_puzzle' })
  }

  getCustomerRef = () => {
    return this.firestore.collection('customers').doc(this.customerid)
  }

  getCustomerReportRef = () => {
    return this.firestore.collection('stats').doc(this.customerid)
  }

  getCustomerProshopRef = () => {
    return this.firestore.collection('proshop').doc(this.customerid)
  }

  getCustomerBookingIndexRef = () => {
    return this.firestore.collection('indexes').doc('bookings').collection(this.customerid)
  }

  getCustomerBookingIndexingRef = () => {
    return this.firestore.collection('bookings').doc(this.customerid)
  }

  getCustomerMessageRef = () => {
    return this.firestore.collection('messages').doc(this.customerid).collection('active')
  }

  getRTDBCustomerRef = () => {
    return this.db.ref('customers/' + this.customerid)
  }

  updateUserRole = async (userid, role) => {
    try {
      this.functions.region = 'europe-west1'
      const updateRole = this.functions.httpsCallable('users-updateRole')
      const response = await updateRole({ userid, cid: this.customerid, role })
      return response
    } catch (error) {
      console.error(error)
      return {}
    }
  }


  /* Resource */

  // getResourceCategories = async () => {
  //   // sport
  //   return [
  //     { type: 'category', value: 'tennis', label: 'Tennis' },
  //     { type: 'category', value: 'sulkapallo', label: 'Sulkapallo' },
  //   ]
  // }

  createResource = async (resource) => {
    resource.creatorId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('resources').add(resource)
  }

  updateResource = async (resource) => {
    resource.updaterId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('resources').doc(resource.id).set(resource, { merge: true })
  }

  deleteResource = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('resources').doc(id).set(r, { merge: true })
  }

  getResourse = async (id) => {
    return await this.getCustomerRef().collection('resources').doc(id).get()
  }

  getResourses = async () => {
    let r = await this.getCustomerRef().collection('resources').get()
    return r
  }

  listenResourses = () => {
    return this.getCustomerRef().collection('resources').orderBy('displayName')
  }

  // createResourceCalendar = (resourcecalendar) => {}

  // updateResourceCalendar = (resourcecalendar) => {}

  // deleteResourceCalendar = (id) => {}

  // createResourcePricing = (resourcepricing) => {}

  // updateResourcePricing = (resourcepricing) => {}

  // deleteResourcePricing = (id) => {}

  /* Provider */
  createProvider = async (provider) => {
    provider.creatorId = this.auth.currentUser.uid
    if (!provider.code || provider.code === '') provider.code = provider.name.toLowerCase().replace(/\s/g, '')
    return await this.getCustomerRef().collection('provider').add(provider)
  }

  updateProvider = async (provider) => {
    provider.updaterId = this.auth.currentUser.uid
    if (!provider.code || provider.code === '') provider.code = provider.name.toLowerCase().replace(/\s/g, '')
    return await this.getCustomerRef().collection('provider').doc(provider.id).set(provider, { merge: true })
  }

  deleteProvider = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('provider').doc(id).set(r, { merge: true })
  }

  getProvider = async (id) => {
    return await this.getCustomerRef().collection('provider').doc(id).get()
  }

  getProviders = async () => {
    let r = await this.getCustomerRef().collection('provider').get()
    return r
  }


  /* Activity */

  createActivity = async (activity) => {
    activity.creatorId = this.auth.currentUser.uid
    activity.timestamp = this.getTimestamp(moment.utc(activity.start).format('DD.MM.YYYY'))
    activity.date = moment.utc(activity.start).format('YYYYMMDD')
    activity.created = this.getToday()
    return await this.getCustomerRef().collection('activities').add(activity)
  }

  createRecurrentActivity = async (activity) => {
    activity.creatorId = this.auth.currentUser.uid
    activity.timestamp = this.getTimestamp(moment.utc().format('DD.MM.YYYY'))
    activity.date = moment.utc().format('YYYYMMDD')
    activity.created = this.getToday()
    activity.collection = 'activities'
    activity.entryunit = 'minutes'
    return await this.getCustomerRef().collection('recurrents').add(activity)
  }


  updateActivity = async (activity) => {
    activity.updaterId = this.auth.currentUser.uid
    delete activity.participants
    delete activity.queue
    delete activity.payments
    delete activity.refunds
    activity.timestamp = this.getTimestamp(moment.utc(activity.start).format('DD.MM.YYYY'))
    activity.date = moment.utc(activity.start).format('YYYYMMDD')
    activity.updated = this.getToday()
    return await this.getCustomerRef().collection('activities').doc(activity.id).set(activity, { merge: true })
  }

  deleteActivity = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('activities').doc(id).set(r, { merge: true })
  }

  getActivity = async (id) => {
    return await this.getCustomerRef().collection('activities').doc(id).get()
  }

  getActivities = async () => {
    let r = await this.getCustomerRef().collection('activities').where('state', '!=', 'deleted').get()
    return r
  }

  addParticipantsToActivity = async (activityid, participants) => {
    try {
      this.functions.region = 'europe-west1'
      const addParticipants = this.functions.httpsCallable('activities-addParticipants')
      const customerid = this.customerid
      const response = await addParticipants({ customerid, activityid, users: participants })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  removeParticipantsFromActivity = async (activityid, participants) => {
    try {
      this.functions.region = 'europe-west1'
      const removeParticipants = this.functions.httpsCallable('activities-removeParticipants')
      const customerid = this.customerid
      const response = await removeParticipants({ customerid, activityid, userids: participants })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  addQueuedToActivity = async (activityid, queued) => {
    try {
      this.functions.region = 'europe-west1'
      const addQueued = this.functions.httpsCallable('activities-addQueue')
      const customerid = this.customerid
      const response = await addQueued({ customerid, activityid, users: queued })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  removeQueuedFromActivity = async (activityid, queued) => {
    try {
      this.functions.region = 'europe-west1'
      const removeQueued = this.functions.httpsCallable('activities-removeQueue')
      const customerid = this.customerid
      const response = await removeQueued({ customerid, activityid, userids: queued })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  moveParticipantToQueue = async (activityid, participant) => {
    try {
      this.functions.region = 'europe-west1'
      const moveBetweenQueueAndParticipants = this.functions.httpsCallable('activities-moveBetweenQueueAndParticipants')
      const customerid = this.customerid
      const response = await moveBetweenQueueAndParticipants({ customerid, activityid, direction: 'to_queue', userids: [participant] })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  moveParticipantFromQueue = async (activityid, participant) => {
    try {
      this.functions.region = 'europe-west1'
      const moveBetweenQueueAndParticipants = this.functions.httpsCallable('activities-moveBetweenQueueAndParticipants')
      const customerid = this.customerid
      const response = await moveBetweenQueueAndParticipants({ customerid, activityid, direction: 'to_participant', userids: [participant] })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  checkinoutParticipant = async (activityid, participant, inout) => {
    try {
      this.functions.region = 'europe-west1'
      const checkinoutParticipant = this.functions.httpsCallable('activities-checkInoutParticipant')
      const customerid = this.customerid
      const response = await checkinoutParticipant({ customerid, activityid, userid: participant, inout })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  /* Person */
  registerPerson = async (person) => {
    person.customerIds = [this.customerid]
    person._tags = [this.customerid]
    person.admincreated = true
    person.confirmed = false
    person.register = this.customerid
    Object.keys(person).forEach(key => {
      if (person[key] === undefined) {
        delete person[key];
      }
    });
    this.db.ref('reg').push(person)
  }

  // createUser = async (person) => {
  //   delete person.customers
  //   return await this.getCustomerRef().collection('persons').add(person)
  // }

  checkIfEmailExits = (email) => { return false }

  // addNewUser = async (person) => {
  //   // check if user account exists
  //   if(this.checkIfEmailExits(person.email))
  //   // if exist add user to customer with _tags and just remove first and lastname

  //   // if not exists register and add unconfirmed to customer 


  // }

  createPerson = async (person) => {
    delete person.customers
    const rand =
      'ag_' + this.getRandomId() + '_' + person.email.toLowerCase().replace(/\./g, '_dot_').replace(/#/g, '_hash_')
    person.admincreated = true
    person.confirmed = false
    person._tags = [this.customerid]
    try {
      return await this.getCustomerRef().collection('persons').doc(rand).set(person)
    } catch (error) {
      return Promise.resolve(error)
    }
  }

  updatePerson = async (person) => {
    delete person.customers
    person.updaterId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('persons').doc(person.id).set(person, { merge: true })
  }

  getPersonCData = async (id) => {
    return await this.getCustomerRef().collection('persons').doc(id).get()
  }

  deletePerson = async (id) => {
    let r = { id: id, state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.updatePerson(r)
  }

  listenPersons = () => {
    return this.getCustomerRef().collection('persons')
  }

  listenCustomerUsers = () => {
    return this.getCustomerRef().collection('persons').where('role', 'in', ['ADMIN', 'CASHIER', 'PARTNER', 'COOPERATIVE'])
  }

  listenPersonsRT = () => {
    // console.log('listening usercache/' + this.customerid)
    return this.db.ref('usercache/' + this.customerid)
  }

  getPersonReservations = (id) => {
    return this.firestore
      .collection('users')
      .doc(id)
      .collection('reservations')
      .where('locationid', '==', this.customerid)
  }

  getOrganisationReservations = async (id) => {
    return await this.getCustomerRef().collection('organisations').doc(id).collection('reservations').get()
  }

  /* personal admin stuff */
  getPersonVenueManagerConfigs = () => {
    return this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid)
      .collection('venuemanager')
      .doc(this.customerid).get()
  }

  savePersonVenueManagerConfigs = (config) => {
    // console.log('savePersonVenueManagerConfigs')
    return this.firestore
      .collection('users')
      .doc(this.auth.currentUser.uid)
      .collection('venuemanager')
      .doc(this.customerid).set(config, { merge: true })
  }

  /* Partner */
  createPartner = async (partner) => {
    partner.creatorId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('partners').add(partner)
  }

  updatePartner = async (partner) => {
    partner.updaterId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('partners').doc(partner.id).set(partner, { merge: true })
  }

  deletePartner = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('partners').doc(id).set(r, { merge: true })
  }

  getPartner = async (id) => {
    return await this.getCustomerRef().collection('partners').doc(id).get()
  }

  getPartners = async () => {
    let r = await this.getCustomerRef().collection('partners').get()
    return r
  }

  /* Membership */
  createMembership = async (membership) => {
    membership.creatorId = this.auth.currentUser.uid
    if (membership.variations) {
      const vs = membership.variations.map((v) => {
        if (!v.varid) v.varid = uuidv4()
        return v
      })
      membership.variations = vs
    }
    return await this.getCustomerRef().collection('membership').add(membership)
  }

  updateMembership = async (membership) => {
    membership.updaterId = this.auth.currentUser.uid
    if (membership.variations) {
      const vs = membership.variations.map((v) => {
        if (!v.varid) v.varid = uuidv4()
        return v
      })
      membership.variations = vs
    }
    return await this.getCustomerRef().collection('membership').doc(membership.id).set(membership, { merge: true })
  }

  deleteMembership = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('membership').doc(id).set(r, { merge: true })
  }

  getMembership = async (id) => {
    return await this.getCustomerRef().collection('membership').doc(id).get()
  }

  getMemberships = async () => {
    let r = await this.getCustomerRef().collection('membership').get()
    return r
  }

  createMembershipResourcePricing = async (config) => {
    config.type = 'membershippricing'
    return await this.getCustomerRef().collection('resourcepricings').add(config)
  }

  addMembershipResourcePricingSlot = async (slotConfig) => {
    if (!slotConfig.id) slotConfig.id = this.getRandomId()
    let config = { slots: {} }
    config.slots[slotConfig.id] = slotConfig
    config.type = 'membershippricing'
    return await this.getCustomerRef()
      .collection('resourcepricings')
      .doc(slotConfig.configid)
      .set(config, { merge: true })
  }

  getMembershipResourcePricings = async () => {
    return await this.getCustomerRef().collection('resourcepricings').where('type', '==', 'membershippricing').get()
  }

  getMembershipResourcePricing = async (id) => {
    return await this.getCustomerRef().collection('resourcepricings').doc(id).get()
  }

  deleteMembershipResourcePricings = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('resourcepricings').doc(id).set(r, { merge: true })
  }

  updateMembershipResourcePricing = async (config) => {
    config.type = 'membershippricing'
    return await this.getCustomerRef().collection('resourcepricings').doc(config.id).set(config, { merge: true })
  }

  /* BenefitCards */
  createBenefitCard = async (benefitcard) => {
    benefitcard.collection = 'activities'
    await this._createBenefitCard(benefitcard)
  }

  createReservationBenefitCard = async (benefitcard) => {
    benefitcard.collection = 'reservations'
    benefitcard.type = 'count'
    await this._createBenefitCard(benefitcard)
  }


  _createBenefitCard = async (benefitcard) => {
    benefitcard.creatorId = this.auth.currentUser.uid
    benefitcard.state = 'active'
    if (benefitcard.price) {
      benefitcard.price = Math.round(benefitcard.price * 100)
    }
    return await this.getCustomerRef().collection('benefitcards').add(benefitcard)
  }

  updateBenefitCard = async (benefitcard) => {
    benefitcard.collection = 'activities'
    await this._updateBenefitCard(benefitcard)
  }

  updateReservationBenefitCard = async (benefitcard) => {
    benefitcard.collection = 'reservations'
    benefitcard.type = 'count'
    await this._updateBenefitCard(benefitcard)
  }

  _updateBenefitCard = async (benefitcard) => {
    benefitcard.updaterId = this.auth.currentUser.uid
    benefitcard.price = Math.round(benefitcard.price * 100)
    return await this.getCustomerRef().collection('benefitcards').doc(benefitcard.id).set(benefitcard, { merge: true })
  }

  deleteBenefitCard = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('benefitcards').doc(id).set(r, { merge: true })
  }

  getBenefitCard = async (id) => {
    return await this.getCustomerRef().collection('benefitcards').doc(id).get()
  }

  getBenefitCards = async () => {
    let r = await this.getCustomerRef().collection('benefitcards').where('state', '!=', 'deleted').get()
    return r
  }


  createReservationBenefitCardTiming = async (config) => {
    config.type = 'reservationbenefitcard'
    return await this.getCustomerRef().collection('timings').add(config)
  }

  addReservationBenefitCardTimingSlot = async (slotConfig) => {
    if (!slotConfig.id) slotConfig.id = this.getRandomId()
    let config = { slots: {} }
    config.slots[slotConfig.id] = slotConfig
    config.type = 'reservationbenefitcard'
    return await this.getCustomerRef()
      .collection('timings')
      .doc(slotConfig.configid)
      .set(config, { merge: true })
  }

  getReservationBenefitCardTimings = async () => {
    return await this.getCustomerRef().collection('timings').where('type', '==', 'reservationbenefitcard').get()
  }

  getReservationBenefitCardTiming = async (id) => {
    return await this.getCustomerRef().collection('timings').doc(id).get()
  }

  deleteReservationBenefitCardTiming = async (id) => {
    let r = { state: 'deleted', creatorId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('timings').doc(id).set(r, { merge: true })
  }

  updateReservationBenefitCardTiming = async (config) => {
    config.type = 'reservationbenefitcard'
    return await this.getCustomerRef().collection('timings').doc(config.id).set(config, { merge: true })
  }


  /* ValueCards */

  createValueCard = async (valuecard) => {
    valuecard.creatorId = this.auth.currentUser.uid
    valuecard.state = 'active'
    valuecard.price = Math.round(valuecard.price * 100)
    return await this.getCustomerRef().collection('valuecards').add(valuecard)
  }

  updateValueCard = async (valuecard) => {
    valuecard.updaterId = this.auth.currentUser.uid
    valuecard.price = Math.round(valuecard.price * 100)
    return await this.getCustomerRef().collection('valuecards').doc(valuecard.id).set(valuecard, { merge: true })
  }

  deleteValueCard = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('valuecards').doc(id).set(r, { merge: true })
  }

  getValueCard = async (id) => {
    return await this.getCustomerRef().collection('valuecards').doc(id).get()
  }

  getValueCards = async () => {
    let r = await this.getCustomerRef().collection('valuecards').where('state', '!=', 'deleted').get()
    return r
  }

  /* Giftcode */

  createGiftcode = async (giftcode) => {
    giftcode.creatorId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('giftcodes').add(giftcode)
  }

  updateGiftcode = async (giftcode) => {
    giftcode.updaterId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('giftcodes').doc(giftcode.id).set(giftcode, { merge: true })
  }

  deleteGiftcode = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('giftcodes').doc(id).set(r, { merge: true })
  }

  getGiftcode = async (id) => {
    return await this.getCustomerRef().collection('giftcodes').doc(id).get()
  }

  getGiftcodes = async () => {
    let r = await this.getCustomerRef().collection('giftcodes').get()
    return r
  }

  createGiftcodeResourcePricing = async (config) => {
    config.type = 'giftcodepricing'
    return await this.getCustomerRef().collection('resourcepricings').add(config)
  }

  addGiftcodeResourcePricingSlot = async (slotConfig) => {
    if (!slotConfig.id) slotConfig.id = this.getRandomId()
    let config = { slots: {} }
    config.slots[slotConfig.id] = slotConfig
    config.type = 'giftcodepricing'
    return await this.getCustomerRef()
      .collection('resourcepricings')
      .doc(slotConfig.configid)
      .set(config, { merge: true })
  }

  getGiftcodeResourcePricings = async () => {
    return await this.getCustomerRef().collection('resourcepricings').where('type', '==', 'giftcodepricing').get()
  }

  getGiftcodeResourcePricing = async (id) => {
    return await this.getCustomerRef().collection('resourcepricings').doc(id).get()
  }

  deleteGiftcodeResourcePricings = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('resourcepricings').doc(id).set(r, { merge: true })
  }

  updateGiftcodeResourcePricing = async (config) => {
    config.type = 'giftcodepricing'
    return await this.getCustomerRef().collection('resourcepricings').doc(config.id).set(config, { merge: true })
  }


  /* Contract */
  createContract = async (contract) => {
    contract.creatorId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('contract').add(contract)
  }

  updateContract = async (contract) => {
    contract.updaterId = this.auth.currentUser.uid
    delete contract.downloadUrl
    return await this.getCustomerRef().collection('contract').doc(contract.id).set(contract, { merge: true })
  }

  deleteContract = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('contract').doc(id).set(r, { merge: true })
  }

  getContract = async (id) => {
    return await this.getCustomerRef().collection('contract').doc(id).get()
  }

  getContracts = async () => {
    let r = await this.getCustomerRef().collection('contract').get()
    return r
  }

  /* Reporting */
  getReportData = async (period) => {
    return await this.getCustomerReportRef().collection(period + 'bookings').get()
  }

  getReport = async (startdate, enddate, report, starttime, endtime) => {
    try {
      this.functions.region = 'europe-west1'
      const callable = this.functions.httpsCallable('reports-get')
      const response = await callable({ startdate, enddate, report, customerid: this.customerid, starttime, endtime })
      return response
    } catch (error) {
      console.error(error)
      return {}
    }
  }


  /* Invoices */
  listenInvoicingsRt = () => {
    return this.getRTDBCustomerRef().child('invoicing')
  }

  listenInvoicings = () => {
    return this.getCustomerRef().collection('invoicing')
  }

  getInvoicings = async () => {
    return await this.getCustomerRef().collection('invoicing').get()
  }

  getUserInvoicings = async (userid) => {
    return await this.getCustomerRef().collection('invoicing').doc(userid).get()
  }


  listenInvoices = () => {
    return this.getCustomerRef().collection('invoices')
  }

  getInvoices = async () => {
    return await this.getCustomerRef().collection('invoices').get()
  }

  getInvoicesByDates = async (startdate, enddate) => {
    if (!startdate || !enddate) return await this.getInvoices()
    const startTimestamp = this.getTimestampFromMoment(startdate)
    const endTimestamp = this.getTimestampFromMoment(enddate)
    console.log('getInvoicesByDates', startTimestamp, endTimestamp)
    return await this.getCustomerRef()
      .collection('invoices')
      .where('billingdateAsTimestamp', '>=', startTimestamp)
      .where('billingdateAsTimestamp', '<=', endTimestamp)
      .get()
  }

  getUserInvoices = async (userid) => {
    return await this.getCustomerRef().collection('invoices').doc(userid).get()
  }


  /* Message */
  createMessage = async (message) => {
    message.creatorId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('messages').add(message)
  }

  sendMessage = async (body, subject, recipient, userid, tags) => {
    return await this.getCustomerMessageRef().add({
      body,
      subject,
      recipient,
      channel: 'email',
      state: 'processing',
      timestamp: app.firestore.FieldValue.serverTimestamp(),
      userid,
      tags,
    })
  }

  sendMessageCall = async (body, subject, recipient) => {
    try {
      this.functions.region = 'europe-west1'
      const sendMessage = this.functions.httpsCallable('messaging-sendMessage')
      const response = await sendMessage({ body, subject, recipient, channel: 'email' })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  sendMessages = async (recipientids, subject, body, html, tags) => {
    try {
      this.functions.region = 'europe-west1'
      const sendMessages = this.functions.httpsCallable('messaging-sendMessages')
      const response = await sendMessages({ subject, body, html, recipientids, cid: this.customerid, tags })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }
  /* Group */
  createGroup = (group) => { }

  updateGroup = (group) => { }

  deleteGroup = (id) => { }

  /* Product */



  createProduct = async (product) => {
    product.source = 'internal'
    product.creatorId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('products').add(product)
  }

  updateProduct = async (product) => {
    if (product.source === 'internal') {
      product.updaterId = this.auth.currentUser.uid
      // console.log('updateProduct', product, product.id)
      return await this.getCustomerRef().collection('products').doc(product.id).set(product, { merge: true })
    } else {
      product.error = 'Please edit product in webstore/cashier'
      return product
    }
  }

  deleteProduct = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('products').doc(id).set(r, { merge: true })
  }

  getProduct = async (id) => {
    return await this.getCustomerRef().collection('products').doc(id).get()
  }

  getProducts = async () => {
    let r = await this.getCustomerRef().collection('products').where('state', '!=', 'deleted').get()
    return r
  }

  createReservationExtra = async (reservationExtra) => {
    reservationExtra.source = 'internal'
    reservationExtra.state = 'active'
    reservationExtra.creatorId = this.auth.currentUser.uid
    return await this.createProduct(reservationExtra)
  }

  updateReservationExtra = async (reservationExtra) => {
    return await this.updateProduct(reservationExtra)
  }

  deleteReservationExtra = async (id) => {
    return await this.deleteProduct(id)
  }

  getReservationExtra = async (id) => {
    return await this.getProduct(id)
  }

  getReservationExtras = async () => {
    return await this.getProducts()
  }

  getExtrasSchedules = async () => {
    return await this.getSchedules('product')
  }

  createExtrasSchedule = async (schedule) => {
    return await this.createSchedule(schedule, 'product')
  }

  updateExtrasSchedule = async (schedule) => {
    return await this.updateSchedule(schedule)
  }

  deleteExtrasSchedule = async (id) => {
    return await this.deleteSchedule(id)
  }

  /* ShopProduct */

  createShopProduct = async (product) => {
    product.source = 'internal'
    product.creatorId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('shopproducts').add(product)
  }

  updateShopProduct = async (product) => {
    product.updaterId = this.auth.currentUser.uid
    // console.log('updateProduct', product, product.id)
    return await this.getCustomerRef().collection('shopproducts').doc(product.id).set(product, { merge: true })
  }

  deleteShopProduct = async (id) => {
    let r = { state: 'deleted', creatorId: this.auth.currentUser.uid, updaterId: this.auth.currentUser.uid, updated: app.firestore.FieldValue.serverTimestamp() }
    return await this.getCustomerRef().collection('shopproducts').doc(id).set(r, { merge: true })
  }

  getShopProduct = async (id) => {
    return await this.getCustomerRef().collection('shopproducts').doc(id).get()
  }

  getShopProducts = async () => {
    let r = await this.getCustomerRef().collection('shopproducts')/*.where('state', '!=', 'deleted')*/.get()
    return r
  }

  /* ProshopProduct */

  createProshopProduct = async (product) => {
    product.source = 'internal'
    product.creatorId = this.auth.currentUser.uid
    return await this.getCustomerProshopRef().collection('products').add(product)
  }

  updateProshopProduct = async (product) => {
    product.updaterId = this.auth.currentUser.uid
    // console.log('updateProduct', product, product.id)
    return await this.getCustomerProshopRef().collection('products').doc(product.id).set(product, { merge: true })
  }

  deleteProshopProduct = async (id) => {
    let r = { state: 'deleted', creatorId: this.auth.currentUser.uid, updaterId: this.auth.currentUser.uid, updated: app.firestore.FieldValue.serverTimestamp() }
    return await this.getCustomerProshopRef().collection('products').doc(id).set(r, { merge: true })
  }

  getProshopProduct = async (id) => {
    return await this.getCustomerProshopRef().collection('products').doc(id).get()
  }

  getProshopProducts = async () => {
    let r = await this.getCustomerProshopRef().collection('products')/*.where('state', '!=', 'deleted')*/.get()
    return r
  }

  /* ProductCategory */

  createProductCategory = async (category) => {
    category.creatorId = this.auth.currentUser.uid
    return await this.getCustomerProshopRef().collection('categories').add(category)
  }

  updateProductCategory = async (category) => {
    category.updaterId = this.auth.currentUser.uid
    return await this.getCustomerProshopRef().collection('categories').doc(category.id).set(category, { merge: true })
  } 

  deleteProductCategory = async (id) => {
    let r = { state: 'deleted', creatorId: this.auth.currentUser.uid }
    return await this.getCustomerProshopRef().collection('categories').doc(id).set(r, { merge: true })
  }

  getProductCategory = async (id) => {
    return await this.getCustomerProshopRef().collection('categories').doc(id).get()
  }

  getProductCategories = async () => {
    let r = await this.getCustomerProshopRef().collection('categories').get()
    return r
  }

  /* ProductBrand */

  createProductBrand = async (brand) => {
    brand.creatorId = this.auth.currentUser.uid
    return await this.getCustomerProshopRef().collection('brands').add(brand)
  }

  updateProductBrand = async (brand) => {
    brand.updaterId = this.auth.currentUser.uid
    return await this.getCustomerProshopRef().collection('brands').doc(brand.id).set(brand, { merge: true })
  } 

  deleteProductBrand = async (id) => {
    let r = { state: 'deleted', creatorId: this.auth.currentUser.uid }
    return await this.getCustomerProshopRef().collection('brands').doc(id).set(r, { merge: true })
  }

  getProductBrand = async (id) => {
    return await this.getCustomerProshopRef().collection('brands').doc(id).get()
  }

  getProductBrands = async () => {
    let r = await this.getCustomerProshopRef().collection('brands').get()
    return r
  }

  /* ProshopOrder */

  addProshopOrder = async (order, payer) => {
    order.creatorId = this.auth.currentUser.uid
    order.created = this.getToday()
    order.payer = { id: payer.id }
    order.collection = 'products'
    order.type = 'toinvoice' // inquiery, 
    order.module = 'proshop'
    order.state = 'created' // created, confirmed, paid, delivered, cancelled, deleted
    order.productid = order.id
    delete order.updaterId
    delete order.id
    return await this.getCustomerProshopRef().collection('orders').add(order)
  }

  removePersonProshopOrder = async (orderid) => {
    return await this.getCustomerProshopRef().collection('orders').doc(orderid).update({ state: 'deleted', updaterId: this.auth.currentUser.uid, updated: app.firestore.FieldValue.serverTimestamp() })
  }

  getProshopOrder = async (id) => {
    return await this.getCustomerProshopRef().collection('orders').doc(id).get()
  }

  getProshopOrders = async () => {
    let r = await this.getCustomerProshopRef().collection('orders').get()
    return r
  }

  

  /* ProductOrders */

  addProductOrder = async (order, payer) => {
    order.creatorId = this.auth.currentUser.uid
    order.created = this.getToday()
    order.payer = { id: payer.id }
    order.collection = 'products'
    order.state = 'active'
    order.productid = order.id
    delete order.updaterId
    delete order.id
    return await this.getCustomerRef().collection('productorders').add(order)
  }

  removePersonProductOrder = async (orderid) => {
    return await this.getCustomerRef().collection('productorders').doc(orderid).update({ state: 'deleted', updaterId: this.auth.currentUser.uid, updated: app.firestore.FieldValue.serverTimestamp() })
  }

  /* Schedules */
  createSchedule = async (schedule, type) => {
    schedule.type = type
    schedule.state = 'active'
    schedule.creatorId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('schedules').add(schedule)
  }

  updateSchedule = async (schedule) => {
    schedule.creatorId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('schedules').doc(schedule.id).set(schedule, { merge: true })
  }

  deleteSchedule = async (id) => {
    let r = { state: 'deleted', creatorId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('schedules').doc(id).set(r, { merge: true })
  }

  getSchedule = async (id) => {
    return await this.getCustomerRef().collection('schedules').doc(id).get()
  }

  getSchedules = async (type) => {
    let r = await this.getCustomerRef().collection('schedules').where('type', '==', type).get()
    return r
  }


  triggerUpdateReservationIndex = async (reservationid) => {
    try {
      this.functions.region = 'europe-west1'
      const triggerIndex = this.functions.httpsCallable('reservations-triggerindexv2')
      const status = await triggerIndex({ reservationid, customerid: this.customerid })
      return status
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  /* Reservation */
  createReservation = async (reservation) => {
    reservation.creatorId = this.auth.currentUser.uid
    reservation.updated = app.firestore.FieldValue.serverTimestamp()
    reservation.via = 'admin'
    try {
      const createdReservation = await this.getCustomerRef().collection('reservations').add(reservation)
      await this.triggerUpdateReservationIndex(createdReservation.id)
      return createdReservation
    } catch (error) {
      return { error: error.message }
    }
  }

  updateReservation = async (reservation) => {
    reservation.updaterId = this.auth.currentUser.uid
    // reservation.t = 2
    reservation.updated = app.firestore.FieldValue.serverTimestamp()
    // console.time('updateReservation')
    try {
      const createdReservation = await this.getCustomerRef()
        .collection('reservations')
        .doc(reservation.id)
        .set(reservation, { merge: true })
      // console.timeEnd('updateReservation')

      // console.time('triggerUpdateReservationIndex')
      await this.triggerUpdateReservationIndex(reservation.id)
      // console.timeEnd('triggerUpdateReservationIndex')

      return createdReservation
    } catch (error) {
      return { error: error.message }
    }
  }

  _updateReservationState = async (id, state) => {
    let r = { state: state }
    r.creatorId = this.auth.currentUser.uid
    r.updaterId = this.auth.currentUser.uid
    r.updated = app.firestore.FieldValue.serverTimestamp()
    return await this.getCustomerRef().collection('reservations').doc(id).set(r, { merge: true })
  }

  _cancelReservation = async (id) => {
    let r = { state: 'cancelled' }
    r.creatorId = this.auth.currentUser.uid
    r.updaterId = this.auth.currentUser.uid
    r.updated = app.firestore.FieldValue.serverTimestamp()
    try {
      const updatedReservation = await this.getCustomerRef()
        .collection('reservations')
        .doc(r.id)
        .set(r, { merge: true })
      await this.triggerUpdateReservationIndex(id)
      return updatedReservation
    } catch (error) {
      return { error: error.message }
    }
    // return await this.getCustomerRef()
    //   .collection('reservations')
    //   .doc(id)
    //   .set(r, { merge: true })
  }

  cancelMultiReservation = async (occurencies, subject, body) => {
    try {
      this.functions.region = 'europe-west1'
      const cancelAdminMultiReservations = this.functions.httpsCallable('reservations-cancelAdminMultiReservations')
      const status = await cancelAdminMultiReservations({ occurencies, subject, body, cid: this.customerid })
      return status
    } catch (error) {
      return { error: error.message }
    }
  }

  setOnSaleReservation = async (id) => {
    return this._updateReservationState(id, 'onsale')
  }

  cancelReservation = async (id) => {
    return this._updateReservationState(id, 'cancelled')
  }

  getReservation = async (id) => {
    return await this.getCustomerRef().collection('reservations').doc(id).get()
  }

  getReservations = async () => {
    let r = await this.getCustomerRef().collection('reservations').get()
    return r
  }

  addSaldoPayment = async (payment) => {
    try {
      payment.q = 'addSaldoPayment'
      payment.cid = this.customerid
      const apicall = this.getAdminAPICall()
      const response = await apicall(payment)
      return response
    }
    catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  addStoragePayment = async (payment) => {
    try {
      payment.q = 'addStoragePayment'
      payment.cid = this.customerid
      const apicall = this.getAdminAPICall()
      const response = await apicall(payment)
      return response
    }
    catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  addPayment = async (reservationid, payment) => {
    try {
      const data = { reservationid, payment, cid: this.customerid, q: 'addPayment' }
      const apicall = this.getAdminAPICall()
      const response = await apicall(data)
      return response
    }
    catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  listenReservations = () => {
    return this.getCustomerRef().collection('reservations')
  }

  listenReservation = (id) => {
    return this.getCustomerRef().collection('reservations').doc(id)
  }


  // getBookings = async (date) => {
  //   // console.log(date)
  //   const _bookings = await this.db.ref('bookings/' + this.customerid + '/' + date).once('value')
  //   if (_bookings.exists()) {
  //     const bookings = Object.keys(_bookings.val()).map((key) => _bookings.val()[key])
  //     return bookings
  //   } else {
  //     return []
  //   }
  // }

  // listenBookings = (date) => {
  //   // return this.db.ref('bookings/' + this.customerid + '/' + date).orderByChild('data/state').equalTo('reserved')
  //   return this.db.ref('bookings/' + this.customerid + '/' + date)
  // }

  listenBookingStore = (date) => {
    return this.getCustomerBookingIndexRef().doc(date)
  }

  listenBookingIndex = (date) => {
    return this.getCustomerBookingIndexingRef().collection(date)
  }

  listenFreetimes = () => {
    return this.db.ref('freetimes/' + this.customerid)
  }

  listenLights = () => {
    return this.db.ref('test/lights/' + this.customerid)
  }

  listenInfoTexts = () => {
    return this.db.ref('infotexts/' + this.customerid)
  }

  /* Organisation */
  registerOrganisation = async (organisation) => {
    organisation.customerid = this.customerid
    this.db.ref('regbusiness').push(organisation)
  }

  createCustomerOrganisation = async (organisation) => {
    return await this.getCustomerRef().collection('organisations').add(organisation)
  }

  storeContacts = async (contacts, organisationid) => {
    if (organisationid) {
      return await this.getCustomerRef().collection('organisations').doc(organisationid).set(contacts, { merge: true })
    } else {
      alert('No organisation selected')
    }
  }

  updateOrganisation = async (organisation) => {
    delete organisation.customers
    return await this.getCustomerRef()
      .collection('organisations')
      .doc(organisation.id)
      .set(organisation, { merge: true })
  }

  deleteOrganisation = async (id) => {
    let r = { id: id, state: 'deleted' }
    return await this.updateOrganisation(r)
  }

  listenOrganisations = () => {
    return this.getCustomerRef().collection('organisations')
  }

  listenOrganisationsRT = () => {
    return this.db.ref('organisations/')
  }

  // ** Configs **

  addOpeningTimes = (config) => {
    return this.getCustomerRef().collection('config').doc('openingtimes').set(config, { merge: true })
  }

  getOpeningTimes = () => {
    return this.getCustomerRef().collection('config').doc('openingtimes')
  }

  getDayOpeningTimes = (day) => {
    const index = moment(day, 'YYYY-MM-DD').format('YYYYMMDD')
    console.log(index)
    return this.firestore.collection('indexes').doc('opentimes').collection(this.customerid).doc(index).get()
  }

  addResourceCategories = (config) => {
    return this.getCustomerRef().collection('config').doc('resourcecategories').set(config, { merge: true })
  }

  getResourceCategories = () => {
    return this.getCustomerRef().collection('config').doc('resourcecategories')
  }

  addMessageTemplates = (config) => {
    return this.getCustomerRef().collection('config').doc('messagetemplates').set(config, { merge: true })
  }

  getMessageTemplates = () => {
    return this.getCustomerRef().collection('config').doc('messagetemplates')
  }

  getAdminNotification = () => {
    return this.getCustomerRef().collection('config').doc('adminnotification')
  }

  addAdminNotification = (config) => {
    return this.getCustomerRef().collection('config').doc('adminnotification').set(config, { merge: true })
  }


  addColorCategories = (config) => {
    return this.getCustomerRef().collection('config').doc('colorcategories').set(config, { merge: true })
  }

  getColorCategories = () => {
    return this.getCustomerRef().collection('config').doc('colorcategories')
  }

  getCustomerExtras = () => {
    return this.getCustomerRef().collection('config').doc('customerextras')
  }

  addCustomerExtras = (config) => {
    return this.getCustomerRef().collection('config').doc('customerextras').set(config, { merge: true })
  }

  getTerms = () => {
    return this.getCustomerRef().collection('config').doc('terms')
  }

  addTerms = (config) => {
    return this.getCustomerRef().collection('config').doc('terms').set(config, { merge: true })
  }

  getRecurrentTerms = () => {
    return this.getCustomerRef().collection('config').doc('recurrentterms')
  }

  addRecurrentTerms = (config) => {
    return this.getCustomerRef().collection('config').doc('recurrentterms').set(config, { merge: true })
  }

  getActivityConfig = () => {
    return this.getCustomerRef().collection('config').doc('activity')
  }

  addActivityConfig = (config) => {
    return this.getCustomerRef().collection('config').doc('activity').set(config, { merge: true })
  }

  getConfig = (configName) => {
    return this.getCustomerRef().collection('config').doc(configName)
  }

  addConfig = async (configName, config) => {
    return await this.getCustomerRef().collection('config').doc(configName).set(config, { merge: true })
  }


  addResourceConfigs = (config) => {
    if (!config.id) config.id = this.getRandomId()
    return this.getCustomerRef()
      .collection('config')
      .doc('resourceconfigs')
      .collection('config')
      .doc(config.id)
      .set(config, { merge: true })
  }

  getResourceConfigs = () => {
    return this.getCustomerRef().collection('config').doc('resourceconfigs').collection('config').get()
  }

  createResourcePricing = async (config) => {
    config.type = 'resourcepricing'
    return await this.getCustomerRef().collection('resourcepricings').add(config)
  }

  addResourcePricingSlot = async (slotConfig) => {
    if (!slotConfig.id) slotConfig.id = this.getRandomId()
    let config = { slots: {} }
    config.slots[slotConfig.id] = slotConfig
    config.type = 'resourcepricing'
    return await this.getCustomerRef()
      .collection('resourcepricings')
      .doc(slotConfig.configid)
      .set(config, { merge: true })
  }

  getResourcePricings = async () => {
    return await this.getCustomerRef().collection('resourcepricings').where('type', '==', 'resourcepricing').get()
  }

  getResourcePricing = async (id) => {
    return await this.getCustomerRef().collection('resourcepricings').doc(id).get()
  }

  deleteResourcePricings = async (id) => {
    let r = { state: 'deleted', creatorId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('resourcepricings').doc(id).set(r, { merge: true })
  }

  updateResourcePricing = async (config) => {
    config.type = 'resourcepricing'
    return await this.getCustomerRef().collection('resourcepricings').doc(config.id).set(config, { merge: true })
  }

  getReservationPrices = async (slot, personid) => {
    try {
      this.functions.region = 'europe-west1'
      const getprice = this.functions.httpsCallable('testreservations-getprices')
      // const getprice = this.functions.httpsCallable('reservations-getpricev4')
      const prices = await getprice({ slot, personid, customerid: this.customerid })
      return prices
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  isConflict = async (slots) => {
    // console.log('Checking conflicts')
    try {
      this.functions.region = 'europe-west1'
      const isConflict = this.functions.httpsCallable('redis-isconflict')
      const conflictData = await isConflict({ slots, customerid: this.customerid })
      // console.log('got conflicts', conflictData)
      return conflictData.data
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  isConflictv2 = async (slots) => {
    // console.log('Checking conflicts')
    try {
      this.functions.region = 'europe-west1'
      const isConflict = this.functions.httpsCallable('redisv2-isconflict')
      const conflictData = await isConflict({ slots, customerid: this.customerid })
      // console.log('got conflicts', conflictData)
      return conflictData.data
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }


  // SALDO

  addSaldoTransaction = async (userid, type, amount, description, wallet, venue) => {
    try {
      const transaction = { type, amount, description }
      this.functions.region = 'europe-west1'
      const addTransaction = this.functions.httpsCallable('users-addsaldotransaction')
      const addedTransaction = await addTransaction({ userid, customerid: this.customerid, transaction, wallet, venue })
      return addedTransaction
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  getPersonSaldo = async (userid) => {
    try {
      this.functions.region = 'europe-west1'
      const getTransactions = this.functions.httpsCallable('users-getsaldotransactions')
      const transactions = await getTransactions({ userid, customerid: this.customerid })
      return transactions
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  addStorageTransaction = async (userid, type, amount, description, wallet, venue) => {
    try {
      const transaction = { type, amount, description }
      this.functions.region = 'europe-west1'
      const addTransaction = this.functions.httpsCallable('users-addstoragetransaction')
      const addedTransaction = await addTransaction({ userid, customerid: this.customerid, transaction, wallet, venue })
      return addedTransaction
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  getPersonStorage = async (userid) => {
    try {
      this.functions.region = 'europe-west1'
      const getTransactions = this.functions.httpsCallable('users-getstoragetransactions')
      const transactions = await getTransactions({ userid, customerid: this.customerid })
      return transactions
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  getOrganisationSaldo = async (organisationid) => {
    return await this.getCustomerRef().collection('organisations').doc(organisationid).collection('saldo').get()
  }

  getOrganisationStorage = async (organisationid) => {
    return await this.getCustomerRef().collection('organisations').doc(organisationid).collection('storage').get()
  }


  getPersonDetailsCommand = async (command, userid) => {
    try {
      this.functions.region = 'europe-west1'
      const getPersonDetails = this.functions.httpsCallable('users-persondetails')
      const data = { command, customerid: this.customerid }
      if (userid) data.userid = userid
      return await getPersonDetails(data)
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }


  // PERSON ACL

  getAcls = async (userid) => {
    try {
      this.functions.region = 'europe-west1'
      const getAcls = this.functions.httpsCallable('users-getAcls')
      const acls = await getAcls({ userid, cid: this.customerid })
      return acls
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }


  setAcl = async ({ userid, acl }) => {
    try {
      this.functions.region = 'europe-west1'
      const setAcl = this.functions.httpsCallable('users-setAcl')
      const acls = await setAcl({ userid, cid: this.customerid, acl })
      return acls
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  getAclsPd = async (userid) => {
    return await this.getPersonDetailsCommand('getAcls', userid)
  }

  // PERSON ACCESSCODE

  getPersonPin = async (userid) => {
    try {
      this.functions.region = 'europe-west1'
      const getPersonPin = this.functions.httpsCallable('users-getpin')
      const pin = await getPersonPin({ userid, customerid: this.customerid })
      return pin
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  getPersonPinPd = async (userid) => {
    return await this.getPersonDetailsCommand('getpin', userid)
  }

  // PERSON MESSAGES

  getPersonMessages = async (userid) => {
    try {
      const _me = await this.firestore.collection('messages').doc(this.customerid).collection('active').where('userid', '==', userid).get()
      if (!_me.empty) {
        return _me.docs.map((doc) => { return { ...doc.data(), id: doc.id } })
      } else {
        return []
      }
    } catch (error) {
      console.error(error)
      return []
    }
  }

  getPersonMessagesPd = async (userid) => {
    return await this.getPersonDetailsCommand('getmessages', userid)
  }

  // PERSON BENEFITCARDS

  getPersonBenefitCards = async (userid) => {
    try {
      this.functions.region = 'europe-west1'
      const getPersonBenefitCards = this.functions.httpsCallable('users-getbenefitcards')
      const benefitcards = await getPersonBenefitCards({ userid, customerid: this.customerid })
      return benefitcards
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  addNewPersonBenefitCard = async (userid, benefitcardid, note) => {
    try {
      this.functions.region = 'europe-west1'
      const addPersonBenefitCard = this.functions.httpsCallable('users-addbenefitcard')
      const benefitcard = await addPersonBenefitCard({ userid, customerid: this.customerid, benefitcardid, note })
      return benefitcard
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  addNewPersonTimedBenefitCard = async (userid, benefitcardid, note, start, end, permanent) => {
    try {
      this.functions.region = 'europe-west1'
      const addPersonBenefitCard = this.functions.httpsCallable('users-addbenefitcard')
      const benefitcard = await addPersonBenefitCard({ userid, customerid: this.customerid, benefitcardid, note, start, end, permanent })
      return benefitcard
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }


  updatePersonBenefitCard = async (userid, id, remaining) => {
    try {
      this.functions.region = 'europe-west1'
      const updatePersonBenefitCard = this.functions.httpsCallable('users-updatebenefitcard')
      const benefitcard = await updatePersonBenefitCard({ userid, customerid: this.customerid, id, remaining })
      return benefitcard
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  getPersonBenefitCardsPd = async (userid) => {
    return await this.getPersonDetailsCommand('getbenefitcards', userid)
  }

  getCustomerBenefitCardsPd = async () => {
    return await this.getPersonDetailsCommand('benefitcards')
  }

  // return this.getCustomerRef().collection('messages').doc(this.customerid).collection('config').get()
  // getPersonMessages = async (userid) => {
  //   try {
  //     this.functions.region = 'europe-west1'
  //     const getPersonMessages = this.functions.httpsCallable('users-getmessages')
  //     const messages = await getPersonMessages({ userid, customerid: this.customerid })
  //     return messages
  //   } catch (error) {
  //     console.error(error)
  //     return { error: true, message: error.message }
  //   }
  // }


  // REMOVE PERSON CUSTOMERSHIP



  // PERSON MEMBERSHIPS

  addNewMembership = async (userid, membershipid, start, end, note, permanent) => {
    try {
      const membership = { membershipid, start, end, note, permanent }
      this.functions.region = 'europe-west1'
      const addMembership = this.functions.httpsCallable('users-addmembership')
      const addedMembership = await addMembership({ userid, customerid: this.customerid, membership })
      return addedMembership
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  updateUserMembership = async (userid, id, start, end, note, permanent) => {
    try {
      // console.log('updateMembership ff','userid', userid, 'id', id, 'start', start, 'end', end, 'note', note, 'permanent', permanent)      
      const membership = { start, end, note, permanent }
      this.functions.region = 'europe-west1'
      const updateMembership = this.functions.httpsCallable('users-updatemembership')
      const updatedMembership = await updateMembership({ userid, customerid: this.customerid, id, membership })
      return updatedMembership
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  terminateMembership = async (userid, id, end, permanent) => {
    try {
      const membership = { end, permanent }
      this.functions.region = 'europe-west1'
      const updateMembership = this.functions.httpsCallable('users-updatemembership')
      const updatedMembership = await updateMembership({ userid, customerid: this.customerid, id, membership })
      return updatedMembership
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  getPersonMemberships = async (userid, serverfilter) => {
    try {
      this.functions.region = 'europe-west1'
      const getMemberships = this.functions.httpsCallable('users-getmemberships')
      if (serverfilter) {
        const memberships = await getMemberships({ userid, customerid: this.customerid, serverfilter })
        return memberships
      } else {
        const memberships = await getMemberships({ userid, customerid: this.customerid })
        return memberships
      }
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  getOrganisationMemberships = async (organisationid) => {
    try {
      this.functions.region = 'europe-west1'
      const getMemberships = this.functions.httpsCallable('organisations-getmemberships')
      const memberships = await getMemberships({ organisationid, customerid: this.customerid })
      return memberships
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }


  addNewOrganisationMembership = async (organisationid, membershipid, start, end, note, permanent) => {
    try {
      const membership = { membershipid, start, end, note, permanent }
      this.functions.region = 'europe-west1'
      const addMembership = this.functions.httpsCallable('organisations-addmembership')
      const addedMembership = await addMembership({ organisationid, customerid: this.customerid, membership })
      return addedMembership
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  updateOrganisationMembership = async (organisationid, id, start, end, note, permanent) => {
    try {
      // console.log('updateMembership ff','userid', userid, 'id', id, 'start', start, 'end', end, 'note', note, 'permanent', permanent)      
      const membership = { start, end, note, permanent }
      this.functions.region = 'europe-west1'
      const updateMembership = this.functions.httpsCallable('organisations-updatemembership')
      const updatedMembership = await updateMembership({ organisationid, customerid: this.customerid, id, membership })
      return updatedMembership
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  terminateOrganisationMembership = async (organisation, id, end, permanent) => {
    try {
      const membership = { end, permanent }
      this.functions.region = 'europe-west1'
      const updateMembership = this.functions.httpsCallable('organisations-updatemembership')
      const updatedMembership = await updateMembership({ userid: organisation, customerid: this.customerid, id, membership })
      return updatedMembership
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }













  getPersonCDataPd = async (id) => {
    return await this.getPersonDetailsCommand('getcdata', id)
  }

  getPersonMembershipsPd = async (userid) => {
    return await this.getPersonDetailsCommand('getmemberships', userid)
  }

  getCustomerMembershipsPd = async () => {
    return await this.getPersonDetailsCommand('memberships')
  }

  getPersonProductOrdersPd = async (userid) => {
    return await this.getPersonDetailsCommand('productorders', userid)
  }

  getPersonDetails = async (userid) => {
    return await this.getPersonDetailsCommand('getuserdetails', userid)
  }

  getInvoicingFake = async () => {
    return Promise.resolve({
      empty: false, docs: [
        { data: () => { return { name: 'default' } }, id: '1' },
        { data: () => { return { name: 'meilahdenpalloilu' } }, id: '2' },
      ]
    })
  }
  getVenues = async () => {
    if (this.customerid.indexOf('meilahdenliikunta') > -1) return await this.getInvoicingFake()
    return await this.getCustomerRef().collection('venues').get()
  }

  // getVenues = () => {
  //   if (this.customerid && this.customerid.startsWith('tali')) {
  //     return ['tali', 'taivallahti']
  //   } else if (this.customerid && this.customerid.startsWith('evs')) {
  //     return ['aktia', 'martinmäki']
  //   } else {
  //     return []
  //   }
  // }

  // UTILS

  getDiffs = async (id) => {
    const _diffLeaf = await this.db.ref('logs/' + this.customerid + '/' + id).once('value')
    if (_diffLeaf.exists()) {
      // const bookings = Object.keys(_bookings.val()).map((key) => _bookings.val()[key])
      return _diffLeaf.val()
    } else {
      return {}
    }
  }

  getPasswordResetLink = async (email) => {
    try {
      this.functions.region = 'europe-west1'
      const getPasswordReset = this.functions.httpsCallable('users-generatePasswordResetLink')
      const link = await getPasswordReset({ email })
      return link
    } catch (error) {
      console.error(error)
      return {}
    }
  }

  findOrganisationFromPrh = async (businessId, name) => {
    try {
      this.functions.region = 'europe-west1'
      // const getBusiness = this.functions().httpsCallable('organisation-getBusiness')
      const getBusiness = this.functions.httpsCallable('organisations-getBusiness')
      const business = await getBusiness({ businessid: businessId, name: name })
      return business
    } catch (error) {
      console.error(error)
      return {}
    }
  }

  getOrganisationFromPrhById = async (businessId) => {
    try {
      this.functions.region = 'europe-west1'
      const getBusiness = this.functions.httpsCallable('organisations-getBusinessByBusinessId')
      const business = await getBusiness({ businessid: businessId })
      return business
    } catch (error) {
      console.error(error)
      return {}
    }
  }

  // CACHE

  searchPerson = (pattern) => {
    // console.log(pattern)
    // let results = this.cache.searchPersons(pattern)
    // console.log(results)
    return this.cache.searchPersons(pattern)
  }

  updatePersonCache = (persons) => {
    // console.log('updatePersonCache', persons)
    this.cache.updatePersons(persons)
  }

  searchOrganisations = (pattern) => {
    // console.log(pattern)
    return this.cache.searchOrganisations(pattern)
  }

  updateOrganisationsCache = (organisations) => {
    this.cache.updateOrganisations(organisations)
  }

  searchOrganisations = (pattern) => {
    // console.log(pattern)
    return this.cache.searchOrganisations(pattern)
  }

  updateCombinedCache = (organisations, persons) => {
    this.cache.updateCombined(organisations, persons)
  }

  searchCombined = (pattern) => {
    // console.log(pattern)
    return this.cache.searchCombined(pattern)
  }

  isCacheValid = () => {
    return this.cache.isValid()
  }

  // Action Messages
  listenActionMessages = () => {
    // console.log('listening /actionmessages/' + this.customerid)
    return this.db.ref('actionmessages/' + this.customerid + '/').orderByKey() //.orderByChild('created')
  }

  // Shareholders
  createShareholderSlot = async (shareholderSlot) => {
    shareholderSlot.creatorId = this.auth.currentUser.uid
    return await this.getCustomerRef().collection('shareholders').add(shareholderSlot)
  }

  updateShareholderSlot = async (shareholderSlot) => {
    shareholderSlot.creatorId = this.auth.currentUser.uid
    return await this.getCustomerRef()
      .collection('shareholders')
      .doc(shareholderSlot.id)
      .set(shareholderSlot, { merge: true })
  }

  _updateShareholderSlotState = async (id, state) => {
    let r = { state: state }
    r.creatorId = this.auth.currentUser.uid

    return await this.getCustomerRef().collection('shareholders').doc(id).set(r, { merge: true })
  }

  setOnSaleShareholderSlot = async (id) => {
    return this._updateShareholderSlotState(id, 'onsale')
  }

  cancelShareholderSlot = async (id) => {
    return this._updateShareholderSlotState(id, 'cancelled')
  }

  getShareholderSlot = async (id) => {
    return await this.getCustomerRef().collection('shareholders').doc(id).get()
  }

  getShareholderSlots = async () => {
    let r = await this.getCustomerRef().collection('shareholders').get()
    return r
  }

  sendConfirmation = async (newemail, userid, language) => {
    try {
      this.functions.region = 'europe-west1'
      const createConfirmation = this.functions.httpsCallable('users-createConfirmationAdmin')
      const response = await createConfirmation({ newemail, cid: this.customerid, userid, language })
      return response
    } catch (error) {
      console.error(error)
      return {}
    }
  }

  invoiceAction = async (userid, rows, action, markasinvoiced, invoicer, billingdate) => {
    try {
      this.functions.region = 'europe-west1'
      const invoiceAction = this.functions.httpsCallable('invoicing-invoiceAction')
      // console.log({ userid, customerid: this.customerid, rows, action, markasinvoiced, invoicer })
      const response = await invoiceAction({ userid, customerid: this.customerid, rows, action, markasinvoiced, invoicer, billingdate })
      return response
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  invoiceBatchAction = async (rows, action, markasinvoiced, invoicer, billingdate) => { // invoicer === venue
    try {
      this.functions.region = 'europe-west1'
      const invoiceBatchAction = this.functions.httpsCallable('invoicing-invoiceBatchActionV2')
      // console.log({customerid: this.customerid, rows, action, markasinvoiced, invoicer })
      const response = await invoiceBatchAction({ customerid: this.customerid, rows, action, markasinvoiced, invoicer, billingdate })
      return response
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  invoiceCombinedAction = async (rows, payer, invoicer, billingdate, addoriginalusertorow, billingreference) => {
    try {
      this.functions.region = 'europe-west1'
      const createInvoiceAction = this.functions.httpsCallable('invoicing-createInvoiceAction')
      const response = await createInvoiceAction({ customerid: this.customerid, rows, payer, invoicer, billingdate, addoriginalusertorow, billingreference })
      return response
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  invoiceCreateAction = async (rows, action, markasinvoiced, invoicer, billingdate) => {
    console.log('invoiceCreateAction', rows, action, markasinvoiced, invoicer, billingdate)
    try {
      this.functions.region = 'europe-west1'
      const invoiceCreate = this.functions.httpsCallable('invoicing-invoiceCreate')
      const response = await invoiceCreate({ customerid: this.customerid, rows, action, markasinvoiced, invoicer, billingdate })
      return response
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  batchAddSaldos = async (userid, rows) => {
    try {
      this.functions.region = 'europe-west1'
      const _batchAddSaldos = this.functions.httpsCallable('invoicing-batchAddSaldos')
      // console.log({customerid: this.customerid, rows, action, markasinvoiced, invoicer })
      const response = await _batchAddSaldos({ customerid: this.customerid, rows, userid })
      return response
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  copyRecurrectsBatchAction = async (ids, startdate, enddate, overrideminus, overrideminuspercent) => {
    try {
      // console.log(ids, startdate, enddate, overrideminus)
      this.functions.region = 'europe-west1'
      const copyBatchAction = this.functions.httpsCallable('reservations-copyRecurrings')
      // console.log({customerid: this.customerid, ids, startdate, enddate, overrideminus })
      const response = await copyBatchAction({ customerid: this.customerid, ids, startdate, enddate, overrideminus, overrideminuspercent })
      // console.log('response', response)
      return response.data
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  removeCustomership = async (userid) => {
    try {
      // console.log('Removing', userid)
      this.functions.region = 'europe-west1'
      const deleteCustomership = this.functions.httpsCallable('users-deletecustomership')
      const response = await deleteCustomership({ userid, customerid: this.customerid })
      return response
    } catch (error) {
      console.error(error)
      return { error: true, message: error.message }
    }
  }

  /* Infotext */
  createInfotext = async (infotext) => {
    infotext.creatorId = this.auth.currentUser.uid
    return await this.db.ref('infotexts/' + this.customerid).push(infotext)
  }

  updateInfotext = async (infotext) => {
    if (infotext.id) {
      infotext.updaterId = this.auth.currentUser.uid
      return await this.db.ref('infotexts/' + this.customerid + '/' + infotext.id).update(infotext)
    }
  }

  deleteInfotext = async (id) => {
    let r = { state: 'deleted', creatorId: this.auth.currentUser.uid }
    return await this.db.ref('infotexts/' + this.customerid + '/' + id).update(r)
  }

  getInfotext = async (id) => {
    return await this.db.ref('infotexts/' + this.customerid + '/' + id).once('value')
  }


  // Media

  listenMedia = () => {
    return this.firestore.collection('media').doc(this.customerid).collection('library')
  }

  getImages = async () => {
    const mediaDocs = await this.firestore.collection('media').doc(this.customerid).collection('library').get()
    return mediaDocs.docs.map((doc) => { return { ...doc.data(), id: doc.id } }).filter((media) => media.state !== 'deleted').filter((media) => media.mimetype.startsWith('image/'))
  }

  deleteMedia = async (id) => {
    try {
      this.functions.region = 'europe-west1'
      const updateMedia = this.functions.httpsCallable('media-updateMedia')
      const customerid = this.customerid
      const response = await updateMedia({ customerid, remove: true, fileid: id })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }
  
  updateMedia = async (id, name) => {
    try {
      this.functions.region = 'europe-west1'
      const updateMedia = this.functions.httpsCallable('media-updateMedia')
      const customerid = this.customerid
      const response = await updateMedia({ customerid, fileid: id, name })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }


  // Activitytypes

  getActivitytypes = () => {
    return this.getCustomerRef().collection('config').doc('activitytypes')
  }

  // Sports

  getSports = () => {
    return this.getCustomerRef().collection('config').doc('sports')
  }
  

  // latest

  listenLatestReservations = () => {
    return this.firestore.collection('latest').doc(this.customerid).collection('reservations')
  }

  /* Banners */
  createBanner = async (banner) => {
    banner.creatorId = this.auth.currentUser.uid
    return await this.db.ref('infobanners/' + this.customerid).push(banner)
  }

  updateBanner = async (banner) => {
    if (banner.id) {
      banner.updaterId = this.auth.currentUser.uid
      return await this.db.ref('infobanners/' + this.customerid + '/' + banner.id).update(banner)
    }
  }

  deleteBanner = async (id) => {
    let r = { state: 'deleted', updatedId: this.auth.currentUser.uid }
    return await this.db.ref('infobanners/' + this.customerid + '/' + id).update(r)
  }

  getBanner = async (id) => {
    return await this.db.ref('infobanners/' + this.customerid + '/' + id).once('value')
  }

  listenBanners = () => {
    return this.db.ref('infobanners/' + this.customerid)
  }

  /* TextBanner */
  createTextBanner = async (banner) => {
    banner.creatorId = this.auth.currentUser.uid
    return await this.db.ref('textbanners/' + this.customerid).push(banner)
  }
  
  updateTextBanner = async (banner) => {
    if (banner.id) {
      banner.updaterId = this.auth.currentUser.uid
      return await this.db.ref('textbanners/' + this.customerid + '/' + banner.id).update(banner)
    }
  }
  
  deleteTextBanner = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.db.ref('textbanners/' + this.customerid + '/' + id).update(r)
  }
  
  getTextBanner = async (id) => {
    return await this.db.ref('textbanners/' + this.customerid + '/' + id).once('value')
  }
  
  listenTextBanners = () => {
    return this.db.ref('textbanners/' + this.customerid)
  }
  
  /* Recurring listing */

  getRecurringsCsv = async (startdate, enddate) => {
    try {
      this.functions.region = 'europe-west1'
      const updateRole = this.functions.httpsCallable('reports-getRecurringsCsv')
      const response = await updateRole({ startdate, enddate, cid: this.customerid })
      return response
    } catch (error) {
      console.error(error)
      return {}
    }
  }

  forceLights = () => {
    try {
      this.functions.region = 'europe-west1'
      return this.functions.httpsCallable('lights3-forceCommand')({ customerid: this.customerid })
    } catch (error) {
      console.error(error)
      return {}
    }
  }

  listenRecurringSales = () => {
    return this.firestore.collection('customers').doc(this.customerid).collection('recurringsales')
  }

  createRecurringSale = async (document) => {
    document.creatorId = this.auth.currentUser.uid
    document.created = app.firestore.FieldValue.serverTimestamp()
    return await this.getCustomerRef().collection('recurringsales').add(document)
  }

  updateRecurringSale = async (document) => {
    if (document.id) {
      document.updaterId = this.auth.currentUser.uid
      document.updated = app.firestore.FieldValue.serverTimestamp()
      return await this.getCustomerRef().collection('recurringsales').doc(document.id).set(document, { merge: true })
    }
  }

  deleteRecurringSale = async (id) => {
    const r = { state: 'deleted', updaterId: this.auth.currentUser.uid, updated: app.firestore.FieldValue.serverTimestamp() }
    return await this.getCustomerRef().collection('recurringsales').doc(id).set(r, { merge: true })
  }

  createRecurringSaleResourcePricing = async (config) => {
    config.type = 'recurringsalepricing'
    return await this.getCustomerRef().collection('resourcepricings').add(config)
  }

  addRecurringSaleResourcePricingSlot = async (slotConfig) => {
    if (!slotConfig.id) slotConfig.id = this.getRandomId()
    let config = { slots: {} }
    config.slots[slotConfig.id] = slotConfig
    config.type = 'recurringsalepricing'
    return await this.getCustomerRef()
      .collection('resourcepricings')
      .doc(slotConfig.configid)
      .set(config, { merge: true })
  }

  getRecurringSaleResourcePricings = async () => {
    return await this.getCustomerRef().collection('resourcepricings').where('type', '==', 'recurringsalepricing').get()
  }

  getRecurringSaleResourcePricing = async (id) => {
    return await this.getCustomerRef().collection('resourcepricings').doc(id).get()
  }

  deleteRecurringSaleResourcePricings = async (id) => {
    let r = { state: 'deleted', creatorId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('resourcepricings').doc(id).set(r, { merge: true })
  }

  updateRecurringSaleResourcePricing = async (config) => {
    config.type = 'recurringsalepricing'
    return await this.getCustomerRef().collection('resourcepricings').doc(config.id).set(config, { merge: true })
  }



  /* reskontra */
  setduedate = async (date, ids) => {
    try {
      this.functions.region = 'europe-west1'
      const call = this.functions.httpsCallable('invoicing-reskontra')
      const response = await call({ customerid: this.customerid, command: 'setduedate', payload: { duedate: date, ids } })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  setbillingdate = async (date, ids) => {
    try {
      this.functions.region = 'europe-west1'
      const call = this.functions.httpsCallable('invoicing-reskontra')
      const response = await call({ customerid: this.customerid, command: 'setbillingdate', payload: { billingdate: date, ids } })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  addpayment = async (amount, id, type, note, paymentdate) => {
    try {
      this.functions.region = 'europe-west1'
      const call = this.functions.httpsCallable('invoicing-reskontra')
      const response = await call({ customerid: this.customerid, command: 'addpayment', payload: { amount, id, note, type, paymentdate } })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }

  sendinvoices = async (ids, type, justcreate, coveringnote, title, creditmemorandumRows) => {
    try {
      if (justcreate === undefined) justcreate = false
      this.functions.region = 'europe-west1'
      const call = this.functions.httpsCallable('invoicing-reskontra')
      const response = await call({ customerid: this.customerid, command: 'sendinvoices', payload: { ids, type, justcreate, coveringnote, title, creditmemorandumRows } })
      return response
    } catch (error) {
      console.error(error)
      return { error: error.message }
    }
  }


  /* maintenance */
  createMaintenanceContract = async (contract) => {
    contract.creatorId = this.auth.currentUser.uid
    contract.doctype = 'contract'
    return await this.getCustomerRef().collection('maintenance').add(contract)
  }

  updateMaintenanceContract = async (contract) => {
    contract.updaterId = this.auth.currentUser.uid
    contract.doctype = 'contract'
    return await this.getCustomerRef().collection('maintenance').doc(contract.id).set(contract, { merge: true })
  }

  deleteMaintenanceContract = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('maintenance').doc(id).set(r, { merge: true })
  }

  getMaintenanceContract = async (id) => {
    const r = await this.getCustomerRef().collection('maintenance').doc(id).get()
    if (r.exists) {
      return { ...r.data(), id: r.id }
    } else {
      return {}
    }
  }

  getMaintenanceContracts = async () => {
    let r = await this.getCustomerRef().collection('maintenance').where('doctype', '==', 'contract').get()
    if (r.empty) return []
    return r.docs.map((doc) => { return { ...doc.data(), id: doc.id } })
  }



  createMaintenanceServiceDescription = async (servicedescription) => {
    servicedescription.creatorId = this.auth.currentUser.uid
    servicedescription.doctype = 'servicedescription'
    if (servicedescription.taskgroups) {
      servicedescription.taskgroups = servicedescription.taskgroups.map((tg) => { if (!tg.id) { return { ...tg, id: this.getRandomId() } } else { return tg } })
    }
    return await this.getCustomerRef().collection('maintenance').add(servicedescription)
  }

  updateMaintenanceServiceDescription = async (servicedescription) => {
    servicedescription.updaterId = this.auth.currentUser.uid
    servicedescription.doctype = 'servicedescription'
    if (servicedescription.taskgroups) {
      servicedescription.taskgroups = servicedescription.taskgroups.map((tg) => { if (!tg.id) { return { ...tg, id: this.getRandomId() } } else { return tg } })
    }
    return await this.getCustomerRef().collection('maintenance').doc(servicedescription.id).set(servicedescription, { merge: true })
  }

  deleteMaintenanceServiceDescription = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('maintenance').doc(id).set(r, { merge: true })
  }

  getMaintenanceServiceDescription = async (id) => {
    const r = await this.getCustomerRef().collection('maintenance').doc(id).get()
    if (r.exists) {
      return { ...r.data(), id: r.id }
    } else {
      return {}
    }
  }

  getMaintenanceServiceDescriptions = async (contractid) => {
    let r = await this.getCustomerRef().collection('maintenance').where('doctype', '==', 'servicedescription').where('maintenancecontractid', '==', contractid).get()
    if (r.empty) return []
    return r.docs.map((doc) => { return { ...doc.data(), id: doc.id } })
  }

  createMaintenanceTask = async (task) => {
    task.creatorId = this.auth.currentUser.uid
    task.doctype = 'task'
    return await this.getCustomerRef().collection('maintenance').add(task)
  }

  updateMaintenanceTask = async (task) => {
    task.updaterId = this.auth.currentUser.uid
    task.doctype = 'task'
    return await this.getCustomerRef().collection('maintenance').doc(task.id).set(task, { merge: true })
  }

  deleteMaintenanceTask = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('maintenance').doc(id).set(r, { merge: true })
  }

  getMaintenanceTask = async (id) => {
    const r = await this.getCustomerRef().collection('maintenance').doc(id).get()
    if (r.exists) {
      return { ...r.data(), id: r.id }
    } else {
      return {}
    }
  }

  getMaintenanceTasks = async (servicedescriptionid) => {
    let r = await this.getCustomerRef().collection('maintenance').where('doctype', '==', 'task').where('servicedescriptionid', '==', servicedescriptionid).get()
    if (r.empty) return []
    return r.docs.map((doc) => { return { ...doc.data(), id: doc.id } })
  }


  // getMaintenanceTicketTargets = async () => {
  //   let r = await this.getCustomerRef().collection('maintenance').where('doctype', '==', 'tickettarget').where('state', '!=', 'deleted').get()
  //   if (r.empty) return []
  //   return r.docs.map((doc) => { return { ...doc.data(), id: doc.id } })
  // }

  getMaintenanceTicketTargets = () => {
    return this.getCustomerRef().collection('config').doc('maintenancetargets')
  }

  updateMaintenanceTicketTarget = (config) => {
    config.updaterId = this.auth.currentUser.uid
    config.updated = app.firestore.FieldValue.serverTimestamp()
    return this.getCustomerRef().collection('config').doc('maintenancetargets').set(config, { merge: true })
  }

  getMaintenanceTicketTargetsOnce = async () => {
    let r = await this.getCustomerRef().collection('config').doc('maintenancetargets').get()
    if (r.exists) {
      return r.data().targets
    } else {
      return []
    }
  }

  updateMaintenanceTicketClasses = (config) => {
    config.updaterId = this.auth.currentUser.uid
    config.updated = app.firestore.FieldValue.serverTimestamp()
    return this.getCustomerRef().collection('config').doc('maintenancetargets').set(config, { merge: true })
  }

  getMaintenanceTicketClasses = () => {
    return this.getCustomerRef().collection('config').doc('maintenancetargets')
  }

  getMaintenanceTicketClassesOnce = async () => {
    let r = await this.getCustomerRef().collection('config').doc('maintenancetargets').get()
    if (r.exists) {
      return r.data().classes
    } else {
      return []
    }
  }


  getMaintenanceTickets = async () => {
    let r = await this.getCustomerRef().collection('maintenance').where('doctype', '==', 'ticket')/*.where('state', '!=', 'deleted')*/.get()
    if (r.empty) return []
    return r.docs.map((doc) => { return { ...doc.data(), id: doc.id } })
  }

  createMaintenanceTicket = async (ticket) => {
    ticket.creatorId = this.auth.currentUser.uid
    ticket.doctype = 'ticket'
    delete ticket.createdDate
    delete ticket.updatedDate
    return await this.getCustomerRef().collection('maintenance').add(ticket)
  }

  updateMaintenanceTicket = async (ticket) => {
    ticket.updaterId = this.auth.currentUser.uid
    ticket.doctype = 'ticket'
    ticket.updated = app.firestore.FieldValue.serverTimestamp()
    delete ticket.created
    delete ticket.updatedDate
    delete ticket.createdDate
    delete ticket.createdLocal

    return await this.getCustomerRef().collection('maintenance').doc(ticket.id).set(ticket, { merge: true })
  }

  deleteMaintenanceTicket = async (id) => {
    let r = { state: 'deleted', updaterId: this.auth.currentUser.uid }
    return await this.getCustomerRef().collection('maintenance').doc(id).set(r, { merge: true })
  }

  getLinksExtension = async (tag) => {
    const links = await app.firestore().collection('links').where('customerid', '==', this.getCustomerId()).get()
    if (links.empty) return []
    const tagLink = links.docs.find((doc) => doc.data().tag === tag)
    if (tagLink) {
      return { ext: tagLink.id }
    } else {
      return { error: true, message: 'No link found' }
    }
  }
}

export default Firebase
