const DEFAULT_SPORTS = [
  { key: 'tennis', label: 'Tennis' },
  { key: 'padel', label: 'Padel' },
  { key: 'sulkapallo', label: 'Sulkapallo' },
  { key: 'squash', label: 'Squash' },
  { key: 'pingis', label: 'Pingis' },
  { key: 'biljardi', label: 'Biljardi' },
  { key: 'petanque', label: 'Petanque' },
  { key: 'keilaus', label: 'Keilaus' },
  { key: 'curling', label: 'Curling' },
  { key: 'darts', label: 'Darts' },
  { key: 'jousiammunta', label: 'Jousiammunta' },
  { key: 'pickleball', label: 'Pickleball' },
  { key: 'jalkapallo', label: 'Jalkapallo' },
  { key: 'koripallo', label: 'Koripallo' },
  { key: 'kuntonyrkkeily', label: 'Kuntonyrkkeily'},
  { key: 'salibandy', label: 'Salibandy' },
  { key: 'lentopallo', label: 'Lentopallo' },
  { key: 'kuntosali', label: 'Kuntosali' },
  { key: 'ryhmaliikunta', label: 'Ryhmäliikunta' },
  { key: 'beachvolley', label: 'Beach volley' },
  { key: 'golf', label: 'Golf' },
  { key: 'jääkiekko', label: 'Jääkiekko' },
  { key: 'luistelu', label: 'Luistelu' },
  { key: 'uinti', label: 'Uinti' },
  { key: 'sukellus', label: 'Sukellus' },
  { key: 'ratsastus', label: 'Ratsastus' },
  { key: 'hiihto', label: 'Hiihto' },
  { key: 'laskettelu', label: 'Laskettelu' },
  { key: 'pyöräily', label: 'Pyöräily' },
  { key: 'juoksu', label: 'Juoksu' },
  { key: 'kävely', label: 'Kävely' },
  { key: 'melonta', label: 'Melonta' },
  { key: 'soutu', label: 'Soutu' },
  { key: 'purjehdus', label: 'Purjehdus' },
  { key: 'purjelautailu', label: 'Purjelautailu' },
  { key: 'kajakki', label: 'Kajakki' },
  { key: 'sähly', label: 'Sähly' },
  { key: 'frisbeegolf', label: 'Frisbeegolf' },
  { key: 'muu', label: 'Muu' },
]
const DEFAULT_TYPES = [
  { key: 'valmennus', label: 'Valmennus' },
  { key: 'kilpailu', label: 'Kilpailu' },
  { key: 'peli', label: 'Peli' },
  { key: 'tapahtuma', label: 'Tapahtuma' },
  { key: 'kurssi', label: 'Kurssi' },
]
const _giftcodeBenefit = {
  type: 'object',
  properties: {
    type: {
      type: 'string',
      title: 'Etutyyppi',
      anyOf: [
        {
          type: 'string',
          title: 'Prosentuaalinen alennus (aina)',
          enum: ['pricereduction'],
        },
        {
          type: 'string',
          title: 'Etuhinta (aina)',
          enum: ['price'],
        },
        {
          type: 'string',
          title: 'Ilmaiskoodi',
          enum: ['free'],
        },
        {
          type: 'string',
          title: 'Alennushinnasto',
          enum: ['pricing'],
        },
        {
          type: 'string',
          title: 'Osan maksu (€)',
          enum: ['partpaymentmoney'],
        },
        {
          type: 'string',
          title: 'Osan maksu (%)',
          enum: ['partpaymentpercent'],
        },
      ],
    },
  },
  dependencies: {
    type: {
      oneOf: [
        {
          properties: {
            type: {
              enum: ['pricereduction'],
            },
            value: {
              type: 'number',
              title: 'Montako prosenttia alennus',
            },
          },
        },
        {
          properties: {
            type: {
              enum: ['price'],
            },
            value: {
              type: 'number',
              title: 'Mikä on etuhinta',
            },
          },
        },

        {
          properties: {
            type: {
              enum: ['free'],
            },
          },
        },
        {
          properties: {
            type: {
              enum: ['pricing'],
            },
            value: {
              title: 'Hinnoittely (kenttävaraus)',
              type: 'string',
              enum: [],
              enumNames: [],
            },
          },
        },
        {
          properties: {
            type: {
              enum: ['partpaymentmoney'],
            },
            value: {
              type: 'number',
              title: 'Paljonko vuorosta jää laskulle (€)',
            },
          },
        },
        {
          properties: {
            type: {
              enum: ['partpaymentpercent'],
            },
            value: {
              type: 'number',
              title: 'Paljonko vuorosta jää laskulle (%)',
            },
          },
        },
      ],
    },
  },
}

export const _giftcodeClass = {
  name: { type: 'string', title: 'Etukoodin nimi' },
  code: { type: 'string', title: 'Koodi' },
  start: {
    type: 'string',
    format: 'date',
    title: 'Alkaa',
  },
  end: {
    type: 'string',
    format: 'date',
    title: 'Loppuu',
  },
  onepercustomer: {
    type: 'boolean',
    title: 'Yksi per asiakas',
  },
  state: {
    type: 'string',
    title: 'Tila',
    enum: ['active', 'paused'],
    enumNames: ['Aktiivinen', 'Pysäytetty'],
  },
  type: {
    type: 'string',
    title: 'Tyyppi',
    enum: ['courtreservation', 'activity'],
    enumNames: ['Kenttävaraus', 'Aktiviteetti'],
  },
  benefit: {
    title: '',
    description: 'Etu',
    type: 'array',
    items: _giftcodeBenefit,
  },
  quota: {
    type: 'object',
    title: 'Määrärajat',
    properties: {
      left: {
        type: 'number',
        title: 'Jäljellä',
        readOnly: false,
        default: 100
      },
      total: {
        type: 'number',
        title: 'Kokonaismäärä',
        default: 100,
      }
    }
  }

}

export const _giftcodeClassDeps = {
  type: {
    oneOf: [
      {
        properties: {
          type: {
            enum: ['courtreservation'],
          },
        },
      },
      {
        properties: {
          type: {
            enum: ['activity'],
          },
          sport: {
            type: 'string',
            title: 'Laji',
            enum: DEFAULT_SPORTS.map(sport => sport.label),
            enumNames: DEFAULT_SPORTS.map(sport => sport.label),

          },
          activitytype: {
            type: 'string',
            title: 'Aktiviteetin tyyppi',
            enum: DEFAULT_TYPES.map(type => type.label),
            enumNames: DEFAULT_TYPES.map(type => type.label),
          },
          providertype: {
            type: 'string',
            title: 'Tapahtuman sisäinentyyppi',
          },
        },
      },
    ],
  },
}

export const _giftcodeUISchema = {}