export const DEFAULT_SPORTS = [
  { key: 'tennis', label: 'Tennis' },
  { key: 'padel', label: 'Padel' },
  { key: 'sulkapallo', label: 'Sulkapallo' },
  { key: 'squash', label: 'Squash' },
  { key: 'pingis', label: 'Pingis' },
  { key: 'biljardi', label: 'Biljardi' },
  { key: 'petanque', label: 'Petanque' },
  { key: 'keilaus', label: 'Keilaus' },
  { key: 'curling', label: 'Curling' },
  { key: 'darts', label: 'Darts' },
  { key: 'jousiammunta', label: 'Jousiammunta' },
  { key: 'pickleball', label: 'Pickleball' },
  { key: 'jalkapallo', label: 'Jalkapallo' },
  { key: 'koripallo', label: 'Koripallo' },
  { key: 'salibandy', label: 'Salibandy' },
  { key: 'lentopallo', label: 'Lentopallo' },
  { key: 'kuntosali', label: 'Kuntosali' },
  { key: 'kuntonyrkkeily', label: 'Kuntonyrkkeily'},
  { key: 'ryhmaliikunta', label: 'Ryhmäliikunta' },
  { key: 'beachvolley', label: 'Beach volley' },
  { key: 'golf', label: 'Golf' },
  { key: 'jääkiekko', label: 'Jääkiekko' },
  { key: 'luistelu', label: 'Luistelu' },
  { key: 'uinti', label: 'Uinti' },
  { key: 'sukellus', label: 'Sukellus' },
  { key: 'ratsastus', label: 'Ratsastus' },
  { key: 'hiihto', label: 'Hiihto' },
  { key: 'laskettelu', label: 'Laskettelu' },
  { key: 'pyöräily', label: 'Pyöräily' },
  { key: 'juoksu', label: 'Juoksu' },
  { key: 'kävely', label: 'Kävely' },
  { key: 'melonta', label: 'Melonta' },
  { key: 'soutu', label: 'Soutu' },
  { key: 'purjehdus', label: 'Purjehdus' },
  { key: 'purjelautailu', label: 'Purjelautailu' },
  { key: 'kajakki', label: 'Kajakki' },
  { key: 'sähly', label: 'Sähly' },
  { key: 'frisbeegolf', label: 'Frisbeegolf' },
  { key: 'muu', label: 'Muu' },
]