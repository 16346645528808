const _memberBenefit = {
  type: 'object',
  properties: {
    benefittype: {
      type: 'string',
      title: 'Etutyyppi',
      anyOf: [
        // {
        //   type: 'string',
        //   title: 'Aikaisempi varausoikeus',
        //   enum: ['earlybird'],
        // },
        // {
        //   type: 'string',
        //   title: 'Live varaustilanne seuranta',
        //   enum: ['stalkerservice'],
        // },
        {
          type: 'string',
          title: 'Jäsenalennus',
          enum: ['discount'],
        },
      ],
    },
  },
  dependencies: {
    benefittype: {
      oneOf: [
        {
          properties: {
            benefittype: {
              enum: ['earlybird'],
            },
            earlier: {
              type: 'number',
              title: 'Montako päivää normaalia aiemmin',
            },
            resources: {
              type: 'array',
              title: 'Mitkä kentät',
              items: {
                enum: ['1', '2', '3'],
                enumNames: ['Kenttä 1', 'Kenttä 2', 'Kenttä 3'],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            benefittype: {
              enum: ['stalkerservice'],
            },
          },
        },
        {
          properties: {
            benefittype: {
              enum: ['discount'],
            },
            pricing: {
              title: 'Hinnoittely',
              type: 'string',
              enum: [],
              enumNames: [],
            },
          },
        },
      ],
    },
  },
}

export const _membershipClass = {
  name: { type: 'string', title: 'Jäsenyyden nimi, esim. Tennisseuran hallisarjapelaaja' },
  displayName: { type: 'string', title: 'Asiakkaille näkyvä nimi' },
  description: { type: 'string', title: 'Kuvaus' },
  benefits: {
    title: '',
    description: 'Edut',
    type: 'array',
    items: _memberBenefit,
  },
  availableinshop: {
    type: 'string',
    title: 'Näytetäänkö verkkokaupassa',
    enum: ['true', 'false'],
    enumNames: ['Kyllä', 'Ei'],
  },
  // verifymethod: {
  //   type: 'string',
  //   title: 'Hankintatapa',
  //   enum: ['purchase'],
  //   enumNames: ['Tarkistetaan käsin', 'Ostettava'],
  // },
  provideritem: {
    type: 'boolean',
    title: 'Kumppanituote',
    // enum: ['true', 'false'],
    // enumNames: ['Kyllä', 'Ei'],
  },
  quota: {
    type: 'number',
    title: 'Yhtäaikaisvarauksien määrä',
    default: 0,
  },
  maxduration: {
    type: 'number',
    title: 'Varauksen maksimikesto (minuutteina)',
    default: 0,
  }
}

export const _membershipClassDeps = {
  availableinshop: {
    oneOf: [
      {
        properties: {
          availableinshop: {
            enum: ['true'],
          },
          vat: { type: 'number', title: 'ALV', enum: [0, 10, 14, 24, 25.5], enumNames: ['0%', '10%', '14%', '24%', '25,5%'] },
          image: { type: 'string', title: 'Verkkokaupan tuotekuva', items: { type: 'object', images: [] }, minaspectratio: 1 },
          variations: {
            title: '',
            description: 'Hintavaihtoehdot',
            type: 'array',
            items: {
              type: 'object',
              properties: {
                name: { type: 'string', title: 'Nimi' },
                price: { type: 'number', title: 'Hinta' },
                productCode: { type: 'string', title: 'Tuotekoodi' },
              },
            },
          },
          confirmationSubject: { type: 'string', title: 'Vahvistusviestin otsikko' },
          confirmationMessage: { type: 'string', title: 'Vahvistusviestin sisältö' },
          accounting: { type: 'string', title: 'Kirjanpidontili' },
          validity: {
            type: 'string',
            title: 'Voimassaoloaika',
            enum: ['rolling', 'next', 'fixed', 'permanent'],
            enumNames: ['Rullaava ajanjakso', 'Seuraavaan ajanjaksoon', 'Kiinteä ajanjakso', 'Pysyvä'],
          },
          // validityperiod: {
          //   type: 'number',
          //   title: 'Voimassaoloaika päivinä ostohetkestä (0 = ei päättymistä)',
          // },
          // validfrom: {
          //   type: 'string',
          //   title: 'Voimassaolo alkaa, jätä tyhjäksi jos alkaa ostohetkestä',
          //   format: 'date',
          // },
          // validuntil: {
          //   type: 'string',
          //   title: 'Voimassaolo päättyy, jos voimassa oloaika on asetettu se kumpi on aiemmin määrää',
          //   format: 'date',
          // },
        }
      },
      {
        properties: {
          availableinshop: {
            enum: ['false'],
          },
        }
      }
    ]
  },
  verifymethod: {
    oneOf: [
      {
        properties: {
          verifymethod: {
            enum: ['manual'],
          },
        },
      },
      {
        properties: {
          verifymethod: {
            enum: ['integration'],
          },
          verifyservice: {
            type: 'string',
            title: 'Tarkistuspalvelu',
            enum: ['tennisclub', 'openid'],
            enumNames: ['Tennisclub', 'OpenId'],
          },
        },
        // required: ['verifyservice']
      },
      {
        properties: {
          verifymethod: {
            enum: ['purchase'],
          },
          // pricing: {
          //   type: 'number',
          //   title: 'Hinta',
          // },
          validityperiod: {
            type: 'number',
            title: 'Voimassaoloaika päivinä (0 = ei päättymistä)',
          },
        },
        // required: ['verifyservice']
      },
    ],
  },
  provideritem: {
    oneOf: [
      {
        properties: {
          provideritem: {
            enum: [true],
          },
          provider: {
            type: 'string',
            title: 'Kumppani',
            enum: [],
            enumNames: [],
          },
        },
        required: ['provider'],
      },
      {
        properties: {
          provideritem: {
            enum: [false],
          },
        },
      },
    ],
  },
  validity: {
    oneOf: [
      {
        properties: {
          validity: {
            enum: ['rolling'],
          },
          unit: {
            type: 'string',
            title: 'Rullaava ajanjakso',
            enum: ['day', 'week', 'month', 'year'],
            enumNames: ['Päivä', 'Viikko', 'Kuukausi', 'Vuosi'],
          },
          amount: {
            type: 'number',
            title: 'Montako ajanjaksoa ostohetkestä',
          },
        },
      },
      {
        properties: {
          validity: {
            enum: ['next'],
          },
          endof: {
            type: 'string',
            title: 'Päättyy',
            enum: ['month', 'year'],
            enumNames: ['Kuukauden lopussa', 'Vuoden lopussa'],
          },
          daysahead: {
            type: 'number',
            title: 'Montako päivää ennen aletaan myydä seuraavaa ajanjaksoa. 0 = tästä hetkestä kuluvan ajanjakson loppuun asti.',
          },
        },
      },
      {
        properties: {
          validity: {
            enum: ['fixed'],
          },
          firstvalid: {
            type: 'string',
            title: 'Ensimmäinen voimassaolopäivä',
            format: 'date',
          },
          lastvalid: {
            type: 'string',
            title: 'Viimeinen voimassaolopäivä',
            format: 'date',
          },
        },
      },
      {
        properties: {
          validity: {
            enum: ['permanent'],
          },
          firstvalid: {
            type: 'string',
            title: 'Ensimmäinen voimassaolopäivä',
            format: 'date',
          },
        },
      },
    ],
  },
}

export const _membershipUISchema = {
  description: {
    'ui:widget': 'editorWidget',
  },
  confirmationMessage: {
    'ui:widget': 'editorWidget',
  },
  image: {
    'ui:widget': 'imageWidget',
  }
}
